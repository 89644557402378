import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import ErrorIndicator from '../../../../error-page/error-indicator';
import withGetService from '../../../../../hoc/with-get-service';
import getDocumentKycAction from '../../../../../../actions/get-document-kyc.actions';
import disableDocumentKycAction from '../../../../../../actions/get-disable-document-kyc.actions';
import Spinner from '../../../../../spinner';
import StepThree from './step-three-view';
import { compose } from '../../../../../../utils';

export class StepThreeContainer extends Component {
    static defaultProps = {
        t: () => {},
        disableDocumentKyc: () => {},
        getDocumentKyc: () => {},
        documentKyc: {},
        disableDocumentKycSuccess: false,
        loadingDisableDocumentKyc: false,
    };

    static propTypes = {
        t: PropTypes.func,
        disableDocumentKyc: PropTypes.func,
        getDocumentKyc: PropTypes.func,
        documentKyc: PropTypes.object,
        disableDocumentKycSuccess: PropTypes.bool,
        loadingDisableDocumentKyc: PropTypes.bool,
    };

    state = {};

    componentDidUpdate(prevProps) {
        const { disableDocumentKycSuccess, getDocumentKyc } = this.props;

        if (
            disableDocumentKycSuccess
            && disableDocumentKycSuccess !== prevProps.disableDocumentKycSuccess
        ) {
            getDocumentKyc();
        }
    }

    resetKyc = () => {
        const { t, disableDocumentKyc } = this.props;
        disableDocumentKyc(t);
    }


    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const { loading, error } = this.state;
        const { documentKyc, loadingDisableDocumentKyc } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <StepThree
                data={documentKyc}
                loading={loadingDisableDocumentKyc}
                resetKyc={this.resetKyc}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        documentKyc: { data: { info: documentKyc } },
        disableDocumentKyc: { success: disableDocumentKycSuccess, loadingDisableDocumentKyc },
    } = state;

    return {
        documentKyc,
        disableDocumentKycSuccess,
        loadingDisableDocumentKyc,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        disableDocumentKyc: disableDocumentKycAction(getService),
        getDocumentKyc: getDocumentKycAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
)(StepThreeContainer);
