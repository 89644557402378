import { store } from 'react-notifications-component';
import { POST_CHANGE_PASSWORD } from '../constants';

const postChangePasswordRequested = () => ({
    type: POST_CHANGE_PASSWORD.FETCH_CHANGE_PASSWORD_REQUEST,
});

const postChangePasswordLoaded = data => ({
    type: POST_CHANGE_PASSWORD.FETCH_CHANGE_PASSWORD_SUCCESS,
    payload: data,
});

const postChangePasswordError = error => ({
    type: POST_CHANGE_PASSWORD.FETCH_CHANGE_PASSWORD_FAILURE,
    payload: error,
});

const fetchChangePasswordAction = postService => (newPassword, t) => dispatch => {
    dispatch(postChangePasswordRequested());
    postService
        .changePassword(newPassword)
        .then(data => {
            dispatch(postChangePasswordLoaded(data));
            store.addNotification({
                message: t('general.passwordChangedSuccessfully'),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        })
        .catch(err => {
            dispatch(postChangePasswordError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            store.addNotification({
                message: err.response.data.message,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchChangePasswordAction;
