import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withGetService from '../../../../hoc/with-get-service';
import ErrorIndicator from '../../../error-page/error-indicator';
import spinnerImg from '../../../../assets/images/spinner.svg';
import DocumentsView from './documents-view';
import getDocumentKycAction from '../../../../../actions/get-document-kyc.actions';
import { compose } from '../../../../../utils';
import style from './documents.module.scss';

export class DocumentsContainer extends Component {
    mounted = true;

    static defaultProps = {
        getDocumentKyc: () => {},
        documentKyc: {},
        loading: false,
    };

    static propTypes = {
        getDocumentKyc: PropTypes.func,
        documentKyc: PropTypes.object,
        loading: PropTypes.bool,
    };

    state = {
        loading: true,
    };

    componentDidMount() {
        const { getDocumentKyc } = this.props;
        getDocumentKyc();
    }

    componentDidUpdate(prevProps) {
        const { loading } = this.props;
        if (!loading && loading !== prevProps.loading) {
            setTimeout(() => {
                if (this.mounted) {
                    this.setState({
                        loading: false,
                    });
                }
            }, 500);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { loading, error } = this.state;
        const { documentKyc } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? (
            <img className={style.spinner} src={spinnerImg} alt="spinner" />
        ) : null;
        const content = hasData ? (<DocumentsView documentKyc={documentKyc} />
        ) : null;

        return (
            <div className={style.documents} id="kyc">
                {errorMessage}
                {spinner}
                {content}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        documentKyc: { data: documentKyc, loading },
    } = state;

    return {
        documentKyc,
        loading,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getDocumentKyc: getDocumentKycAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
)(DocumentsContainer);
