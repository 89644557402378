import React, { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    personalAreaPath,
    depositPath,
    gamePath,
} from '../../../../constants/pathLocation';
import { compose } from '../../../../utils';
import style from './header.module.scss';

const Balance = ({
    loggingIn,
    cabinet,
    allTickersLoading,
    color,
    balance: { name: ticker, balance },
}) => {
    const { t } = useTranslation();
    const pathName = window.location.pathname;
    const matchPathName = pathName.split('/')[1];

    if (`/${matchPathName}` === gamePath && loggingIn) {
        return (
            <div className={style.header__buttonWrapper}>
                <Link to={`${personalAreaPath}${depositPath}`} className={style.deposit}>
                    <span className={style.buttonText}>{t('general.deposit')}</span>
                </Link>
            </div>
        );
    }

    const hideOnMobile = cabinet ? '' : style.hideOnMobile;

    return (
        <Fragment>
            {loggingIn ? (
                <Fragment>
                    <CSSTransition
                        in={!allTickersLoading}
                        timeout={1000}
                        classNames="CSSTransition"
                    >
                        <div
                            className={classNames(
                                style.header__balanceWrapper,
                                hideOnMobile,
                            )}
                        >
                            <p className={style.header__balanceWrapper_title}>
                                {t('general.yourCurrentBalance')}
                            </p>
                            <p
                                className={style.header__balanceWrapper_amount}
                                style={{ color }}
                            >
                                {balance.toLocaleString()} {ticker}
                            </p>
                        </div>
                    </CSSTransition>

                    <div
                        className={classNames(style.header__buttonWrapper, hideOnMobile)}
                    >
                        <Link
                            to={`${personalAreaPath}${depositPath}`}
                            className={style.deposit}
                        >
                            <span className={style.buttonText}>
                                {t('general.deposit')}
                            </span>
                        </Link>
                    </div>
                </Fragment>
            ) : null}
        </Fragment>
    );
};

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        currentBalance: { balance },
        allTickers: { loading: allTickersLoading },
    } = state;

    return {
        loggingIn,
        allTickersLoading,
        balance,
    };
};

Balance.defaultProps = {
    balance: {},
    color: '',
    allTickersLoading: false,
    loggingIn: false,
    cabinet: false,
};

Balance.propTypes = {
    balance: PropTypes.object,
    color: PropTypes.string,
    allTickersLoading: PropTypes.bool,
    loggingIn: PropTypes.bool,
    cabinet: PropTypes.bool,
};

export default compose(connect(mapStateToProps), withRouter)(Balance);
