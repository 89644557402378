import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { store } from 'react-notifications-component';
import { message } from 'antd';
import getUserInfoAction from '../../../../../actions/get-user-info.actions';
import fetchRemoveAvatarAction from '../../../../../actions/post-remove-avatar.actions';
import fetchChangeUserAvatarAction from '../../../../../actions/post-change-user-avatar.actions';
import fetchChangeUserDataAction from '../../../../../actions/post-change-user-data.actions';
import fetchChangePasswordAction from '../../../../../actions/post-change-password.actions';
import getGenerateSecretGoogleAuthAction from '../../../../../actions/get-generate-secret-google-auth.actions';
import fetchEnabledTwoFaAction from '../../../../../actions/post-enabled-two-fa.actions';
import fetchDisabledTwoFaAction from '../../../../../actions/post-disabled-two-fa.actions';
import getTwoFaQrCodeAction from '../../../../../actions/get-twoFa-qrcode.actions';
import withPostService from '../../../../hoc/with-post-service';
import withGetService from '../../../../hoc/with-get-service';
import ErrorIndicator from '../../../error-page/error-indicator';
import ProfileDataView from './profile-data-view';
import { compose } from '../../../../../utils';
import spinnerImg from '../../../../assets/images/spinner.svg';
import {
    oneLowercaseChar,
    oneUppercaseChar,
    oneNumber,
    oneSpecialChar,
} from '../../../../../helpers';
import style from './profile-data.module.scss';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export class ProfileDataContainer extends Component {
    mounted = true;

    static defaultProps = {
        t: () => {},
        submitNewPassword: () => {},
        changeUserData: () => {},
        getUserInfo: () => {},
        changeUserAvatar: () => {},
        deleteAvatar: () => {},
        generateSecretGoogleAuth: () => {},
        getTwoFaQrCode: () => {},
        enabledTwoFa: () => {},
        disabledTwoFa: () => {},
        changePasswordSuccess: false,
        changeUserDataSuccess: false,
        changeUserAvatarSuccess: false,
        removeAvatarSuccess: false,
        loading: false,
        generateSecretGoogleAuthLoading: false,
        enabledTwoFaSuccess: false,
        disabledTwoFaSuccess: false,
        userInfoSuccess: false,
        userInfo: {},
        qrCodeImage: '',
    };

    static propTypes = {
        t: PropTypes.func,
        submitNewPassword: PropTypes.func,
        changeUserData: PropTypes.func,
        getUserInfo: PropTypes.func,
        changeUserAvatar: PropTypes.func,
        deleteAvatar: PropTypes.func,
        generateSecretGoogleAuth: PropTypes.func,
        getTwoFaQrCode: PropTypes.func,
        enabledTwoFa: PropTypes.func,
        disabledTwoFa: PropTypes.func,
        changePasswordSuccess: PropTypes.bool,
        changeUserDataSuccess: PropTypes.bool,
        changeUserAvatarSuccess: PropTypes.bool,
        removeAvatarSuccess: PropTypes.bool,
        loading: PropTypes.bool,
        generateSecretGoogleAuthLoading: PropTypes.bool,
        enabledTwoFaSuccess: PropTypes.bool,
        disabledTwoFaSuccess: PropTypes.bool,
        userInfoSuccess: PropTypes.bool,
        userInfo: PropTypes.object,
        qrCodeImage: PropTypes.string,
    };

    state = {
        previewVisible: false,
        previewImage: '',
        fileList: [],
        nickname: '',
        twoFACode: '',
        password: '',
        confirmPassword: '',
        nicknameErrors: {
            nicknameCharactersError: '',
        },
        twoFACodeErrors: {
            twoFACodeCharactersError: '',
        },
        passwordErrors: {
            passwordCharactersError: '',
            passwordLengthError: '',
            passwordDigitError: '',
            passwordLettersError: '',
        },
        confirmPasswordErrors: {
            confirmPasswordCharactersError: '',
        },
        type: 'password',
        loadingProfile: true,
    };

    componentDidMount() {
        const { userInfo, getUserInfo } = this.props;
        getUserInfo();
        if (userInfo.avatar) {
            if (this.mounted) {
                this.setState({
                    fileList: [
                        {
                            thumbUrl: `${process.env.REACT_APP_AVATAR_IMAGE_URL}${userInfo.avatar}`,
                            type: 'image/png',
                            uid: 'rc-upload-1598967523974-4',
                        },
                    ],
                    previewImage: `${process.env.REACT_APP_AVATAR_IMAGE_URL}${userInfo.avatar}`,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {
            changePasswordSuccess,
            changeUserDataSuccess,
            changeUserAvatarSuccess,
            removeAvatarSuccess,
            getUserInfo,
            userInfo,
            loading,
            generateSecretGoogleAuth,
            getTwoFaQrCode,
            generateSecretGoogleAuthLoading,
            enabledTwoFaSuccess,
            disabledTwoFaSuccess,
            userInfoSuccess,
        } = this.props;
        const { fileList } = this.state;

        // Reset password state if was successfully changed
        if (
            changePasswordSuccess
            && changePasswordSuccess !== prevProps.changePasswordSuccess
        ) {
            this.setState(state => ({
                ...state,
                password: '',
                confirmPassword: '',
                passwordErrors: {
                    passwordCharactersError: '',
                    passwordLengthError: '',
                    passwordDigitError: '',
                    passwordLettersError: '',
                },
                confirmPasswordErrors: {
                    confirmPasswordCharactersError: '',
                },
            }));
        }

        // Reset nickname state if was successfully changed
        if (
            changeUserDataSuccess
            && changeUserDataSuccess !== prevProps.changeUserDataSuccess
        ) {
            this.setState(state => ({
                ...state,
                nickname: '',
                nicknameErrors: {
                    nicknameCharactersError: '',
                },
            }));
            getUserInfo();
        }

        // Getting QrCode if was no two_fa_hash
        if (
            !generateSecretGoogleAuthLoading
            && generateSecretGoogleAuthLoading !== prevProps.generateSecretGoogleAuthLoading
        ) {
            getUserInfo();
        }

        // Setting the avatar in state
        if (!loading && loading !== prevProps.loading) {
            if (!fileList.length && userInfo.avatar) {
                if (this.mounted) {
                    this.setState({
                        fileList: [
                            {
                                thumbUrl: `${process.env.REACT_APP_AVATAR_IMAGE_URL}${userInfo.avatar}`,
                                type: 'image/png',
                                uid: 'rc-upload-1598967523974-4',
                            },
                        ],
                        previewImage: `${process.env.REACT_APP_AVATAR_IMAGE_URL}${userInfo.avatar}`,
                    });
                }
            }

            // Getting two_fa_hash
            if (userInfoSuccess && userInfo.two_fa_hash) {
                getTwoFaQrCode();
            }

            // Getting QrCode
            if (userInfoSuccess && !userInfo.two_fa_hash) {
                generateSecretGoogleAuth();
            }

            setTimeout(() => {
                if (this.mounted) {
                    this.setState({
                        loadingProfile: false,
                    });
                }
            }, 500);
        }

        // get information on the user

        if (
            changeUserAvatarSuccess
            && changeUserAvatarSuccess !== prevProps.changeUserAvatarSuccess
        ) {
            getUserInfo();
        }

        if (
            removeAvatarSuccess
            && removeAvatarSuccess !== prevProps.removeAvatarSuccess
        ) {
            getUserInfo();
        }

        if (
            enabledTwoFaSuccess
            && enabledTwoFaSuccess !== prevProps.enabledTwoFaSuccess
        ) {
            getUserInfo();
            this.setState(state => ({
                ...state,
                twoFACode: '',
                twoFACodeErrors: {
                    twoFACodeCharactersError: '',
                },
            }));
        }

        if (
            disabledTwoFaSuccess
            && disabledTwoFaSuccess !== prevProps.disabledTwoFaSuccess
        ) {
            getUserInfo();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        const { userInfo } = this.props;
        const { avatar } = userInfo;
        if (!avatar && !file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (this.mounted) {
            this.setState({
                previewImage: avatar
                    ? `${process.env.REACT_APP_AVATAR_IMAGE_URL}${avatar}`
                    : file.url || file.preview,
                previewVisible: true,
            });
        }
    };

    beforeUpload = file => {
        const { t } = this.props;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isJpgOrPng) {
            message.error(t('general.youCanUploadPNGFile'), 5);
        } else if (!isLt5M) {
            message.error(t('general.imageMustSmallerThan', { digit: 5 }), 5);
        } else {
            this.uploadAvatar(file);
        }
    };

    handleChange = ({ fileList }) => {
        const { t, deleteAvatar } = this.props;
        if (fileList.length) {
            this.beforeUpload(fileList[0]);
        } else {
            if (this.mounted) {
                this.setState({
                    fileList: [],
                });
            }
            deleteAvatar(t);
        }
    };

    uploadAvatar = async file => {
        const { t, changeUserAvatar } = this.props;
        if (this.mounted) {
            this.setState({
                fileList: [file],
            });
        }
        const base64 = await getBase64(file.originFileObj);
        const data = {
            avatar: {
                base64,
            },
        };
        changeUserAvatar(data, t);
    };

    inputOnchange = async event => {
        const { name, value } = event.target;
        const { confirmPassword } = this.state;

        if (name === 'nickname') {
            if (value.length > 20) {
                return;
            }
            this.setState(state => ({
                [name]: value,
                nicknameErrors: {
                    ...state.nicknameErrors,
                    nicknameCharactersError: '',
                },
            }));
        }

        if (name === 'twoFACode') {
            this.setState(state => ({
                [name]: value,
                twoFACodeErrors: {
                    ...state.twoFACodeErrors,
                    twoFACodeCharactersError: '',
                },
            }));
        }

        if (name === 'password') {
            await this.passwordValidation(name, value);
            await this.confirmPasswordValidation('confirmPassword', confirmPassword);
        }

        if (name === 'confirmPassword') {
            await this.confirmPasswordValidation(name, value);
        }
    };

    passwordValidation = (name, value) => {
        const { t } = this.props;
        this.setState(state => ({
            [name]: value.trim(),
            passwordErrors: {
                ...state.passwordErrors,
                oneLowercaseChar: oneLowercaseChar(value)
                    ? t('error.one_lowercase_char')
                    : '',
                oneUppercaseChar: oneUppercaseChar(value)
                    ? t('error.one_upperrcase_char')
                    : '',
                oneNumber: oneNumber(value) ? t('error.one_number') : '',
                oneSpecialChar: oneSpecialChar(value) ? t('error.one_special_char') : '',
                minLength: value.length < 8 ? t('error.min_length', { digit: 8 }) : '',
                passwordLengthError: '',
            },
        }));
    };

    confirmPasswordValidation = (name, value) => {
        const { t } = this.props;
        const { password } = this.state;
        const errorText = value !== password ? t('error.password_does_not_match') : '';
        this.setState({
            [name]: value.trim(),
            confirmPasswordErrors: {
                passwordDoesntMatch: errorText,
                confirmPasswordCharactersError: '',
            },
        });
    };

    showHidePassword = () => {
        const { type } = this.state;
        if (this.mounted) {
            this.setState({
                type: type === 'password' ? 'text' : 'password',
            });
        }
    };

    submitChangeName = event => {
        event.preventDefault();
        const { nickname } = this.state;
        const { t, changeUserData } = this.props;
        if (!nickname.length) {
            if (this.mounted) {
                this.setState({
                    nicknameErrors: {
                        nicknameCharactersError: t('error.field_can_not_be_empty'),
                    },
                });
            }
        } else {
            const data = {
                username: nickname,
            };
            changeUserData(data, t);
        }
    };

    submitTwoFACode = event => {
        event.preventDefault();
        const { twoFACode } = this.state;
        const { t, enabledTwoFa } = this.props;

        if (!twoFACode.length) {
            if (this.mounted) {
                this.setState({
                    twoFACodeErrors: {
                        twoFACodeCharactersError: t('error.field_can_not_be_empty'),
                    },
                });
            }
        } else {
            const data = {
                code: twoFACode,
            };
            enabledTwoFa(data, t);
        }
    };

    disableTwoFa = () => {
        const { t, disabledTwoFa } = this.props;

        disabledTwoFa(t);
    };

    submitNewPassword = event => {
        event.preventDefault();
        const {
            password,
            passwordErrors,
            confirmPassword,
            confirmPasswordErrors,
        } = this.state;
        const { t } = this.props;
        const copyPasswordErrors = Object.assign({}, passwordErrors);
        const copyConfirmPasswordErrors = Object.assign({}, confirmPasswordErrors);

        Object.keys(copyPasswordErrors).forEach(key => {
            if (!copyPasswordErrors[key]) delete copyPasswordErrors[key];
        });
        Object.keys(copyConfirmPasswordErrors).forEach(key => {
            if (!copyConfirmPasswordErrors[key]) delete copyConfirmPasswordErrors[key];
        });

        if (!password.length) {
            this.setState(state => ({
                passwordErrors: {
                    ...state.passwordErrors,
                    passwordLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        } else if (!confirmPassword.length) {
            this.setState(state => ({
                confirmPasswordErrors: {
                    ...state.confirmPasswordErrors,
                    confirmPasswordCharactersError: t('error.field_can_not_be_empty'),
                },
            }));
        } else if (
            !Object.keys(copyPasswordErrors).length
            && !Object.keys(copyConfirmPasswordErrors).length
        ) {
            if (password && confirmPassword) {
                const { submitNewPassword } = this.props;
                const data = {
                    password,
                    password_repeat: confirmPassword,
                };
                submitNewPassword(data, t);
            }
        }
    };

    copyTwoFaCode = () => {
        const { t } = this.props;
        store.addNotification({
            title: t('general.successNotification'),
            message: t('general.сopiedToClipboard'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'slideInRight'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
                duration: 3000,
                pauseOnHover: true,
            },
        });
    };

    generateNewCode = () => {
        const { generateSecretGoogleAuth } = this.props;
        generateSecretGoogleAuth();
    }

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const {
            loadingProfile,
            error,
            previewVisible,
            previewImage,
            fileList,
            nickname,
            twoFACode,
            password,
            confirmPassword,
            nicknameErrors,
            twoFACodeErrors,
            passwordErrors,
            confirmPasswordErrors,
            type,
        } = this.state;
        const {
            qrCodeImage,
            userInfo: { two_fa_hash: twoFaHash, is_two_fa: isEnableTwoFa },
        } = this.props;
        const hasData = !(loadingProfile || error);
        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loadingProfile ? (
            <img className={style.spinner} src={spinnerImg} alt="spinner" />
        ) : null;
        const content = hasData ? (
            <ProfileDataView
                previewVisible={previewVisible}
                previewImage={previewImage}
                fileList={fileList}
                nickname={nickname}
                twoFACode={twoFACode}
                password={password}
                confirmPassword={confirmPassword}
                nicknameErrors={nicknameErrors}
                twoFACodeErrors={twoFACodeErrors}
                passwordErrors={passwordErrors}
                type={type}
                qrCodeImage={qrCodeImage}
                confirmPasswordErrors={confirmPasswordErrors}
                twoFaHash={twoFaHash}
                isEnableTwoFa={isEnableTwoFa}
                disableTwoFa={this.disableTwoFa}
                handlePreview={this.handlePreview}
                handleChange={this.handleChange}
                handleCancel={this.handleCancel}
                inputOnchange={this.inputOnchange}
                showHidePassword={this.showHidePassword}
                beforeUpload={this.beforeUpload}
                submitChangeName={this.submitChangeName}
                submitNewPassword={this.submitNewPassword}
                submitTwoFACode={this.submitTwoFACode}
                copyTwoFaCode={this.copyTwoFaCode}
                generateNewCode={this.generateNewCode}
            />
        ) : null;

        return (
            <div className={style.profileData}>
                {errorMessage}
                {spinner}
                {content}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        changePassword: { success: changePasswordSuccess },
        changeUserData: { success: changeUserDataSuccess },
        userInfo: { data: userInfo, loading, success: userInfoSuccess },
        changeUserAvatar: { success: changeUserAvatarSuccess },
        removeAvatar: { success: removeAvatarSuccess },
        generateSecretGoogleAuth: { loading: generateSecretGoogleAuthLoading },
        getTwoFaQrCode: { data: qrCodeImage, loading: loadingTwoFaQrCode },
        enabledTwoFa: { success: enabledTwoFaSuccess },
        disabledTwoFa: { success: disabledTwoFaSuccess },
    } = state;

    return {
        loggingIn,
        changePasswordSuccess,
        changeUserDataSuccess,
        changeUserAvatarSuccess,
        removeAvatarSuccess,
        userInfo,
        loading,
        generateSecretGoogleAuthLoading,
        qrCodeImage,
        loadingTwoFaQrCode,
        enabledTwoFaSuccess,
        disabledTwoFaSuccess,
        userInfoSuccess,
    };
};

const mapDispatchToProps = (dispatch, { postService, getService }) => bindActionCreators(
    {
        submitNewPassword: fetchChangePasswordAction(postService),
        changeUserData: fetchChangeUserDataAction(postService),
        changeUserAvatar: fetchChangeUserAvatarAction(postService),
        deleteAvatar: fetchRemoveAvatarAction(postService),
        enabledTwoFa: fetchEnabledTwoFaAction(postService),
        disabledTwoFa: fetchDisabledTwoFaAction(postService),
        getUserInfo: getUserInfoAction(getService),
        generateSecretGoogleAuth: getGenerateSecretGoogleAuthAction(getService),
        getTwoFaQrCode: getTwoFaQrCodeAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
)(ProfileDataContainer);
