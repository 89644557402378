import { GET_ALL_FAQ } from '../constants';

const getAllFaqRequested = () => ({
    type: GET_ALL_FAQ.GET_ALL_FAQ_REQUEST,
});

const getAllFaqLoaded = data => ({
    type: GET_ALL_FAQ.GET_ALL_FAQ_SUCCESS,
    payload: data,
});

const getAllFaqError = error => ({
    type: GET_ALL_FAQ.GET_ALL_FAQ_FAILURE,
    payload: error,
});

const getAllFaqAction = getService => () => dispatch => {
    dispatch(getAllFaqRequested());
    getService
        .getAllFaq()
        .then(data => {
            dispatch(getAllFaqLoaded(data));
        })
        .catch(err => {
            dispatch(getAllFaqError(err));
        });
};

export default getAllFaqAction;
