/* eslint-disable */
import { FILTER_CONSTANTS } from '../constants';

export const filterParams = (state, action) => {
    if (state === undefined) {
        return {
            data: {
                currentPageNumber: '',
            },
        };
    }
    switch (action.type) {
        case FILTER_CONSTANTS.SELECT_FILTER:
            return {
                data: action.payload,
            };
        default:
            return state;
    }
};
