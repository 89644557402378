import React from 'react';
import PropTypes from 'prop-types';
import rating1 from '../../assets/images/game-rating/rating1.svg';
import rating2 from '../../assets/images/game-rating/rating2.svg';
import rating3 from '../../assets/images/game-rating/rating3.svg';
import rating4 from '../../assets/images/game-rating/rating4.svg';
import rating5 from '../../assets/images/game-rating/rating5.svg';

const Rating = ({ rating, className }) => {
    let src = '';
    if (+rating >= 0 && +rating < 2) {
        src = rating1;
    }

    if (+rating >= 2 && +rating < 3) {
        src = rating2;
    }

    if (+rating >= 3 && +rating < 4) {
        src = rating3;
    }

    if (+rating >= 4 && +rating < 5) {
        src = rating4;
    }

    if (+rating >= 5) {
        src = rating5;
    }

    return (
        <img
            className={className}
            src={src}
            alt="rating"
        />
    );
};

Rating.defaultProps = {
    rating: '',
    className: '',
};

Rating.propTypes = {
    rating: PropTypes.any,
    className: PropTypes.string,
};

export default Rating;
