/* eslint-disable */

import { GET_CURRENT_BALANCE_CONSTANTS } from '../constants';

const currentBalance = balance => ({
    type: GET_CURRENT_BALANCE_CONSTANTS.CURRENT_BALANCE,
    balance,
});

export const currentBalanceActions = {
    currentBalance,
};
