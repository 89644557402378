/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import secondStepIcon from './images/second-step-icon.svg';
import secondStepMobileIcon from './images/second-step-mobile-icon.svg';
import passportIcon from './images/passport.svg';
import idCardIcon from './images/id-card.svg';
import passportImg from './images/passport-icon.svg';
import selfiePassport from './images/selfie-passport.svg';
import idImg from './images/id-icon.svg';
import selfieId from './images/selfie-id.svg';
import driverImg from './images/driver-icon.svg';
import selfieDriver from './images/selfie-driver.svg';
import driverLicenseIcon from './images/driver-license.svg';
import uploadIcon from './images/upload-icon.svg';
import successIcon from '../../../../../assets/images/success-icon.svg';
import style from './step-two.module.scss';

const StepTwo = ({
    selectedDocument,
    selectDocument,
    documents: { PASSPORT, ID_CARD, DRIVER_LICENSE },
    goBack,
    fileUpload,
    main_image_first,
    main_image_second,
    main_image_third,
    imagesName: { FIRST_IMAGE, SECOND_IMAGE, THIRD_IMAGE },
    nextStep,
}) => {
    const { t } = useTranslation();
    let data = {};

    if (selectedDocument === PASSPORT) {
        data = {
            title: t('kyc.passport'),
            icon: passportImg,
            selfieIcon: selfiePassport,
        };
    } else if (selectedDocument === ID_CARD) {
        data = {
            title: t('kyc.identityCard'),
            icon: idImg,
            selfieIcon: selfieId,
        };
    } else if (selectedDocument === DRIVER_LICENSE) {
        data = {
            title: t('kyc.driverLicense'),
            icon: driverImg,
            selfieIcon: selfieDriver,
        };
    }

    const PassportUploadWrapper = () => (
        <div className={style.step__uploadWrapper}>
            <img className={style.step__uploadWrapper_img} src={data.icon} alt="icon" />
            <div className={style.step__uploadWrapper_rightSide}>
                {!main_image_first.base64 ? (
                    <Fragment>
                        <div className={style.step__uploadWrapper_infoWrapper}>
                            <img
                                className={style.step__uploadWrapper_uploadIcon}
                                src={uploadIcon}
                                alt="uploadIcon"
                            />
                            <div className={style.step__uploadWrapper_textWrapper}>
                                <p>{t('kyc.upload')}</p>
                                <p>.jpg {t('general.or')} .png</p>
                            </div>
                        </div>
                        <p className={style.step__uploadWrapper_maxSize}>
                            {t('kyc.shouldLessThan8Mb')}
                        </p>
                        <div className={style.step__uploadButtonWrapper}>
                            <button
                                type="button"
                                className={style.step__uploadWrapper_uploadButton}
                            >
                                <span className={style.buttonText}>
                                    {t('kyc.choseToUpload')}
                                </span>
                            </button>
                            <input
                                type="file"
                                onChange={file => fileUpload(file, FIRST_IMAGE)}
                                className={style.inputTypeFile}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <img
                        src={successIcon}
                        alt="success"
                        className={style.successBigIcon}
                    />
                )}
            </div>
        </div>
    );

    const IdUploadWrapper = () => (
        <div className={classNames(
            style.step__uploadWrapper,
            style.step__uploadWrapperID,
        )}
        >
            <img className={style.step__uploadWrapper_img} src={data.icon} alt="icon" />
            <div className={style.step__uploadWrapper_idDescription}>
                <div className={style.step__uploadWrapper_textWrapperId}>
                    <p>
                        {t('kyc.upload')} .jpg {t('general.or')} .png{' '}
                        {t('kyc.shouldLessThan8Mb')}
                    </p>
                </div>
                <div className={style.step__uploadWrapper_rightSideContainer}>
                    <div className={style.step__uploadWrapper_rightSideId}>
                        {!main_image_first.base64 ? (
                            <Fragment>
                                <div className={style.step__uploadWrapper_textWrapperId}>
                                    <p>{t('kyc.frontSide')}</p>
                                </div>
                                <div className={style.step__uploadButtonWrapper}>
                                    <button
                                        type="button"
                                        className={style.step__uploadWrapper_uploadButtonId}
                                    >
                                        <span className={style.buttonText}>
                                            {t('kyc.choseToUpload')}
                                        </span>
                                    </button>
                                    <input
                                        type="file"
                                        onChange={file => fileUpload(file, FIRST_IMAGE)}
                                        className={style.inputTypeFile}
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <img
                                src={successIcon}
                                alt="success"
                                className={style.successSmallIcon}
                            />
                        )}
                    </div>
                    <div className={style.step__uploadWrapper_rightSideId}>
                        {!main_image_third.base64 ? (
                            <Fragment>
                                <div className={style.step__uploadWrapper_textWrapperId}>
                                    <p>{t('kyc.backSide')}</p>
                                </div>
                                <div className={style.step__uploadButtonWrapper}>
                                    <button
                                        type="button"
                                        className={style.step__uploadWrapper_uploadButtonId}
                                    >
                                        <span className={style.buttonText}>
                                            {t('kyc.choseToUpload')}
                                        </span>
                                    </button>
                                    <input
                                        type="file"
                                        onChange={file => fileUpload(file, THIRD_IMAGE)}
                                        className={style.inputTypeFile}
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <img
                                src={successIcon}
                                alt="success"
                                className={style.successSmallIcon}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    let uploadInfo = '';

    if (selectedDocument === PASSPORT) {
        uploadInfo = <PassportUploadWrapper />;
    } else if (selectedDocument === ID_CARD) {
        uploadInfo = <IdUploadWrapper />;
    } else if (selectedDocument === DRIVER_LICENSE) {
        uploadInfo = <IdUploadWrapper />;
    }

    return (
        <div className={style.step}>
            <p className={style.step__title}>2. {t('kyc.uploadDocuments')}</p>
            <img
                className={style.step__stepIcon}
                src={secondStepIcon}
                alt="stepicon"
            />
            <img
                className={style.step__stepMobileIcon}
                src={secondStepMobileIcon}
                alt="stepicon"
            />
            <div className={style.step__description}>
                <p className={style.step__description_item}>
                    {t('kyc.basicInformation')}
                </p>
                <p className={style.step__description_item}>{t('kyc.uploadDocuments')}</p>
                <p className={style.step__description_item}>{t('kyc.veryfying')}</p>
            </div>
            {!selectedDocument ? (
                <div>
                    <p className={style.step__subTitle}>{t('kyc.selectDocument')}</p>
                    <div className={style.step__selectDocuments}>
                        <div
                            onClick={() => selectDocument(PASSPORT)}
                            className={style.step__selectDocuments_item}
                        >
                            <img src={passportIcon} alt="passportIcon" />
                            <p className={style.step__selectDocuments_title}>
                                {t('kyc.passport')}
                            </p>
                        </div>
                        <div
                            onClick={() => selectDocument(ID_CARD)}
                            className={style.step__selectDocuments_item}
                        >
                            <img src={idCardIcon} alt="idCardIcon" />
                            <p className={style.step__selectDocuments_title}>
                                {t('kyc.identityCard')}
                            </p>
                        </div>
                        <div
                            onClick={() => selectDocument(DRIVER_LICENSE)}
                            className={style.step__selectDocuments_item}
                        >
                            <img src={driverLicenseIcon} alt="driverLicenseIcon" />
                            <p className={style.step__selectDocuments_title}>
                                {t('kyc.driverLicense')}
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}

            {selectedDocument ? (
                <div className={style.step__uploadDocuments}>
                    <div className={style.step__uploadDocuments_item}>
                        <p className={style.step__uploadDocuments_title}>{data.title}</p>
                        {uploadInfo}
                    </div>
                    <div className={style.step__uploadDocuments_item}>
                        <p className={style.step__uploadDocuments_title}>
                            {t('kyc.selfieWithDocument')}
                        </p>
                        <div className={style.step__uploadWrapper}>
                            <img
                                className={style.step__uploadWrapper_img}
                                src={data.selfieIcon}
                                alt="selfieIcon"
                            />
                            <div className={style.step__uploadWrapper_rightSide}>
                                {!main_image_second.base64 ? (
                                    <Fragment>
                                        <div
                                            className={
                                                style.step__uploadWrapper_infoWrapper
                                            }
                                        >
                                            <img
                                                className={
                                                    style.step__uploadWrapper_uploadIcon
                                                }
                                                src={uploadIcon}
                                                alt="uploadIcon"
                                            />
                                            <div
                                                className={
                                                    style.step__uploadWrapper_textWrapper
                                                }
                                            >
                                                <p>{t('kyc.upload')}</p>
                                                <p>.jpg {t('general.or')} .png</p>
                                            </div>
                                        </div>
                                        <p className={style.step__uploadWrapper_maxSize}>
                                            {t('kyc.shouldLessThan8Mb')}
                                        </p>
                                        <div className={style.step__uploadButtonWrapper}>
                                            <button
                                                type="button"
                                                className={
                                                    style.step__uploadWrapper_uploadButton
                                                }
                                            >
                                                <span className={style.buttonText}>
                                                    {t('kyc.choseToUpload')}
                                                </span>
                                            </button>
                                            <input
                                                type="file"
                                                onChange={file => fileUpload(file, SECOND_IMAGE)
                                                }
                                                className={style.inputTypeFile}
                                            />
                                        </div>
                                    </Fragment>
                                ) : (
                                    <img
                                        src={successIcon}
                                        alt="success"
                                        className={style.successBigIcon}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {selectedDocument ? (
                <Fragment>
                    <div className={style.step__uploadRules}>
                        <ol className={style.step__uploadRules_decimal}>
                            <li>{t('kyc.uploadRule1')}</li>
                            <li>{t('kyc.uploadRule2')}</li>
                            <li>{t('kyc.uploadRule3')}</li>
                            <li>{t('kyc.uploadRule4')}</li>
                            <ul className={style.step__uploadRules_disc}>
                                <li>{t('kyc.uploadRule4Sub1')}</li>
                                <li>{t('kyc.uploadRule4Sub2')}</li>
                                <li>{t('kyc.uploadRule4Sub3')}</li>
                                <li>{t('kyc.uploadRule4Sub4')}</li>
                            </ul>
                        </ol>
                        <p className={style.step__uploadRules_example}>
                            {t('kyc.uploadRule5')}
                        </p>
                    </div>
                    <div className={style.step__buttonsWrapper}>
                        <div className={style.step__prevButton} onClick={goBack}>
                            <span className={style.buttonText}>
                                {t('general.previous')}
                            </span>
                        </div>
                        <div
                            className={style.step__nextButton}
                            onClick={nextStep}
                        >
                            <span className={style.buttonText}>{t('general.next')}</span>
                        </div>
                    </div>
                </Fragment>
            ) : null}
        </div>
    );
};

StepTwo.defaultProps = {
    selectedDocument: '',
    documents: {},
    main_image_first: {},
    main_image_second: {},
    main_image_third: {},
    imagesName: {},
    selectDocument: () => {},
    goBack: () => {},
    fileUpload: () => {},
    nextStep: () => {},
};

StepTwo.propTypes = {
    selectedDocument: PropTypes.string,
    documents: PropTypes.object,
    main_image_first: PropTypes.object,
    main_image_second: PropTypes.object,
    main_image_third: PropTypes.object,
    imagesName: PropTypes.object,
    selectDocument: PropTypes.func,
    goBack: PropTypes.func,
    fileUpload: PropTypes.func,
    nextStep: PropTypes.func,
};

export default StepTwo;
