import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchPasswordRecoveryAction from '../../../actions/post-password-recovery.actions';
import Button from '../../small-components/button';
import withPostService from '../../hoc/with-post-service';
import { authModalActions } from '../../../actions/authModal.actions';
import { compose } from '../../../utils';
import Field from '../../small-components/field';
import ModalWindow from '../../small-components/modal-window/modal-widow';
import { emailValid } from '../../../helpers';
import style from './password-recovery.module.scss';

class PasswordRecovery extends PureComponent {
    static defaultProps = {
        t: () => {},
        submitEmail: () => {},
        closeModal: () => {},
        openLogin: () => {},
        passwordRecovery: false,
    };

    static propTypes = {
        t: PropTypes.func,
        submitEmail: PropTypes.func,
        closeModal: PropTypes.func,
        openLogin: PropTypes.func,
        passwordRecovery: PropTypes.bool,
    };

    state = {
        email: '',
        emailErrors: {
            emailLengthError: '',
            emailCharactersError: '',
        },
    };

    inputOnchange = event => {
        const { name, value } = event.target;
        const { t } = this.props;

        this.setState(state => ({
            [name]: value.toLowerCase().trim(),
            emailErrors: {
                ...state.emailErrors,
                wrongEmail: emailValid(value) ? t('error.wrong_email') : '',
                emailLengthError: '',
            },
        }));
    };

    validateFields = () => {
        const { t } = this.props;
        const { email } = this.state;

        if (email.length < 1) {
            this.setState(state => ({
                emailErrors: {
                    ...state.emailErrors,
                    emailLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        }
    };

    submit = async () => {
        await this.validateFields();
        const { email, emailErrors } = this.state;
        const { t } = this.props;

        const copyEmailErrors = { ...emailErrors };

        Object.keys(copyEmailErrors).forEach(key => {
            if (!copyEmailErrors[key]) delete copyEmailErrors[key];
        });

        if (Object.keys(copyEmailErrors).length === 0) {
            if (email) {
                const { submitEmail } = this.props;
                const data = {
                    email,
                };
                submitEmail(data, t);
            }
        }
    };

    render() {
        const {
            t, passwordRecovery, closeModal, openLogin,
        } = this.props;
        const { email, emailErrors } = this.state;
        const customStyles = {
            content: {
                maxWidth: '50vw',
            },
        };

        return (
            <ModalWindow
                isOpen={passwordRecovery}
                style={customStyles}
                onRequestClose={() => closeModal()}
            >
                <h3
                    className={style.signIn__title}
                >
                    {t('auth.passwordRecovery')}
                </h3>
                <form className={style.signIn__form} autoComplete="off">
                    <div className={style.signIn__inputWrapper}>
                        <Field
                            id="email"
                            type="email"
                            placeholder={t('auth.typeEmail')}
                            name="email"
                            value={email}
                            onChange={this.inputOnchange}
                            error={emailErrors}
                            inputStyle={style.signIn__input}
                            inputColor="#fff"
                        />
                    </div>
                    <div className={style.signIn__submitBtnWrapper}>
                        <Button
                            className={style.signIn__submitBtn}
                            type="button"
                            onClick={this.submit}
                        >
                            <span className={style.buttonText}>{t('general.send')}</span>
                        </Button>
                    </div>
                    <div onClick={() => openLogin()} className={style.signIn__signIn}>
                        {t('header.signIn')}
                    </div>
                </form>
            </ModalWindow>
        );
    }
}

const mapStateToProps = state => {
    const {
        authModal: { login, signUp, passwordRecovery },
    } = state;

    return {
        login,
        signUp,
        passwordRecovery,
    };
};

const mapDispatchToProps = (dispatch, { postService }) => bindActionCreators(
    {
        submitEmail: fetchPasswordRecoveryAction(postService),
        closeModal: () => authModalActions.closeModal(),
        openLogin: () => authModalActions.openLogin(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
)(PasswordRecovery);
