import { store } from 'react-notifications-component';
import { POST_DISABLE_TWO_FA } from '../constants';

const postDisabledTwoFaRequested = () => ({
    type: POST_DISABLE_TWO_FA.FETCH_DISABLE_TWO_FA_REQUEST,
});

const postDisabledTwoFaLoaded = data => ({
    type: POST_DISABLE_TWO_FA.FETCH_DISABLE_TWO_FA_SUCCESS,
    payload: data,
});

const postDisabledTwoFaError = error => ({
    type: POST_DISABLE_TWO_FA.FETCH_DISABLE_TWO_FA_FAILURE,
    payload: error,
});

const fetchDisabledTwoFaAction = postService => t => dispatch => {
    dispatch(postDisabledTwoFaRequested());
    postService
        .disabledTwoFa()
        .then(data => {
            dispatch(postDisabledTwoFaLoaded(data));
            store.addNotification({
                message: t('general.twoFaDisabled'),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        })
        .catch(err => {
            dispatch(postDisabledTwoFaError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            store.addNotification({
                message: err.response.data.message,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchDisabledTwoFaAction;
