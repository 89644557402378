import { GET_HISTORY_BALANCE } from '../constants';

const getHistoryBalanceRequested = () => ({
    type: GET_HISTORY_BALANCE.GET_HISTORY_BALANCE_REQUEST,
});

const getHistoryBalanceLoaded = data => ({
    type: GET_HISTORY_BALANCE.GET_HISTORY_BALANCE_SUCCESS,
    payload: data,
});

const getHistoryBalanceError = error => ({
    type: GET_HISTORY_BALANCE.GET_HISTORY_BALANCE_FAILURE,
    payload: error,
});

const getHistoryBalanceAction = getService => params => dispatch => {
    dispatch(getHistoryBalanceRequested());
    getService
        .getHistoryBalance(params)
        .then(data => {
            dispatch(getHistoryBalanceLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getHistoryBalanceError(err));
        });
};

export default getHistoryBalanceAction;
