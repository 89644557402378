/* eslint-disable camelcase */
import store from '../store';
import { TICKERS_CONSTANTS } from '../constants';
import { getCurrentTikerActions } from '../actions/current-ticker.actions';
import cnbIcon from '../components/assets/images/coins/cnb.svg';
import cngIcon from '../components/assets/images/coins/cng.svg';

const findTicker = () => {
    const {
        allTickers: {
            data: { id_ticker_currency, tickers },
        },
    } = store.getState();

    const find = tickers.filter(item => item.id === id_ticker_currency);
    let logo = '';

    if (find[0]?.id === TICKERS_CONSTANTS.CNG) {
        logo = cngIcon;
    }

    if (find[0]?.id === TICKERS_CONSTANTS.CNB) {
        logo = cnbIcon;
    }

    const result = {
        ...find[0],
        img: logo,
    };

    store.dispatch(getCurrentTikerActions.currentTicker(result));

    return result;
};

export default findTicker;
