import React, { Component } from 'react';
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import fetchVerificationWithdrawAction from '../../../actions/post-verification-withdraw.actions';
import withPostService from '../../hoc/with-post-service';
import { compose } from '../../../utils';
import spinnerImg from '../../assets/images/spinner.svg';
import style from './verification-withdraw.module.scss';

class VerificationWithdraw extends Component {
    mounted = true;

    static defaultProps = {
        t: () => {},
        verificationWithdraw: () => {},
        location: {},
        history: {},
        loggingIn: false,
        loading: true,
    };

    static propTypes = {
        t: PropTypes.func,
        verificationWithdraw: PropTypes.func,
        location: PropTypes.object,
        history: PropTypes.object,
        loggingIn: PropTypes.bool,
        loading: PropTypes.bool,
    };

    state = {
        loading: true,
    };

    componentDidMount() {
        const {
            t,
            history,
            location: { search },
            loggingIn,
            verificationWithdraw,
        } = this.props;
        if (!loggingIn) {
            history.push('/');
            store.addNotification({
                message: t('general.confirmWithdrawalNeedLogIn'),
                type: 'warning',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 5000,
                    pauseOnHover: true,
                },
            });
        } else {
            const arrayOfStrings = search.split('=');
            const data = {
                token: arrayOfStrings[1],
            };
            verificationWithdraw(data, t, history);
        }
    }

    componentDidUpdate(prevProps) {
        const { loading } = this.props;

        if (!loading && loading !== prevProps.loading && this.mounted) {
            this.setState({
                loading: false,
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { loading } = this.state;

        if (loading) {
            return (
                <div className={style.spinnerWrapper}>
                    <img className={style.spinner} src={spinnerImg} alt="spinner" />
                </div>
            );
        }

        return (
            <div className={style.verification} />
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        verificationWithdraw: { success, loading },
    } = state;

    return {
        loggingIn,
        success,
        loading,
    };
};

const mapDispatchToProps = (dispatch, { postService }) => bindActionCreators(
    {
        verificationWithdraw: fetchVerificationWithdrawAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(VerificationWithdraw);
