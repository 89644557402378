/* eslint-disable */
import { GET_CURRENT_BALANCE_CONSTANTS } from '../constants';

export const currentBalance = (state, action) => {
    if (state === undefined) {
        return {
            balance: {
                id: '',
                name: '',
                balance: ''
            },
        };
    }
    switch (action.type) {
        case GET_CURRENT_BALANCE_CONSTANTS.CURRENT_BALANCE:
            return {
                balance: action.balance,
            };
        default:
            return state;
    }
};
