/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { HashLink as Links } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import Burger from 'react-css-burger';
import NavigationMenu from '../../navigation-menu';
import {
    personalAreaPath,
    profileDataPath,
    balancePath,
    historyPath,
    documentsPath,
    faqPath,
} from '../../../../constants/pathLocation';
import Img from '../../../small-components/img/img';
import { authModalActions } from '../../../../actions/authModal.actions';
import logoutAction from '../../../../actions/logout.actions';
import Button from '../../../small-components/button';
import { compose } from '../../../../utils';
import SelectLanguage from '../../../language';
import SelectCoin from '../../select-coin';
import Balance from './balance';
import SettingsIcon from '../../../assets/images/menu-icon/settings_icon';
import logo from '../../../assets/images/logo.svg';
import profileIcon from '../../../assets/images/menu-icon/profile-icon.svg';
import balanceIcon from '../../../assets/images/menu-icon/balance-icon.svg';
import historyIcon from '../../../assets/images/menu-icon/history-icon.svg';
import documentsIcon from '../../../assets/images/menu-icon/documents-icon.svg';
import logoutIcon from '../../../assets/images/menu-icon/logout-icon.svg';
import style from './header.module.scss';
import './header.scss';
import 'antd/dist/antd.css';

const Header = ({
    dispatch,
    loggingIn,
    history,
    allGames,
    userInfo: { avatar, full_name: name },
    logoClick,
}) => {
    const { t } = useTranslation();
    const [dropDownMenuState, setDropDownMenuState] = useState(false);
    const [isOpenHistorySubMenu, switchOpenHistorySubMenu] = useState(false);
    const [activeBurger, setBurgerState] = useState(false);

    useEffect(() => () => {
        document.body.style.overflowY = 'visible';
    }, []);

    const toggleBurger = () => {
        setBurgerState(!activeBurger);
    };

    const showdropDownMenu = () => {
        setDropDownMenuState(!dropDownMenuState);
    };

    const historySubMenu = event => {
        event.stopPropagation();
        switchOpenHistorySubMenu(!isOpenHistorySubMenu);
    };

    const closeDropdown = () => {
        setDropDownMenuState(false);
    };

    const mobileHistoryTab = event => {
        const { value } = event.target;
        history.push(`${personalAreaPath}${historyPath}/${value}`);
    };

    const openLogin = () => {
        dispatch(authModalActions.openLogin());
    };

    const openSignUp = () => {
        dispatch(authModalActions.openSignUp());
    };

    const closeModal = () => {
        setBurgerState(!activeBurger);
    };

    const submenuStyle = isOpenHistorySubMenu
        ? style.header__openedSubmenu
        : style.header__closedSubmenu;

    const userAvatar = avatar
        ? `${process.env.REACT_APP_AVATAR_IMAGE_URL}${avatar}`
        : null;

    const ButtonWrapper = () => (
        <div className={style.header__rightSide_buttonWrapper}>
            <button className={style.header__login} onClick={openLogin} type="button">
                <span className={style.buttonText}>{t('header.signIn')}</span>
            </button>
            <button onClick={openSignUp} type="button" className={style.header__signUp}>
                <span className={style.buttonText}>{t('header.registration')}</span>
            </button>
        </div>
    );

    const HeaderUserInfo = () => (
        <Fragment>
            <OutsideClickHandler onOutsideClick={() => closeDropdown()}>
                <div
                    onClick={() => showdropDownMenu()}
                    className={style.header__settingsMenu}
                >
                    <SettingsIcon className={style.header__settingsMenu_icon} />
                    {dropDownMenuState ? (
                        <div className={style.header__settingsMenu_select}>
                            <NavigationMenu
                                containerStyle={style.header__nav}
                                asideLinkStyle={style.menuLink}
                                subLinkStyle={style.header__subLink}
                                openedSubmenu={style.header__openedSubmenu}
                                closedSubmenu={style.header__closedSubmenu}
                                isPushToBalance={false}
                                hideGamesTab
                            />
                        </div>
                    ) : null}
                </div>
            </OutsideClickHandler>
            <Link
                to={`${personalAreaPath}${profileDataPath}`}
                className={style.header__logoWrapper}
            >
                <Img
                    className={style.header__rightSide_logo}
                    src={userAvatar}
                    userName={name}
                />
                <p className={style.header__rightSide_name}>{name}</p>
            </Link>
        </Fragment>
    );

    const MobileMenuButtons = () => (
        <div className={style.header__rightSide_buttonWrapper}>
            <Button className={style.header__login} onClick={openLogin} type="button">
                <span className={style.buttonText}>{t('header.signIn')}</span>
            </Button>
            <Button onClick={openSignUp} type="button" className={style.header__signUp}>
                <span className={style.buttonText}>{t('header.registration')}</span>
            </Button>
        </div>
    );

    const drawerStyle = activeBurger ? style.drawer__opened : style.drawer__closed;

    if (activeBurger) {
        document.body.style.overflowY = 'hidden';
    } else {
        document.body.style.overflowY = 'visible';
    }

    return (
        <header className={style.header} id="header">
            <Link to="/" onClick={logoClick} className={style.header__logo}>
                <img src={logo} alt="logo" />
            </Link>
            <Balance />
            <div className={style.header__rightSide}>
                <SelectCoin />
                {loggingIn ? <HeaderUserInfo /> : <ButtonWrapper />}
                <div style={{ display: 'flex' }}>
                    <SelectLanguage />
                </div>
            </div>
            <div className={style.header__burgerMenu}>
                <div className="burgerMenu">
                    <Burger
                        onClick={() => toggleBurger()}
                        active={activeBurger}
                        burger="spin"
                        color="white"
                        marginTop="0"
                        scale={0.65}
                    />
                </div>
                <div className={drawerStyle}>
                    <div className={style.header__mobileMenu}>
                        <SelectCoin mobile />
                        <SelectLanguage mobile translation={t} />
                    </div>
                    {loggingIn ? (
                        <ul className={style.header__nav}>
                            <li>
                                <Link
                                    to={`${personalAreaPath}${profileDataPath}`}
                                    className={style.menuLink}
                                >
                                    <img
                                        className={style.icon}
                                        src={profileIcon}
                                        alt="profileIcon"
                                    />
                                    <span className={style.menuLink__text}>
                                        {t('aside.profile')}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`${personalAreaPath}${balancePath}`}
                                    className={style.menuLink}
                                >
                                    <img
                                        className={style.icon}
                                        src={balanceIcon}
                                        alt="balanceIcon"
                                    />
                                    <span className={style.menuLink__text}>
                                        {t('aside.balance')}
                                    </span>
                                </Link>
                            </li>
                            <li className={style.header__mobileSelect}>
                                <select value="defaultValue" onChange={mobileHistoryTab}>
                                    <option value="defaultValue" disabled>
                                        {t('general.selectOption')}
                                    </option>
                                    {allGames.map(item => {
                                        const { title, id } = item;

                                        return (
                                            <option value={id} key={id}>
                                                {title}
                                            </option>
                                        );
                                    })}
                                </select>
                                <img
                                    className={style.icon}
                                    src={historyIcon}
                                    alt="historyIcon"
                                />
                                <span className={style.menuLink__text}>
                                    {t('aside.history')}
                                </span>
                            </li>
                            <li className={style.header__historyLink}>
                                <div
                                    className={classNames(
                                        style.menuLink,
                                        style.menuLinkHistory,
                                        isOpenHistorySubMenu ? 'active' : '',
                                    )}
                                    onClick={event => historySubMenu(event)}
                                >
                                    <img
                                        className={style.icon}
                                        src={historyIcon}
                                        alt="historyIcon"
                                    />
                                    <span className={style.menuLink__text}>
                                        {t('aside.history')}
                                    </span>
                                </div>
                                <div id="subMenu headerSubMenu" className={submenuStyle}>
                                    {allGames.map(item => {
                                        const { title, id } = item;

                                        return (
                                            <Link
                                                to={`${personalAreaPath}${historyPath}/${id}`}
                                                className={style.header__subLink}
                                                key={id}
                                            >
                                                {title}
                                            </Link>
                                        );
                                    })}
                                </div>
                            </li>
                            <li>
                                <Link
                                    to={`${personalAreaPath}${documentsPath}`}
                                    className={style.menuLink}
                                >
                                    <img
                                        className={style.icon}
                                        src={documentsIcon}
                                        alt="documentsIcon"
                                    />
                                    <span className={style.menuLink__text}>
                                        {t('aside.documents')}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <div
                                    className={style.menuLink}
                                    onClick={() => dispatch(logoutAction(history))}
                                >
                                    <img
                                        className={style.icon}
                                        src={logoutIcon}
                                        alt="logoutIcon"
                                    />
                                    <span className={style.menuLink__text}>
                                        {t('aside.logout')}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    ) : (
                        <MobileMenuButtons />
                    )}
                    <div className={style.header__mobileMenu}>
                        <Links
                            to="/#games"
                            className={style.header__mobileMenu_links}
                            onClick={() => closeModal()}
                        >
                            {t('aside.games')}
                        </Links>
                        <Link
                            to={faqPath}
                            className={style.header__mobileMenu_links}
                            onClick={() => closeModal()}
                        >
                            FAQ
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

const mapStateToProps = state => {
    const {
        authModal: { login },
        authentication: { loggingIn },
        userInfo: { data: userInfo },
        allGames: { data: allGames },
    } = state;

    return {
        login,
        loggingIn,
        userInfo,
        allGames,
    };
};

Header.defaultProps = {
    dispatch: () => {},
    logoClick: () => {},
    loggingIn: false,
    userInfo: {},
    history: {},
    allGames: [],
};

Header.propTypes = {
    dispatch: PropTypes.func,
    logoClick: PropTypes.func,
    loggingIn: PropTypes.bool,
    userInfo: PropTypes.object,
    history: PropTypes.object,
    allGames: PropTypes.instanceOf(Array),
};

export default compose(connect(mapStateToProps), withRouter)(Header);
