import React from 'react';
import PropTypes from 'prop-types';

const SmartAddressIcon = ({ className }) => (
    <svg
        className={className}
        width="42"
        height="41"
        viewBox="0 0 42 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.2988 24.6C16.2358 23.537 15.3753 22.1704 14.7679 20.6519C14.4136 19.7407 14.8691 18.7284 15.8309 18.3741C16.2864 18.2222 16.742 18.2222 17.1975 18.4247C17.6025 18.6272 17.9568 18.9815 18.1086 19.437C18.463 20.4494 19.0704 21.3605 19.8296 22.1198C21.1457 23.4358 22.9679 24.1951 24.8914 24.1951C26.7642 24.1951 28.637 23.4358 29.9531 22.1198L36.3309 15.7926C37.6469 14.4765 38.4062 12.6543 38.4062 10.7309C38.4062 8.85802 37.6469 6.98519 36.3309 5.66914C33.5469 2.88519 28.9914 2.88519 26.2074 5.66914L20.2852 11.5914C19.9309 11.9457 19.4247 12.1481 18.9691 12.0975C18.5136 12.0975 18.058 11.8951 17.8049 11.5914C17.4506 11.237 17.2481 10.7815 17.2988 10.2753C17.2988 9.81975 17.5012 9.3642 17.8049 9.11111L23.6765 3.13827C25.7012 1.11358 28.384 0 31.2691 0C31.2691 0 31.2691 0 31.3198 0C34.1543 0 36.837 1.11358 38.8617 3.13827C40.8864 5.16296 42 7.84568 42 10.7309C42 13.5654 40.8864 16.2481 38.8617 18.3235L32.5346 24.6C30.5099 26.6247 27.8272 27.7383 24.942 27.7383C22.1074 27.7383 19.3741 26.6247 17.2988 24.6Z"
            fill="white"
        />
        <path
            d="M4.03719 22.6766L10.415 16.4C12.4397 14.3753 15.1224 13.2617 18.0076 13.2617C20.8421 13.2617 23.5248 14.3753 25.6002 16.4C26.6631 17.463 27.5236 18.8297 28.131 20.3482C28.2829 20.8037 28.2829 21.2593 28.0804 21.7148C27.8779 22.1198 27.5236 22.4741 27.0681 22.6259C26.1569 22.9803 25.1446 22.5247 24.7903 21.563C24.3853 20.5506 23.8285 19.6395 23.0693 18.8803C21.7532 17.5642 19.931 16.805 18.0076 16.805C16.1347 16.805 14.2619 17.5642 12.9458 18.8803L6.61867 25.2074C5.30262 26.5235 4.54336 28.3457 4.54336 30.2692C4.54336 32.142 5.30262 34.0148 6.61867 35.3309C7.93472 36.6976 9.70632 37.4568 11.5792 37.4568H11.6298C13.5026 37.4568 15.3248 36.6976 16.6409 35.3815L22.6644 29.4593C23.0187 29.105 23.4742 28.9025 23.9804 28.9531C24.436 28.9531 24.8915 29.1556 25.1446 29.4593C25.4989 29.8136 25.7014 30.2692 25.6508 30.7753C25.6508 31.2309 25.4483 31.6864 25.1446 31.9395L19.2224 37.8617C17.1977 39.8864 14.515 41 11.6298 41C8.79521 41 6.1125 39.8864 4.03719 37.8617C2.0125 35.8371 0.898918 33.1543 0.898918 30.2692C0.949535 27.4346 2.0125 24.7519 4.03719 22.6766Z"
            fill="white"
        />
    </svg>
);

SmartAddressIcon.defaultProps = {
    className: '',
};

SmartAddressIcon.propTypes = {
    className: PropTypes.string,
};

export default SmartAddressIcon;
