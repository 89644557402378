/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from '../../../../../utils';
import toFixedBigValue from '../../../../../helpers/big-number';
import Field from '../../../../small-components/field';
import style from './withdrawal.module.scss';
import './withdrawal.scss';

const WithdrawalView = ({
    address,
    amount,
    addressErrors,
    amountErrors,
    inputOnchange,
    setMaxAmount,
    submit,
    createWithdrawLoading,
    transactionInfo: { fee },
    tickerData: { name },
    transactionLoading,
}) => {
    const { t } = useTranslation();

    const total = +amount > +fee ? toFixedBigValue(+amount - +fee, 8) : '';

    const feeStyle = !transactionLoading && fee
        ? classNames(style.opacity, style.withdrawal__fee)
        : classNames(style.withdrawal__fee);

    return (
        <Fragment>
            <div className={style.withdrawal__header}>
                <p className={style.withdrawal__header_title}>
                    {t('general.withdrawal')}
                </p>
            </div>
            <div className={style.withdrawal__container}>
                <div className={style.withdrawal__wrapper}>
                    <div className={style.withdrawal__inputWrapper}>
                        <Field
                            id="address"
                            type="text"
                            placeholder={t('general.typeHere')}
                            name="address"
                            value={address}
                            onChange={inputOnchange}
                            error={addressErrors}
                            inputStyle={style.withdrawal__input}
                            inputColor="#B0AED3"
                            labelText={t('general.withdrawalAddress')}
                            labelStyle={style.withdrawal__label}
                            disabled={transactionLoading}
                        />
                    </div>
                    <div className={style.withdrawal__inputWrapper}>
                        <Field
                            id="amount"
                            type="text"
                            placeholder={t('general.typeHere')}
                            name="amount"
                            value={amount}
                            onChange={inputOnchange}
                            error={amountErrors}
                            inputStyle={style.withdrawal__amountInput}
                            inputColor="#B0AED3"
                            labelText={`${t('general.amount')} ${name}`}
                            labelStyle={style.withdrawal__label}
                            isButton
                            buttonStyle={style.withdrawal__maxBtn}
                            buttonClick={setMaxAmount}
                            buttonText={t('general.max')}
                            disabled={transactionLoading}
                        />
                    </div>
                    <div className={style.withdrawal__inputWrapper}>
                        <Field
                            id="total"
                            type="text"
                            name="total"
                            value={total}
                            inputStyle={style.withdrawal__input}
                            inputColor="#B0AED3"
                            labelText={`${t('general.total')} ${name}`}
                            labelStyle={style.withdrawal__label}
                            disabled
                        />
                        <p className={feeStyle}>
                            {t('general.fee')}: {fee} {name}
                        </p>
                    </div>
                </div>
                <button
                    type="button"
                    className={style.withdrawal__submitBtn}
                    onClick={submit}
                    disabled={createWithdrawLoading}
                >
                    <span className={style.buttonText}>{t('general.withdrawalRequest')}</span>
                </button>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => {
    const {
        currentTicker: { tickerData },
        transactionInfo: { loading: transactionLoading },
    } = state;

    return {
        tickerData,
        transactionLoading,
    };
};

WithdrawalView.defaultProps = {
    address: '',
    amount: '',
    addressErrors: {},
    amountErrors: {},
    transactionInfo: {},
    inputOnchange: () => {},
    setMaxAmount: () => {},
    submit: () => {},
    createWithdrawLoading: false,
    transactionLoading: false,
    tickerData: {},
};

WithdrawalView.propTypes = {
    address: PropTypes.string,
    amount: PropTypes.any,
    addressErrors: PropTypes.object,
    amountErrors: PropTypes.object,
    transactionInfo: PropTypes.object,
    inputOnchange: PropTypes.func,
    setMaxAmount: PropTypes.func,
    submit: PropTypes.func,
    createWithdrawLoading: PropTypes.bool,
    transactionLoading: PropTypes.bool,
    tickerData: PropTypes.object,
};

export default compose(connect(mapStateToProps))(WithdrawalView);
