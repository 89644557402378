/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ErrorIndicator from '../../../error-page/error-indicator';
import withGetService from '../../../../hoc/with-get-service';
import withPostService from '../../../../hoc/with-post-service';
import { personalAreaPath, documentsPath } from '../../../../../constants';
import fetchCreateInvoiceAction from '../../../../../actions/post-create-invoice.actions';
import getTransactionInfoAction from '../../../../../actions/get-transacion-info.actions';
import DepositView from './deposit-view';
import { compose } from '../../../../../utils';
import spinnerImg from '../../../../assets/images/spinner.svg';
import style from './deposit.module.scss';

export class DepositContainer extends Component {
    mounted = true;

    state = {
        amount: '',
        amountErrors: {},
        loading: true,
        isOpenedInvoice: false,
    };

    componentDidMount() {
        const { getTransactionInfo } = this.props;
        getTransactionInfo();
    }

    componentDidUpdate(prevProps) {
        const {
            loading, success, switchTickerSuccess, getTransactionInfo,
        } = this.props;
        if (!loading && loading !== prevProps.loading && this.mounted) {
            this.setState({
                loading: false,
            });
        }

        if (success && success !== prevProps.success && this.mounted) {
            this.setState({
                isOpenedInvoice: true,
                amount: '',
                amountErrors: {},
            });
        }

        if (
            switchTickerSuccess
            && switchTickerSuccess !== prevProps.switchTickerSuccess
        ) {
            getTransactionInfo();

            this.setState({
                amount: '',
                amountErrors: {},
                isOpenedInvoice: false,
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    inputOnchange = event => {
        const { name, value } = event.target;
        this.validateFields(name, value);
    };

    validateFields = (name, value) => {
        const { t } = this.props;
        const onlyNumbers = /^[0-9]+$/;
        const { amount } = this.state;

        if (!amount.length) {
            this.setState(state => ({
                amountErrors: {
                    ...state.amountErrors,
                    amountError: t('error.field_can_not_be_empty'),
                },
            }));
        }

        if (value === '' || onlyNumbers.test(value)) {
            this.setState(
                {
                    [name]: value,
                },
                () => {
                    this.validateDepositAmount(value);
                },
            );
        }
    };

    validateDepositAmount = value => {
        const {
            t,
            transactionInfo: { minDeposit, maxDeposit },
        } = this.props;
        const isCorrectValue = +value < minDeposit || +value > maxDeposit;
        const isAmountError = isCorrectValue
            ? t('error.min_max_amount', {
                min: minDeposit,
                max: maxDeposit,
            })
            : '';

        this.setState(state => ({
            amountErrors: {
                ...state.amountErrors,
                amountError: isAmountError,
            },
        }));
    };

    submit = async () => {
        const {
            t,
            history,
            createInvoice,
            userInfo: { is_kyc },
        } = this.props;

        if (!is_kyc) {
            history.push(`${personalAreaPath}${documentsPath}`);
            return store.addNotification({
                message: t('error.proof_your_identify'),
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        }
        await this.validateFields();

        const { amount, amountErrors } = this.state;

        const copyAmountErrors = { ...amountErrors };

        Object.keys(copyAmountErrors).forEach(key => {
            if (!copyAmountErrors[key]) delete copyAmountErrors[key];
        });

        if (!Object.keys(copyAmountErrors).length) {
            if (amount) {
                const data = {
                    amount,
                };
                createInvoice(data, t, history);
            }
        }
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const {
            loading, error, amount, amountErrors, isOpenedInvoice,
        } = this.state;
        const { transactionInfo, createInvoiceLoading, createInvoiceData } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? (
            <img className={style.spinner} src={spinnerImg} alt="spinner" />
        ) : null;
        const content = hasData ? (
            <DepositView
                transactionInfo={transactionInfo}
                amount={amount}
                inputOnchange={this.inputOnchange}
                submit={this.submit}
                amountErrors={amountErrors}
                isOpenedInvoice={isOpenedInvoice}
                createInvoiceLoading={createInvoiceLoading}
                createInvoiceData={createInvoiceData}
            />
        ) : null;

        return (
            <div className={style.deposit}>
                {errorMessage}
                {spinner}
                {content}
            </div>
        );
    }
}

DepositContainer.defaultProps = {
    t: () => {},
    getTransactionInfo: () => {},
    createInvoice: () => {},
    transactionInfo: {},
    userInfo: {},
    history: {},
    createInvoiceData: {},
    loading: true,
    createInvoiceLoading: false,
    success: false,
    switchTickerSuccess: false,
};

DepositContainer.propTypes = {
    t: PropTypes.func,
    getTransactionInfo: PropTypes.func,
    createInvoice: PropTypes.func,
    transactionInfo: PropTypes.object,
    userInfo: PropTypes.object,
    history: PropTypes.object,
    createInvoiceData: PropTypes.object,
    loading: PropTypes.bool,
    createInvoiceLoading: PropTypes.bool,
    success: PropTypes.bool,
    switchTickerSuccess: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        transactionInfo: { data: transactionInfo, loading },
        createInvoice: {
            data: createInvoiceData,
            loading: createInvoiceLoading,
            success,
        },
        userInfo: { data: userInfo },
        switchTicker: { success: switchTickerSuccess },
    } = state;

    return {
        transactionInfo,
        loading,
        createInvoiceLoading,
        userInfo,
        success,
        createInvoiceData,
        switchTickerSuccess,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        getTransactionInfo: getTransactionInfoAction(getService),
        createInvoice: fetchCreateInvoiceAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(DepositContainer);
