import { store } from 'react-notifications-component';
import { POST_CHANGE_USER_DATA } from '../constants';

const postChangeUserDataRequested = () => ({
    type: POST_CHANGE_USER_DATA.FETCH_CHANGE_USER_DATA_REQUEST,
});

const postChangeUserDataLoaded = data => ({
    type: POST_CHANGE_USER_DATA.FETCH_CHANGE_USER_DATA_SUCCESS,
    payload: data,
});

const postChangeUserDataError = error => ({
    type: POST_CHANGE_USER_DATA.FETCH_CHANGE_USER_DATA_FAILURE,
    payload: error,
});

const fetchChangeUserDataAction = postService => (userData, t) => dispatch => {
    dispatch(postChangeUserDataRequested());
    postService
        .changeUserData(userData)
        .then(data => {
            dispatch(postChangeUserDataLoaded(data));
            store.addNotification({
                message: t('general.dataChangedSuccessfully'),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        })
        .catch(err => {
            dispatch(postChangeUserDataError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            store.addNotification({
                message: err.response.data.message,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchChangeUserDataAction;
