import React from 'react';
import { useTranslation } from 'react-i18next';

import redCoin from '../../../assets/images/redCoin.svg';
import bonusLogo1 from './images/bonusLogo1.svg';
import bonusLogo2 from './images/bonusLogo2.svg';
import bonusLogo3 from './images/bonusLogo3.svg';
import bonusLogo4 from './images/bonusLogo4.svg';
import freeSpin1 from './images/freeSpin1.svg';
import freeSpin5 from './images/freeSpin5.svg';
import style from './bonus.module.scss';

const Bonus = () => {
    const { t } = useTranslation();

    const data = [
        {
            img: bonusLogo1,
            spinImg: freeSpin5,
            title: t('bonusTitle1'),
            subTitle: t('bonusSubTitle1'),
        },
        {
            img: bonusLogo2,
            spinImg: freeSpin1,
            title: t('bonusTitle2'),
            subTitle: t('bonusSubTitle2'),
        },
        {
            img: bonusLogo3,
            spinImg: freeSpin1,
            title: t('bonusTitle3'),
            subTitle: t('bonusSubTitle3'),
        },
        {
            img: bonusLogo4,
            spinImg: freeSpin1,
            title: t('bonusTitle4'),
            subTitle: t('bonusSubTitle4'),
        },
    ];

    return (
        <div className={style.bonus} id="referralProgram">
            <img className={style.bonus__coin} src={redCoin} alt="redCoin" />
            <h3 className={style.bonus__title}>{t('bonusProgramComingSoon')}</h3>
            <p className={style.bonus__subTitle}>{t('amazingBonusAwaitsYou')}</p>
            <div className={style.bonus__wrapper}>
                {data.map(item => {
                    const {
                        img, spinImg, title, subTitle,
                    } = item;

                    return (
                        <div key={title} className={style.bonus__wrapper_item}>
                            <img
                                className={style.bonus__wrapper_img}
                                src={img}
                                alt="icon"
                            />
                            <div>
                                <p className={style.bonus__wrapper_title}>{title}</p>
                                <p className={style.bonus__wrapper_subTitle}>
                                    {subTitle}
                                </p>
                                <img
                                    className={style.bonus__wrapper_spinImg}
                                    src={spinImg}
                                    alt="icon"
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Bonus;
