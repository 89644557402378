/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import withPostService from '../../hoc/with-post-service';
import withGetService from '../../hoc/with-get-service';
import fetchSwitchTickerAction from '../../../actions/post-switch-ticker.actions';
import getAllTickersAction from '../../../actions/get-all-tickers.actions';
import getAllGamesAction from '../../../actions/get-all-games.actions';
import { compose } from '../../../utils';
import { TICKERS_CONSTANTS } from '../../../constants';
import findTicker from '../../../helpers/find-current-ticker';
import ArrowDownIcon from '../../assets/images/arrow-down';
import cnbIcon from '../../assets/images/coins/cnb.svg';
import cngIcon from '../../assets/images/coins/cng.svg';
import style from './select-coin.module.scss';

class CoinSelect extends Component {
    static defaultProps = {
        t: () => {},
        fetchSwitchTicker: () => {},
        getAllTickers: () => {},
        getAllGames: () => {},
        allTickers: [],
        tickerData: {},
        tickersSuccess: false,
        switchTickerSuccess: false,
        loggingIn: false,
        mobile: false,
        id_ticker_currency: '',
    };

    static propTypes = {
        t: PropTypes.func,
        fetchSwitchTicker: PropTypes.func,
        getAllTickers: PropTypes.func,
        getAllGames: PropTypes.func,
        allTickers: PropTypes.instanceOf(Array),
        tickerData: PropTypes.object,
        tickersSuccess: PropTypes.bool,
        switchTickerSuccess: PropTypes.bool,
        loggingIn: PropTypes.bool,
        mobile: PropTypes.bool,
        id_ticker_currency: PropTypes.any,
    };

    state = {
        dropDownMenuState: false,
    };

    componentDidUpdate(prevProps) {
        const {
            tickersSuccess, switchTickerSuccess, getAllTickers, getAllGames, mobile,
        } = this.props;

        if (tickersSuccess && tickersSuccess !== prevProps.tickersSuccess) {
            findTicker();
        }

        if (
            switchTickerSuccess
            && switchTickerSuccess !== prevProps.switchTickerSuccess
        ) {
            if (!mobile) {
                getAllTickers();
            }

            if (!mobile && window.location.pathname === '/') {
                getAllGames();
            }
        }
    }

    showdropDownMenu = () => {
        this.setState(state => ({
            dropDownMenuState: !state.dropDownMenuState,
        }));
    };

    closeDropdown = () => {
        this.setState({
            dropDownMenuState: false,
        });
    };

    switchTicker = (event, id) => {
        const { fetchSwitchTicker, t, id_ticker_currency } = this.props;
        if (id === id_ticker_currency) {
            store.addNotification({
                message: t('error.coinAlreadySelected'),
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });

            return;
        }
        const data = {
            id_ticker: id,
        };
        fetchSwitchTicker(data, t);
    };

    onSelectToken = data => {
        const { value } = data.target;
        this.switchTicker(data, value);
    }

    render() {
        const {
            t,
            allTickers,
            tickerData: { id: tickerId, img, name: tickerName },
            loggingIn,
            mobile,
        } = this.props;
        const { dropDownMenuState } = this.state;

        const arrowStyle = dropDownMenuState
            ? classNames(style.container__arrow, style.container__arrowRotate)
            : style.container__arrow;

        if (!loggingIn) {
            return null;
        }

        if (mobile) {
            return (
                <div className="mobileMenuLang">
                    <p className="mobileMenuLang__langTitle">{t('token')}</p>
                    <div className={style.container__top}>
                        <img src={img} alt="coin" />
                        <span>{tickerName}</span>
                    </div>
                    <select
                        className="personalAreaMobileSelect"
                        onChange={this.onSelectToken}
                        value={tickerId}
                    >
                        {allTickers.map(item => {
                            const { id, name } = item;

                            return (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            );
                        })}
                    </select>
                    <ArrowDownIcon className="mobileMenuLang__arrow" />
                </div>
            );
        }

        return (
            <div className={style.container}>
                <OutsideClickHandler onOutsideClick={this.closeDropdown}>
                    <div onClick={this.showdropDownMenu}>
                        <div className={style.container__top}>
                            <img src={img} alt="coin" />
                            <span>{tickerName}</span>
                            <ArrowDownIcon className={arrowStyle} />
                        </div>
                        {dropDownMenuState ? (
                            <div className={style.container__dropdown}>
                                {allTickers.map(item => {
                                    const { id, name } = item;

                                    let logo = '';

                                    if (id === TICKERS_CONSTANTS.CNG) {
                                        logo = cngIcon;
                                    }

                                    if (id === TICKERS_CONSTANTS.CNB) {
                                        logo = cnbIcon;
                                    }

                                    return (
                                        <div
                                            key={id}
                                            className={style.container__dropdown_row}
                                            onClick={event => this.switchTicker(event, id)
                                            }
                                        >
                                            <img src={logo} alt="coin" />
                                            <span>{name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                </OutsideClickHandler>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        allTickers: {
            data: { tickers: allTickers, id_ticker_currency },
            success: tickersSuccess,
        },
        currentTicker: { tickerData },
        switchTicker: { success: switchTickerSuccess },
        authentication: { loggingIn },
    } = state;

    return {
        allTickers,
        tickersSuccess,
        tickerData,
        switchTickerSuccess,
        id_ticker_currency,
        loggingIn,
    };
};

const mapDispatchToProps = (dispatch, { postService, getService }) => bindActionCreators(
    {
        fetchSwitchTicker: fetchSwitchTickerAction(postService),
        getAllTickers: getAllTickersAction(getService),
        getAllGames: getAllGamesAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
)(CoinSelect);
