import React from 'react';
import NavigationMenu from '../../../../layouts/navigation-menu';
import backgroundImage from './images/left-aside-image.svg';
import style from './aside.module.scss';
import './aside.scss';

const Aside = () => (
    <aside id="aside" className={style.aside}>
        <NavigationMenu
            containerStyle={style.aside__nav}
            textStyle={style.asideLink__text}
            asideLinkStyle={style.asideLink}
            subLinkStyle={style.aside__subLink}
            openedSubmenu={style.aside__openedSubmenu}
            closedSubmenu={style.aside__closedSubmenu}
        />
        <img
            className={style.aside__backgroundImage}
            src={backgroundImage}
            alt="backgroundImage"
        />
    </aside>
);

export default Aside;
