/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SelectSearch from 'react-select-search';
import { Checkbox } from 'antd';
import Button from '../../small-components/button';
import withGetService from '../../hoc/with-get-service';
import GetService from '../../../services/get-service';
import registrationAction from '../../../actions/registration.actions';
import { termOfUsePath, privacyPolicyPath } from '../../../constants';
import getCurrentLocale from '../../../helpers/getCurrentLocale';
import getAllCountriesAction from '../../../actions/get-all-countries.actions';
import { authModalActions } from '../../../actions/authModal.actions';
import { compose } from '../../../utils';
import Field from '../../small-components/field';
import eye from '../../assets/images/white-eye.svg';
import ModalWindow from '../../small-components/modal-window/modal-widow';
import {
    emailValid,
    oneLowercaseChar,
    oneUppercaseChar,
    oneNumber,
    oneSpecialChar,
} from '../../../helpers';
import style from './registration.module.scss';

class Registration extends PureComponent {
    getService = new GetService();

    static defaultProps = {
        t: () => {},
        getAllCountries: () => {},
        closeModal: () => {},
        openLogin: () => {},
        submitRegistration: () => {},
        getService: {},
        login: false,
        signUp: false,
        registering: false,
        loading: false,
        allCountries: [],
    };

    static propTypes = {
        t: PropTypes.func,
        getAllCountries: PropTypes.func,
        closeModal: PropTypes.func,
        openLogin: PropTypes.func,
        submitRegistration: PropTypes.func,
        getService: PropTypes.object,
        login: PropTypes.bool,
        signUp: PropTypes.bool,
        registering: PropTypes.bool,
        loading: PropTypes.bool,
        allCountries: PropTypes.instanceOf(Array),
    };

    state = {
        user: {
            country: '',
            confirmPassword: '',
            email: '',
            userPasswordRegistration: '',
            termOfService: false,
        },
        confirmPasswordErrors: {
            confirmPasswordCharactersError: '',
        },
        countryErrors: '',
        emailErrors: {
            emailLengthError: '',
            emailCharactersError: '',
        },
        passwordErrors: {
            passwordCharactersError: '',
            passwordLengthError: '',
            passwordDigitError: '',
            passwordUpperCaseLetter: '',
            passwordLettersError: '',
        },
        termOfServiceError: '',
        type: 'password',
    };

    componentDidUpdate(prevProps) {
        const {
            registering, getAllCountries, allCountries, signUp,
        } = this.props;

        if (signUp && signUp !== prevProps.signUp) {
            if (!allCountries.length) {
                getAllCountries();
            }
            this.initializationCaptcha();
        }

        if (registering && registering !== prevProps.registering) {
            this.setState({
                user: {
                    country: '',
                    confirmPassword: '',
                    email: '',
                    userPasswordRegistration: '',
                    termOfService: false,
                },
                confirmPasswordErrors: {
                    confirmPasswordCharactersError: '',
                },
                countryErrors: '',
                emailErrors: {
                    emailLengthError: '',
                    emailCharactersError: '',
                },
                passwordErrors: {
                    passwordCharactersError: '',
                    passwordLengthError: '',
                    passwordDigitError: '',
                    passwordUpperCaseLetter: '',
                    passwordLettersError: '',
                },
                termOfServiceError: '',
                type: 'password',
            });
        }
    }

    initializationCaptcha = () => {
        const { getService, t, submitRegistration } = this.props;

        getService
            .startCaptcha()
            .then(data => {
                const { gtserver, gtuser_id } = data;
                window.initGeetest(
                    {
                        product: 'bind',
                        width: '100%',
                        lang: getCurrentLocale(),
                        gt: data.gt,
                        challenge: data.challenge,
                        new_captcha: data.new_captcha,
                        offline: !data.success,
                        https: true,
                    },
                    captchaObj => {
                        captchaObj
                            .onReady(() => {})
                            .onSuccess(() => {
                                const {
                                    user: { email, userPasswordRegistration, country },
                                } = this.state;
                                const captchaKeys = captchaObj.getValidate();
                                const user = {
                                    ...captchaKeys,
                                    country,
                                    email,
                                    password: userPasswordRegistration,
                                    gtserver,
                                    gtuser_id,
                                };
                                submitRegistration(user, t);
                                captchaObj.reset();
                            })
                            .onError(() => {
                                captchaObj.reset();
                            });
                        this.loadCaptcha = () => {
                            captchaObj.verify();
                        };
                    },
                );
            })
            .catch(err => {
                console.log(err, 'err');
            });
    };

    location = value => {
        this.setState(state => ({
            user: {
                ...state.user,
                country: value,
            },
            countryErrors: '',
        }));
    };

    inputOnchange = async event => {
        const { name, value } = event.target;
        const {
            user: { confirmPassword },
        } = this.state;

        if (name === 'email') {
            await this.emailValidation(name, value);
        }

        if (name === 'userPasswordRegistration') {
            await this.passwordValidation(name, value);
            await this.confirmPasswordValidation('confirmPassword', confirmPassword);
        }

        if (name === 'confirmPassword') {
            await this.confirmPasswordValidation(name, value);
        }
    };

    validateFields = () => {
        const { t } = this.props;
        const {
            user: {
                email,
                userPasswordRegistration,
                confirmPassword,
                country,
                termOfService,
            },
        } = this.state;

        if (country.length < 1) {
            this.setState({
                countryErrors: t('error.field_can_not_be_empty'),
            });
        }

        if (confirmPassword.length < 1) {
            this.setState(state => ({
                confirmPasswordErrors: {
                    ...state.confirmPasswordErrors,
                    confirmPasswordCharactersError: t('error.field_can_not_be_empty'),
                },
            }));
        }

        if (email.length < 1) {
            this.setState(state => ({
                emailErrors: {
                    ...state.emailErrors,
                    emailLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        }

        if (userPasswordRegistration.length < 1) {
            this.setState(state => ({
                passwordErrors: {
                    ...state.passwordErrors,
                    passwordLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        }

        if (!termOfService) {
            this.setState({
                termOfServiceError: t('error.field_can_not_be_empty'),
            });
        }
    };

    emailValidation = (name, value) => {
        const { t } = this.props;
        this.setState(state => ({
            user: {
                ...state.user,
                [name]: value.toLowerCase().trim(),
            },
            emailErrors: {
                ...state.emailErrors,
                wrongEmail: emailValid(value) ? t('error.wrong_email') : '',
                emailLengthError: '',
            },
        }));
    };

    passwordValidation = (name, value) => {
        const { t } = this.props;
        this.setState(state => ({
            user: {
                ...state.user,
                [name]: value.trim(),
            },
            passwordErrors: {
                ...state.passwordErrors,
                oneLowercaseChar: oneLowercaseChar(value)
                    ? t('error.one_lowercase_char')
                    : '',
                oneUppercaseChar: oneUppercaseChar(value)
                    ? t('error.one_upperrcase_char')
                    : '',
                oneNumber: oneNumber(value) ? t('error.one_number') : '',
                oneSpecialChar: oneSpecialChar(value) ? t('error.one_special_char') : '',
                minLength: value.length < 8 ? t('error.min_length', { digit: 8 }) : '',
                passwordLengthError: '',
            },
        }));
    };

    confirmPasswordValidation = (name, value) => {
        const { t } = this.props;
        const {
            user: { userPasswordRegistration },
        } = this.state;
        const errorText = value !== userPasswordRegistration ? t('error.password_does_not_match') : '';
        this.setState(state => ({
            user: {
                ...state.user,
                [name]: value.trim(),
            },
            confirmPasswordErrors: {
                passwordDoesntMatch: errorText,
                confirmPasswordCharactersError: '',
            },
        }));
    };

    registratiOnSubmit = async event => {
        event.preventDefault();
        await this.validateFields();
        const {
            user: {
                email,
                userPasswordRegistration,
                termOfService,
                confirmPassword,
                country,
            },
            emailErrors,
            passwordErrors,
            confirmPasswordErrors,
        } = this.state;

        const copyEmailErrors = Object.assign({}, emailErrors);
        const copyPasswordErrors = Object.assign({}, passwordErrors);
        const copyConfirmPassword = Object.assign({}, confirmPasswordErrors);

        Object.keys(copyEmailErrors).forEach(key => {
            if (!copyEmailErrors[key]) delete copyEmailErrors[key];
        });

        Object.keys(copyPasswordErrors).forEach(key => {
            if (!copyPasswordErrors[key]) delete copyPasswordErrors[key];
        });

        Object.keys(copyConfirmPassword).forEach(key => {
            if (!copyConfirmPassword[key]) delete copyConfirmPassword[key];
        });

        if (
            !Object.keys(copyEmailErrors).length
            && !Object.keys(copyPasswordErrors).length
            && !Object.keys(copyConfirmPassword).length
            && termOfService
            && country
            && email
            && confirmPassword
            && userPasswordRegistration
        ) {
            this.loadCaptcha();
        }
    };

    closeModal = () => {
        const { closeModal } = this.props;
        closeModal();
    };

    termOfUse = event => {
        const { t } = this.props;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                termOfService: event.target.checked,
            },
            termOfServiceError: event.target.checked
                ? ''
                : t('error.field_can_not_be_empty'),
        });
    };

    showHidePassword = () => {
        const { type } = this.state;
        this.setState({
            type: type === 'password' ? 'text' : 'password',
        });
    };

    render() {
        const {
            t, signUp, login, loading, allCountries, openLogin,
        } = this.props;
        const {
            emailErrors,
            passwordErrors,
            confirmPasswordErrors,
            countryErrors,
            termOfServiceError,
            user: {
                confirmPassword,
                email,
                userPasswordRegistration,
                country,
                termOfService,
            },
            type,
        } = this.state;
        const customStyles = {
            content: {
                maxWidth: '50vw',
            },
        };

        if (login || signUp) {
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'visible';
        }

        return (
            <ModalWindow
                isOpen={signUp}
                style={customStyles}
                onRequestClose={this.closeModal}
            >
                <h3 className={style.registration__title}>{t('header.registration')}</h3>
                <form className={style.registration__form} autoComplete="off">
                    <div className={style.registration__inputWrapper}>
                        <Field
                            id="email"
                            type="email"
                            placeholder={t('auth.typeEmail')}
                            name="email"
                            value={email}
                            onChange={this.inputOnchange}
                            error={emailErrors}
                            inputStyle={style.registration__input}
                            inputColor="#fff"
                        />
                    </div>
                    <div className={style.registration__inputWrapper}>
                        <Field
                            id="userPasswordRegistration"
                            type={type}
                            placeholder={t('auth.typePassword')}
                            name="userPasswordRegistration"
                            value={userPasswordRegistration}
                            onChange={this.inputOnchange}
                            error={passwordErrors}
                            inputStyle={style.registration__input}
                            inputColor="#fff"
                            passwordType
                        />
                        {userPasswordRegistration.length >= 1 ? (
                            <div
                                onClick={this.showHidePassword}
                                className={style.registration__inputWrapper_eye}
                            >
                                <img src={eye} alt="eye" />
                            </div>
                        ) : null}
                    </div>
                    <div className={style.registration__inputWrapper}>
                        <Field
                            id="confirmPassword"
                            type={type}
                            placeholder={t('general.confirmPassword')}
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={this.inputOnchange}
                            error={confirmPasswordErrors}
                            inputStyle={style.registration__input}
                            inputColor="#fff"
                            passwordType
                        />
                        {confirmPassword.length >= 1 ? (
                            <div
                                onClick={this.showHidePassword}
                                className={style.registration__inputWrapper_eye}
                            >
                                <img src={eye} alt="eye" />
                            </div>
                        ) : null}
                    </div>
                    <div className={style.registration__select}>
                        <p
                            className={classNames(
                                style.registration__label,
                                countryErrors ? style.registration__labelError : {},
                            )}
                        >
                            {t('auth.countryOfResidence')}
                        </p>
                        <div
                            className={classNames(
                                style.frame,
                                countryErrors ? style.frame__error : {},
                            )}
                        >
                            <SelectSearch
                                name="country"
                                mode="input"
                                value={country}
                                options={allCountries}
                                placeholder={t('general.selectFromList')}
                                onChange={this.location}
                                search
                            />
                        </div>
                    </div>
                    <div className={style.registration__checkBoxWrapper}>
                        <span className={style.registration__checkbox}>
                            <div className={style.registration__checkbox_wrapper}>
                                <Checkbox
                                    checked={termOfService}
                                    onChange={this.termOfUse}
                                >
                                    <span
                                        className={classNames(
                                            style.registration__checkbox_title,
                                            termOfServiceError
                                                ? style.registration__checkbox_titleError
                                                : {},
                                        )}
                                    >
                                        {t('auth.iAm18years')}
                                        <Link
                                            target="_blank"
                                            to={termOfUsePath}
                                            className={style.registration__checkbox_link}
                                        >
                                            {t('auth.termsOfLicenseAgreement')}.
                                        </Link>{' '}
                                        {t('auth.iHaveReadAndFullyUnderstand')}
                                        <Link
                                            target="_blank"
                                            to={privacyPolicyPath}
                                            className={style.registration__checkbox_link}
                                        >
                                            {t('auth.privacyPolicyCheckbox')}
                                        </Link>
                                    </span>
                                </Checkbox>
                            </div>
                        </span>
                    </div>
                    <div className={style.registration__submitBtnWrapper}>
                        <Button
                            className={style.registration__submitBtn}
                            type="button"
                            onClick={this.registratiOnSubmit}
                            loading={loading}
                        >
                            <span className={style.buttonText}>
                                {t('auth.createAccount')}
                            </span>
                        </Button>
                    </div>
                    <div
                        onClick={() => openLogin()}
                        className={style.registration__alreadyHaveAccount}
                    >
                        {t('auth.alreadyHaveAccount')}
                    </div>
                </form>
            </ModalWindow>
        );
    }
}

const mapStateToProps = state => {
    const {
        authModal: { signUp, login },
        registration: { registering, loading },
        allCountries: { data: allCountries },
    } = state;

    return {
        signUp,
        login,
        registering,
        loading,
        allCountries,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAllCountries: getAllCountriesAction(getService),
        submitRegistration: (user, t) => registrationAction(user, t),
        closeModal: () => authModalActions.closeModal(),
        openLogin: () => authModalActions.openLogin(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Registration);
