const INCORRECT_PASSWORD_OR_EMAIL = '1';
const USER_IS_NOT_FOUND = '2';
const EMAIL_ADDRESS_ALREADY_TAKEN = '11';
const INVALID_PASSWORD_RECOVERY_LINK = '12';
const INVALID_2FA_CODE = '14';
const INCORRECT_KYC = '16';
const INSUFFICIENT_FUNDS = '18';
const USER_NOT_VERIFICATION = '19';
const GAME_IS_NOT_AVAILABLE = 23;
const ADDRESS_IS_INVALID = 'address: The address is invalid';

export {
    INCORRECT_PASSWORD_OR_EMAIL,
    USER_IS_NOT_FOUND,
    EMAIL_ADDRESS_ALREADY_TAKEN,
    INVALID_PASSWORD_RECOVERY_LINK,
    INVALID_2FA_CODE,
    INCORRECT_KYC,
    USER_NOT_VERIFICATION,
    INSUFFICIENT_FUNDS,
    GAME_IS_NOT_AVAILABLE,
    ADDRESS_IS_INVALID,
};
