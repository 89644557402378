import React from 'react';
import PropTypes from 'prop-types';

const ArrowDownIcon = ({ className }) => (
    <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.35156 5.3335H12.819L8.08526 10.6668L3.35156 5.3335Z"
            fill="white"
        />
    </svg>
);

ArrowDownIcon.defaultProps = {
    className: '',
};

ArrowDownIcon.propTypes = {
    className: PropTypes.string,
};

export default ArrowDownIcon;
