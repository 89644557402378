/* eslint-disable */

export const AUTH_MODAL = {
    OPEN_LOGIN: 'OPEN_LOGIN',
    OPEN_SIGNUP: 'OPEN_SIGNUP',
    CLOSE_AUTH_MODAL: 'CLOSE_AUTH_MODAL',
    OPEN_PASSWORD_RECOVERY: 'OPEN_PASSWORD_RECOVERY',
    TWO_FA: 'TWO_FA',
    WITHDRAW_TWO_FA: 'WITHDRAW_TWO_FA',
};
