import { POST_SWITCH_TICKER } from '../constants';

const switchTicker = (state, action) => {
    if (state === undefined) {
        return {
            data: [],
            loading: false,
            error: false,
            success: false,
        };
    }

    switch (action.type) {
    case POST_SWITCH_TICKER.FETCH_SWITCH_TICKER_REQUEST:
        return {
            data: [],
            loading: true,
            error: false,
            success: false,
        };

    case POST_SWITCH_TICKER.FETCH_SWITCH_TICKER_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
            success: true,
        };

    case POST_SWITCH_TICKER.FETCH_SWITCH_TICKER_FAILURE:
        return {
            data: [],
            loading: false,
            error: true,
            success: false,
        };

    default:
        return state;
    }
};

export default switchTicker;
