/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectSearch from 'react-select-search';
import InputMask from 'react-input-mask';
import { Radio } from 'antd';
import Field from '../../../../../small-components/field';
import firstStepIcon from './images/first-step-icon.svg';
import firstStepMobileIcon from './images/first-step-mobile-icon.svg';
import style from './step-one.module.scss';

const StepOne = ({
    firstStep: {
        firstName, lastName, birthday, city, address, zipCode, country, gender,
    },
    inputOnchange,
    birthdayInputOnchange,
    location,
    allCountries,
    radioOnChange,
    nextButton,
    errors: {
        firstNameError,
        lastNameError,
        birthdayError,
        cityError,
        addressError,
        zipCodeError,
        countryError,
        genderError,
    },
}) => {
    const { t } = useTranslation();

    const birthdayLabelStyle = birthdayError.lengthError
        ? style.step__inputContainer_labelError
        : style.step__inputContainer_label;

    const birthdayFrameStyle = birthdayError.lengthError
        ? classNames(style.frame, style.frame__error)
        : style.frame;

    const birthdayInputStyle = birthdayError.lengthError
        ? classNames(style.step__inputContainer_input_error)
        : style.step__inputContainer_input;

    const genderErrorStyle = genderError.lengthError
        ? classNames(style.step__inputContainer_radioError, 'kyc-radio-error')
        : '';

    const countryErrorStyle = countryError.lengthError
        ? classNames(style.step__selectError, 'kyc-select-error')
        : style.step__select;

    const countryFrameStyle = countryError.lengthError
        ? classNames(style.frame, style.frame__error)
        : style.frame;

    return (
        <div className={style.step}>
            <p className={style.step__title}>1. {t('kyc.basicInformation')}</p>
            <img
                className={style.step__stepIcon}
                src={firstStepIcon}
                alt="stepicon"
            />
            <img
                className={style.step__stepMobileIcon}
                src={firstStepMobileIcon}
                alt="stepicon"
            />
            <div className={style.step__description}>
                <p className={style.step__description_item}>
                    {t('kyc.basicInformation')}
                </p>
                <p className={style.step__description_item}>{t('kyc.uploadDocuments')}</p>
                <p className={style.step__description_item}>{t('kyc.veryfying')}</p>
            </div>
            <p className={style.step__title}>{t('kyc.personalInformation')}</p>
            <div className={style.step__inputContainer}>
                <div className={style.step__inputWrapper}>
                    <Field
                        id="firstName"
                        type="text"
                        placeholder={t('general.typeHere')}
                        name="firstName"
                        value={firstName}
                        onChange={inputOnchange}
                        error={firstNameError}
                        inputStyle={style.step__inputContainer_input}
                        inputColor="#C5C3DF"
                        labelStyle={style.step__inputContainer_label}
                        labelText={t('kyc.firstName')}
                    />
                </div>
                <div className={style.step__inputWrapper}>
                    <Field
                        id="lastName"
                        type="text"
                        placeholder={t('general.typeHere')}
                        name="lastName"
                        value={lastName}
                        onChange={inputOnchange}
                        error={lastNameError}
                        inputStyle={style.step__inputContainer_input}
                        inputColor="#C5C3DF"
                        labelStyle={style.step__inputContainer_label}
                        labelText={t('kyc.lastName')}
                    />
                </div>
                <div className={style.step__inputMaskWrapper}>
                    <p className={birthdayLabelStyle}>{t('kyc.birthDate')}</p>
                    <div className={birthdayFrameStyle}>
                        <InputMask
                            mask="99/99/9999"
                            placeholder={t('general.dd/mm/yyyy')}
                            id="birthday"
                            name="birthday"
                            value={birthday}
                            onChange={birthdayInputOnchange}
                            autoComplete="new-password"
                            className={birthdayInputStyle}
                        />
                    </div>
                </div>
                <div className={genderErrorStyle}>
                    <p className={style.step__inputContainer_labelRadio}>
                        {t('kyc.gender')}
                    </p>
                    <Radio.Group onChange={radioOnChange} value={gender}>
                        <Radio value={1}>{t('general.male')}</Radio>
                        <Radio value={2}>{t('general.female')}</Radio>
                    </Radio.Group>
                </div>
            </div>
            <p className={style.step__title}>{t('kyc.contactInformation')}</p>
            <div className={style.step__inputContainer}>
                <div className={countryErrorStyle}>
                    <p className={style.step__inputContainer_label}>
                        {t('auth.countryOfResidence')}
                    </p>
                    <div className={countryFrameStyle}>
                        <SelectSearch
                            name="country"
                            mode="input"
                            value={country}
                            options={allCountries}
                            placeholder={t('general.selectFromList')}
                            onChange={location}
                            search
                        />
                    </div>
                </div>
                <div className={style.step__inputWrapper}>
                    <Field
                        id="city"
                        type="text"
                        placeholder={t('general.typeHere')}
                        name="city"
                        value={city}
                        onChange={inputOnchange}
                        error={cityError}
                        inputStyle={style.step__inputContainer_inputBottom}
                        inputColor="#C5C3DF"
                        labelStyle={style.step__inputContainer_label}
                        labelText={t('kyc.city')}
                    />
                </div>
                <div className={style.step__inputWrapper}>
                    <Field
                        id="address"
                        type="text"
                        placeholder={t('general.typeHere')}
                        name="address"
                        value={address}
                        onChange={inputOnchange}
                        error={addressError}
                        inputStyle={style.step__inputContainer_inputBottom}
                        inputColor="#C5C3DF"
                        labelStyle={style.step__inputContainer_label}
                        labelText={t('kyc.address')}
                    />
                </div>
                <div className={style.step__inputWrapper}>
                    <Field
                        id="zipCode"
                        type="text"
                        placeholder={t('general.typeHere')}
                        name="zipCode"
                        value={zipCode}
                        onChange={inputOnchange}
                        error={zipCodeError}
                        inputStyle={style.step__inputContainer_inputBottom}
                        inputColor="#C5C3DF"
                        labelStyle={style.step__inputContainer_label}
                        labelText={t('kyc.zipCode')}
                    />
                </div>
            </div>
            <div className={style.step__nextButton} onClick={nextButton}>
                <span className={style.buttonText}>{t('general.next')}</span>
            </div>
        </div>
    );
};

StepOne.defaultProps = {
    firstStep: {},
    errors: {},
    inputOnchange: () => {},
    birthdayInputOnchange: () => {},
    location: () => {},
    radioOnChange: () => {},
    nextButton: () => {},
    allCountries: [],
};

StepOne.propTypes = {
    firstStep: PropTypes.object,
    errors: PropTypes.object,
    inputOnchange: PropTypes.func,
    birthdayInputOnchange: PropTypes.func,
    location: PropTypes.func,
    radioOnChange: PropTypes.func,
    nextButton: PropTypes.func,
    allCountries: PropTypes.instanceOf(Array),
};

export default StepOne;
