const loginPath = '/login';
const registartionPath = '/registration';
const passwordRecoveryPath = '/password-recovery';
const termOfUsePath = '/term-of-use';
const termOfServicePath = '/term-of-service';
const privacyPolicyPath = '/privacy-policy';
const personalAreaPath = '/personal-area';
const balancePath = '/balance';
const historyPath = '/history';
const profileDataPath = '/profile';
const documentsPath = '/documents';
const depositPath = '/balance/deposit';
const withdrawalPath = '/balance/withdrawal';
const historyBalancePath = '/balance/history';
const faqPath = '/faq';
const verificationUserPath = '/verification-user';
const gamePath = '/game';
const verificationWithdrawPath = '/verification-withdraw';

export {
    loginPath,
    registartionPath,
    termOfUsePath,
    termOfServicePath,
    privacyPolicyPath,
    personalAreaPath,
    balancePath,
    historyPath,
    profileDataPath,
    documentsPath,
    passwordRecoveryPath,
    depositPath,
    withdrawalPath,
    historyBalancePath,
    faqPath,
    verificationUserPath,
    gamePath,
    verificationWithdrawPath,
};
