import { GET_DOCUMENT_KYC } from '../constants';

const getDocumentKycRequested = () => ({
    type: GET_DOCUMENT_KYC.GET_DOCUMENT_KYC_REQUEST,
});

const getDocumentKycLoaded = data => ({
    type: GET_DOCUMENT_KYC.GET_DOCUMENT_KYC_SUCCESS,
    payload: data,
});

const getDocumentKycError = error => ({
    type: GET_DOCUMENT_KYC.GET_DOCUMENT_KYC_FAILURE,
    payload: error,
});

const getDocumentKycAction = getService => () => dispatch => {
    dispatch(getDocumentKycRequested());
    getService
        .getDocumentKyc()
        .then(data => {
            dispatch(getDocumentKycLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getDocumentKycError(err));
        });
};

export default getDocumentKycAction;
