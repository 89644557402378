import { GET_GENERATE_SECRET_GOOGLE_AUTH } from '../constants';

const getGenerateSecretGoogleAuthRequested = () => ({
    type: GET_GENERATE_SECRET_GOOGLE_AUTH.GET_GENERATE_SECRET_GOOGLE_AUTH_REQUEST,
});

const getGenerateSecretGoogleAuthLoaded = data => ({
    type: GET_GENERATE_SECRET_GOOGLE_AUTH.GET_GENERATE_SECRET_GOOGLE_AUTH_SUCCESS,
    payload: data,
});

const getGenerateSecretGoogleAuthError = error => ({
    type: GET_GENERATE_SECRET_GOOGLE_AUTH.GET_GENERATE_SECRET_GOOGLE_AUTH_FAILURE,
    payload: error,
});

const getGenerateSecretGoogleAuthAction = getService => () => dispatch => {
    dispatch(getGenerateSecretGoogleAuthRequested());
    getService
        .generateSecretGoogleAuth()
        .then(data => {
            dispatch(getGenerateSecretGoogleAuthLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getGenerateSecretGoogleAuthError(err));
        });
};

export default getGenerateSecretGoogleAuthAction;
