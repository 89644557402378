import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { store } from 'react-notifications-component';
import CopyIcon from '../../../assets/images/icons/copy-icon';
import blueCoin from '../../../assets/images/blueCoin.svg';
import smartContractBg from './images/smart-contractBg.svg';
import SmartAddressIcon from '../../../assets/images/icons/smart-address-icon';
import style from './smart-contract.module.scss';

const SmartContract = () => {
    const { t } = useTranslation();

    const copied = () => {
        store.addNotification({
            title: t('general.successNotification'),
            message: t('general.сopiedToClipboard'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'slideInRight'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
                duration: 3000,
                pauseOnHover: true,
            },
        });
    };

    return (
        <Fragment>
            <div className={style.smartContract}>
                <img
                    className={style.smartContract__coin}
                    src={blueCoin}
                    alt="blueCoin"
                />
                <img
                    className={style.smartContract__icons}
                    src={smartContractBg}
                    alt="smartContractBg"
                />
                <div>
                    <h3 className={style.smartContract__title}>
                        {t('smartContractAddress.title')}
                    </h3>
                    <div className={style.smartContract__referalWrapper}>
                        <p className={style.smartContract__referalWrapper_title}>
                            {t('smartContractAddress.address')}
                        </p>
                        <div className={style.smartContract__referalField}>
                            <div className={style.smartContract__referalField_icon}>
                                <SmartAddressIcon />
                            </div>
                            <p className={style.smartContract__referalField_link}>
                                https://etherscan.io/token/0x883a158c9b28f8d626acfcfbe1028f49e70c9d75
                            </p>
                            <CopyToClipboard
                                text="https://etherscan.io/token/0x883a158c9b28f8d626acfcfbe1028f49e70c9d75"
                                onCopy={() => copied()}
                            >
                                <div className={style.smartContract__referalField_copy}>
                                    <CopyIcon />
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SmartContract;
