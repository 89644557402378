import { GET_ALL_TICKERS } from '../constants';

const getAllTickersRequested = () => ({
    type: GET_ALL_TICKERS.GET_ALL_TICKERS_REQUEST,
});

const getAllTickersLoaded = data => ({
    type: GET_ALL_TICKERS.GET_ALL_TICKERS_SUCCESS,
    payload: data,
});

const getAllTickersError = error => ({
    type: GET_ALL_TICKERS.GET_ALL_TICKERS_FAILURE,
    payload: error,
});

const getAllTickersAction = getService => () => dispatch => {
    dispatch(getAllTickersRequested());
    getService
        .getAllTickers()
        .then(data => {
            dispatch(getAllTickersLoaded(data));
        })
        .catch(err => {
            dispatch(getAllTickersError(err));
        });
};

export default getAllTickersAction;
