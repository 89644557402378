import { GET_USER_BALANCE } from '../constants';

const getUserBalanceRequested = () => ({
    type: GET_USER_BALANCE.GET_USER_BALANCE_REQUEST,
});

const getUserBalanceLoaded = data => ({
    type: GET_USER_BALANCE.GET_USER_BALANCE_SUCCESS,
    payload: data,
});

const getUserBalanceError = error => ({
    type: GET_USER_BALANCE.GET_USER_BALANCE_FAILURE,
    payload: error,
});

const getUserBalanceAction = getService => () => dispatch => {
    dispatch(getUserBalanceRequested());
    getService
        .getUserBalance()
        .then(data => {
            dispatch(getUserBalanceLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getUserBalanceError(err));
        });
};

export default getUserBalanceAction;
