import { GET_SWITCH_LANGUAGE } from '../constants';

const getSwitchLanguageRequested = () => ({
    type: GET_SWITCH_LANGUAGE.GET_SWITCH_LANGUAGE_REQUEST,
});

const getSwitchLanguageLoaded = data => ({
    type: GET_SWITCH_LANGUAGE.GET_SWITCH_LANGUAGE_SUCCESS,
    payload: data,
});

const getSwitchLanguageError = error => ({
    type: GET_SWITCH_LANGUAGE.GET_SWITCH_LANGUAGE_FAILURE,
    payload: error,
});

const getSwitchLanguageAction = getService => () => dispatch => {
    dispatch(getSwitchLanguageRequested());
    getService
        .switchLanguage()
        .then(data => {
            dispatch(getSwitchLanguageLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getSwitchLanguageError(err));
        });
};

export default getSwitchLanguageAction;
