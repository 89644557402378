import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Pagination from 'rc-pagination';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from '../../../utils';
import { FilterActions } from '../../../actions/filter.actions';
import 'rc-pagination/assets/index.css';
import './pagination.scss';

export class PaginationComponent extends Component {
    state = {
        currentFilterParams: {},
    };

    textItemRender = (current, type, element) => {
        const { t } = this.props;
        if (type === 'prev') {
            return t('general.previous');
        }
        if (type === 'next') {
            return t('general.next');
        }
        return element;
    };

    changePaginationPage = page => {
        const { dispatch, filterParams } = this.props;
        this.setState(
            {
                currentFilterParams: {
                    ...filterParams,
                    currentPageNumber: page,
                },
            },
            () => {
                const { currentFilterParams: newfilter } = this.state;
                dispatch(FilterActions.selectFilter(newfilter));
            },
        );
    };

    render() {
        const {
            totalCount,
            filterParams: { currentPageNumber },
        } = this.props;

        return (
            <Fragment>
                <Pagination
                    showTitle={false}
                    total={totalCount}
                    onChange={this.changePaginationPage}
                    itemRender={this.textItemRender}
                    current={currentPageNumber || 1}
                />
            </Fragment>
        );
    }
}

PaginationComponent.defaultProps = {
    t: () => {},
    dispatch: () => {},
    filterParams: {},
    totalCount: 0,
};

PaginationComponent.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func,
    filterParams: PropTypes.object,
    totalCount: PropTypes.number,
};

const mapStateToProps = state => {
    const { filterParams: { data: filterParams } } = state;

    return {
        filterParams,
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
)(PaginationComponent);
