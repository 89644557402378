import { GET_TWO_FA_QR_CODE } from '../constants';

const getTwoFaQrCode = (state, action) => {
    if (state === undefined) {
        return {
            data: '',
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case GET_TWO_FA_QR_CODE.GET_TWO_FA_QR_CODE_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
        };

    case GET_TWO_FA_QR_CODE.GET_TWO_FA_QR_CODE_FAILURE:
        return {
            data: '',
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getTwoFaQrCode;
