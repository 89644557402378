import { message } from 'antd';
import axios from 'axios';

export default class PostService {
    getResource = async (url, data, method) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const currentLang = localStorage.getItem('i18nextLngCasion');
        const getToken = () => user.data.access_token;

        const options = {
            method,
            headers: {
                accessToken: user ? `${getToken()}` : null,
                language: currentLang,
            },
            data,
            url: `${process.env.REACT_APP_API_URL}${url}`,
        };
        const response = await axios(options);

        if (response.status !== 200) {
            response.json().then(errorMessage => {
                message.error(errorMessage.message ? errorMessage.message : errorMessage.error_description, 2);
            });

            if (response.status === 401) {
                this.logout();
            }

            throw new Error(
                `Could not fetch ${process.env.REACT_APP_API_URL}${url},
                received ${response.status}`,
            );
        }
        return response.data;
    };

    logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('updateTokenTime');
        document.location.reload(true);
    };

    changePassword = async data => {
        const res = await this.getResource('/api/change-password', data, 'POST');
        return res;
    };

    resetPassword = async data => {
        const res = await this.getResource('/api/reset-password', data, 'POST');
        return res;
    };

    passwordRecovery = async data => {
        const res = await this.getResource('/api/password-recovery', data, 'POST');
        return res;
    };

    checkResetToken = async data => {
        const res = await this.getResource('/api/check-reset-token', data, 'POST');
        return res;
    };

    checkResetToken = async data => {
        const res = await this.getResource('/api/check-reset-token', data, 'POST');
        return res;
    };

    changeUserData = async data => {
        const res = await this.getResource('/api/change-user-data', data, 'POST');
        return res;
    };

    uploadAvatar = async data => {
        const res = await this.getResource('/api/upload-avatar', data, 'POST');
        return res;
    };

    removeAvatar = async data => {
        const res = await this.getResource('/api/remove-avatar', data, 'POST');
        return res;
    };

    enabledTwoFa = async data => {
        const res = await this.getResource('/api/enabled-two-fa', data, 'POST');
        return res;
    };

    disabledTwoFa = async data => {
        const res = await this.getResource('/api/disabled-two-fa', data, 'POST');
        return res;
    };

    setDocumentKyc = async data => {
        const res = await this.getResource('/api/set-document-kyc', data, 'POST');
        return res;
    };

    createInvoice = async data => {
        const res = await this.getResource('/api/create-invoice', data, 'POST');
        return res;
    };

    createWithdraw = async data => {
        const res = await this.getResource('/api/create-withdraw', data, 'POST');
        return res;
    };

    verificationUser = async data => {
        const res = await this.getResource('/api/verification-user', data, 'POST');
        return res;
    };

    checkTwoFa = async data => {
        const res = await this.getResource('/api/check-two-fa', data, 'POST');
        return res;
    };

    verificationWithdraw = async data => {
        const res = await this.getResource('/api/verification-withdraw', data, 'POST');
        return res;
    };

    switchTicker = async data => {
        const res = await this.getResource('/api/switch-ticker', data, 'POST');
        return res;
    };
}
