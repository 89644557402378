import { GET_TWO_FA_QR_CODE } from '../constants';

const getTwoFaQrCodeRequested = () => ({
    type: GET_TWO_FA_QR_CODE.GET_TWO_FA_QR_CODE_REQUEST,
});

const getTwoFaQrCodeLoaded = data => ({
    type: GET_TWO_FA_QR_CODE.GET_TWO_FA_QR_CODE_SUCCESS,
    payload: data,
});

const getTwoFaQrCodeError = error => ({
    type: GET_TWO_FA_QR_CODE.GET_TWO_FA_QR_CODE_FAILURE,
    payload: error,
});

const getTwoFaQrCodeAction = getService => () => dispatch => {
    dispatch(getTwoFaQrCodeRequested());
    getService
        .get2FaQrCode()
        .then(data => {
            dispatch(getTwoFaQrCodeLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getTwoFaQrCodeError(err));
        });
};

export default getTwoFaQrCodeAction;
