import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import spinnerImg from '../../../../assets/images/spinner.svg';
import withGetService from '../../../../hoc/with-get-service';
import getUserBalanceAction from '../../../../../actions/get-user-balance.actions';
import ErrorIndicator from '../../../error-page/error-indicator';
import BalanceView from './balance-view';
import { compose } from '../../../../../utils';
import style from './balance.module.scss';

export class BalanceContainer extends Component {
    mounted = true;

    static defaultProps = {
        getUserBalance: () => {},
        balanceLoading: false,
        allTickersLoading: false,
    };

    static propTypes = {
        getUserBalance: PropTypes.func,
        balanceLoading: PropTypes.bool,
        allTickersLoading: PropTypes.bool,
    };

    state = {
        error: false,
        loading: true,
    };

    componentDidMount() {
        const { getUserBalance } = this.props;
        getUserBalance();
    }

    componentDidUpdate(prevProps) {
        const { balanceLoading } = this.props;
        if (!balanceLoading && balanceLoading !== prevProps.balanceLoading) {
            setTimeout(() => {
                if (this.mounted) {
                    this.setState({
                        loading: false,
                    });
                }
            }, 500);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onError = () => {
        this.setState({
            error: true,
        });
    };

    render() {
        const { error, loading } = this.state;
        const { allTickersLoading } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? (
            <img className={style.spinner} src={spinnerImg} alt="spinner" />
        ) : null;
        const content = hasData ? (
            <BalanceView
                allTickersLoading={allTickersLoading}
            />
        ) : null;

        return (
            <div className={style.balance}>
                {errorMessage}
                {spinner}
                {content}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        userBalance: { loading: balanceLoading },
        allTickers: { loading: allTickersLoading },
    } = state;

    return {
        loggingIn,
        balanceLoading,
        allTickersLoading,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getUserBalance: getUserBalanceAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
)(BalanceContainer);
