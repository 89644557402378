/* eslint-disable camelcase */
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import Countdown from 'react-countdown-now';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { compose } from '../../../../../utils';
import CopyIcon from '../../../../assets/images/icons/copy-icon';
import Field from '../../../../small-components/field';
import Button from '../../../../small-components/button';
import style from './deposit.module.scss';

const DepositView = ({
    transactionInfo: { minDeposit },
    amount,
    inputOnchange,
    amountErrors,
    submit,
    createInvoiceLoading,
    isOpenedInvoice,
    createInvoiceData: {
        amount: invoiceAmount, date_end, ticker, transactionAddress = '',
    } = {},
    tickerData: { name },
    transactionLoading,
}) => {
    const { t } = useTranslation();
    const [count, setCount] = useState(true);
    const timer = () => {
        setCount(false);
    };

    const invoiceBlockStyle = isOpenedInvoice
        ? classNames(style.invoiceBlock, style.invoiceBlock__opened)
        : classNames(style.invoiceBlock, style.invoiceBlock__closed);

    // eslint-disable-next-line react/prop-types
    const renderer = ({ hours, minutes, seconds }) => (
        <span className={style.aside__counter}>
            {hours}:{minutes}:{seconds}
        </span>
    );

    const copyTwoFaCode = () => {
        store.addNotification({
            title: t('general.successNotification'),
            message: t('general.сopiedToClipboard'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'slideInRight'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
                duration: 3000,
                pauseOnHover: true,
            },
        });
    };

    const opacityStyle = !count ? style.opacity : '';

    const minDepositStyle = !transactionLoading && minDeposit
        ? classNames(style.showDeposit, style.depositContainer__rightSide_minDeposit)
        : classNames(style.depositContainer__rightSide_minDeposit);

    return (
        <Fragment>
            <div className={style.deposit__header}>
                <p className={style.deposit__header_title}>{t('general.deposit')}</p>
            </div>
            <div className={style.depositContainer}>
                <div className={style.depositContainer__rightSide}>
                    <p className={style.depositContainer__rightSide_text}>
                        {t('general.enterDepositAmount')} <br />
                        {t('general.depositAttention')}
                    </p>
                    <p className={minDepositStyle}>
                        {t('general.minimumDepositAmount')}: {minDeposit} {name}
                    </p>
                    <div className={style.depositContainer__amountWrapper}>
                        <div className={style.depositContainer__inputWrapper}>
                            <Field
                                id="amount"
                                type="text"
                                placeholder={t('general.typeHere')}
                                name="amount"
                                disabled={transactionLoading}
                                value={amount}
                                onChange={inputOnchange}
                                error={amountErrors}
                                inputStyle={style.depositContainer__input}
                                inputColor="#B0AED3"
                                labelText={`${t('general.amount')} ${name}`}
                                labelStyle={style.depositContainer__label}
                            />
                        </div>
                        <Button
                            type="button"
                            className={style.depositContainer__submit}
                            onClick={submit}
                            disabled={createInvoiceLoading}
                            loading={createInvoiceLoading}
                        >
                            <span className={style.buttonText}>
                                {t('general.createInvoice')}
                            </span>
                        </Button>
                    </div>
                </div>
                <div className={opacityStyle}>
                    <div className={invoiceBlockStyle}>
                        <p className={style.invoiceBlock__title}>
                            {t('general.depositAmount')}: {invoiceAmount} {ticker}
                        </p>
                        <div className={style.invoiceBlock__wrapper}>
                            <QRCode value={transactionAddress} />
                            <div className={style.invoiceBlock__rightSide}>
                                <p className={style.invoiceBlock__rightSide_label}>
                                    {t('general.transactionAddress')}
                                </p>
                                <div className={style.frame}>
                                    <input
                                        id="code"
                                        type="text"
                                        className={style.invoiceBlock__codeInput}
                                        value={transactionAddress}
                                        name="code"
                                        readOnly
                                    />
                                    <CopyToClipboard
                                        text={transactionAddress}
                                        onCopy={copyTwoFaCode}
                                    >
                                        <div className={style.invoiceBlock__qrCodeValueBtn}>
                                            <span className={style.buttonText}>
                                                <CopyIcon />
                                            </span>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                                <p className={style.depositContainer__rightSide_text}>
                                    {t('general.sendOnlyToken', { token: name })}
                                </p>
                            </div>
                        </div>
                        <div className={style.invoiceBlock__countdown}>
                            <p className={style.invoiceBlock__countdown_title}>
                                {t('general.invoiceActiveTime')}
                            </p>
                            <Countdown
                                onComplete={timer}
                                date={date_end * 1000}
                                // date={Date.now() + 5000}
                                renderer={renderer}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => {
    const {
        currentTicker: { tickerData },
        transactionInfo: { loading: transactionLoading },
    } = state;

    return {
        tickerData,
        transactionLoading,
    };
};

DepositView.defaultProps = {
    transactionInfo: {},
    amount: '',
    inputOnchange: () => {},
    submit: () => {},
    amountErrors: {},
    createInvoiceLoading: false,
    isOpenedInvoice: false,
    transactionLoading: false,
    createInvoiceData: {},
    tickerData: {},
};

DepositView.propTypes = {
    transactionInfo: PropTypes.object,
    amount: PropTypes.string,
    inputOnchange: PropTypes.func,
    submit: PropTypes.func,
    amountErrors: PropTypes.object,
    createInvoiceLoading: PropTypes.bool,
    transactionLoading: PropTypes.bool,
    isOpenedInvoice: PropTypes.bool,
    createInvoiceData: PropTypes.object,
    tickerData: PropTypes.object,
};

export default compose(connect(mapStateToProps))(DepositView);
