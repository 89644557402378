/* eslint-disable */
import { GET_CURRENT_TIKER_CONSTANTS } from '../constants';

export const currentTicker = (state, action) => {
    if (state === undefined) {
        return {
            tickerData: {},
        };
    }
    switch (action.type) {
        case GET_CURRENT_TIKER_CONSTANTS.CURRENT_TICKER:
            return {
                tickerData: action.ticker,
            };
        default:
            return state;
    }
};
