import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from '../../utils';
import i18n from '../../i18n';
import withGetService from '../hoc/with-get-service';
import getSwitchLanguageAction from '../../actions/get-switch-language.actions';
import { getCurrentLocaleActions } from '../../actions/current-locale.actions';
import USICON from '../assets/images/flags/united-states.svg';
import RUCON from '../assets/images/flags/russia.svg';
import ArrowDownIcon from '../assets/images/arrow-down';
import './change-language.scss';

class SelectLangeage extends Component {
    componentDidMount() {
        const { setLocale } = this.props;
        const currentLang = localStorage.getItem('i18nextLngCasion');
        setLocale(currentLang);
    }

    onSelectFlag = countryCode => {
        const {
            setLocale, switchLocale, loggedIn,
        } = this.props;
        if (countryCode.target) {
            const { value } = countryCode.target;
            i18n.changeLanguage(value);
            localStorage.setItem('i18nextLngCasion', value);
            setLocale(value);
        } else {
            i18n.changeLanguage(countryCode);
            localStorage.setItem('i18nextLngCasion', countryCode);
            setLocale(countryCode);
        }
        if (loggedIn) {
            switchLocale();
        }
    };

    render() {
        const {
            mobile, translation, locale,
        } = this.props;
        const currentLang = localStorage.getItem('i18nextLngCasion');

        let fullLangName = '';

        if (currentLang === 'US') {
            fullLangName = 'English';
        } else if (currentLang === 'RU') {
            fullLangName = 'Русский';
        } else {
            fullLangName = currentLang;
        }

        if (mobile) {
            return (
                <div className="mobileMenuLang">
                    <p className="mobileMenuLang__langTitle">
                        {translation('general.language')}
                    </p>
                    <p className="currentLangTicker">{fullLangName}</p>
                    <select
                        className="personalAreaMobileSelect"
                        onChange={this.onSelectFlag}
                        value={currentLang}
                    >
                        <option value="US">English</option>
                        <option value="RU">Русский</option>
                    </select>
                    <ArrowDownIcon className="mobileMenuLang__arrow" />
                </div>
            );
        }

        let flagLocale = '';
        let nextLang = '';

        if (locale === 'RU') {
            flagLocale = RUCON;
            nextLang = 'US';
        }

        if (locale === 'US') {
            flagLocale = USICON;
            nextLang = 'RU';
        }

        return (
            <div
                onClick={() => this.onSelectFlag(nextLang)}
            >
                <img className="selectLanguage__flagIcon" src={flagLocale} alt="flag" />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
        currentLocale: { locale },
    } = state;

    return {
        loggedIn,
        locale,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        setLocale: value => getCurrentLocaleActions.getCurrentLocale(value),
        switchLocale: getSwitchLanguageAction(getService),
    },
    dispatch,
);

SelectLangeage.defaultProps = {
    translation: () => {},
    setLocale: () => {},
    switchLocale: () => {},
    locale: '',
    mobile: false,
    loggedIn: false,
};

SelectLangeage.propTypes = {
    translation: PropTypes.func,
    setLocale: PropTypes.func,
    switchLocale: PropTypes.func,
    locale: PropTypes.any,
    mobile: PropTypes.bool,
    loggedIn: PropTypes.bool,
};

export default compose(
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
)(SelectLangeage);
