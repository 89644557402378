/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import PaginationComponent from '../../../../layouts/pagination';
import closeIcon from '../../../../assets/images/orange-close.svg';
import { compose } from '../../../../../utils';
import style from './history.module.scss';

const HistoryView = ({
    date,
    changeDate,
    allGames,
    historyGames: { allCount, gameLog = [] },
    match: {
        params: { id },
    },
}) => {
    const { t } = useTranslation();

    const currentGame = allGames.find(item => +item.id === +id);

    return (
        <Fragment>
            <div className={style.history__headerContainer}>
                <p className={style.history__headerContainer__id}>{currentGame.title}</p>
                {date
                    ? (
                        <div
                            className={style.resetDate}
                            onClick={() => changeDate('')}
                        >
                            <img src={closeIcon} alt="closeIcon" />
                        </div>
                    )
                    : null}
                <DatePicker
                    selected={date}
                    onChange={changeDate}
                    dateFormat="dd/MM/yyyy"
                    className={style.history__headerContainer_datePicker}
                    placeholderText={t('general.datePlaceholder')}
                />
            </div>
            {gameLog.length ? (
                <Fragment>
                    <div className={style.table}>
                        <div className={style.table__columnWrapper}>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.date')}
                                </div>
                                {gameLog.map((item, index) => {
                                    const { date: dateRow, idGame } = item;

                                    return (
                                        <Fragment key={index + 1}>
                                            <div
                                                className={
                                                    style.table__columnWrapper_item
                                                }
                                            >
                                                <Moment format="DD.MM.YYYY" unix>
                                                    {dateRow}
                                                </Moment>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.status')}
                                </div>
                                {gameLog.map((item, index) => {
                                    const { isWin, idGame } = item;

                                    const statusStyle = isWin
                                        ? style.table__columnWrapper_itemWin
                                        : style.table__columnWrapper_itemLose;

                                    return (
                                        <Fragment key={index + 1}>
                                            <div className={statusStyle}>
                                                {isWin
                                                    ? t('general.win')
                                                    : t('general.lose')}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.bet')}
                                </div>
                                {gameLog.map((item, index) => {
                                    const { bet, ticker, idGame } = item;

                                    return (
                                        <Fragment key={index + 1}>
                                            <div
                                                className={
                                                    style.table__columnWrapper_item
                                                }
                                            >
                                                {bet.toLocaleString()} {ticker}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.amount')}
                                </div>
                                {gameLog.map((item, index) => {
                                    const { amount, ticker, idGame } = item;

                                    const amountStyle = +amount > 0
                                        ? style.table__columnWrapper_itemWin
                                        : style.table__columnWrapper_itemLose;

                                    return (
                                        <Fragment key={index + 1}>
                                            <div
                                                className={amountStyle}
                                            >
                                                {amount.toLocaleString()} {ticker}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.balance')}
                                </div>
                                {gameLog.map((item, index) => {
                                    const { balance, ticker, idGame } = item;

                                    return (
                                        <Fragment key={index + 1}>
                                            <div
                                                className={
                                                    style.table__columnWrapper_item
                                                }
                                            >
                                                {balance.toLocaleString()} {ticker}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {allCount > 10 ? (
                        <PaginationComponent totalCount={+allCount} />
                    ) : null}
                </Fragment>
            ) : (
                <p className={style.noHistory}>{t('general.noHistoryYet')}</p>
            )}
        </Fragment>
    );
};

HistoryView.defaultProps = {
    date: '',
    match: {},
    historyGames: {},
    changeDate: () => {},
    allGames: [],
};

HistoryView.propTypes = {
    date: PropTypes.any,
    match: PropTypes.object,
    historyGames: PropTypes.any,
    changeDate: PropTypes.func,
    allGames: PropTypes.instanceOf(Array),
};

export default compose(withRouter)(HistoryView);
