import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import fetchResetPasswordAction from '../../../actions/post-reset-password.actions';
import { authModalActions } from '../../../actions/authModal.actions';
import withPostService from '../../hoc/with-post-service';
import { INVALID_PASSWORD_RECOVERY_LINK } from '../../../constants';
import PostService from '../../../services/post-service';
import { compose } from '../../../utils';
import Field from '../../small-components/field';
import spinnerImg from '../../assets/images/spinner.svg';
import eye from '../personal-area/personal-area-view/profile-data-page/images/eye.svg';
import {
    oneLowercaseChar,
    oneUppercaseChar,
    oneNumber,
    oneSpecialChar,
} from '../../../helpers';
import style from './password-recovery.module.scss';

class PasswordRecovery extends Component {
    postService = new PostService();

    static defaultProps = {
        t: () => {},
        location: {},
        history: {},
        submitNewPassword: () => {},
        openLogin: () => {},
        changePasswordSuccess: false,
        loggingIn: false,
    };

    static propTypes = {
        t: PropTypes.func,
        location: PropTypes.object,
        history: PropTypes.object,
        submitNewPassword: PropTypes.func,
        openLogin: PropTypes.func,
        changePasswordSuccess: PropTypes.bool,
        loggingIn: PropTypes.bool,
    };

    state = {
        password: '',
        confirmPassword: '',
        passwordErrors: {
            passwordCharactersError: '',
            passwordLengthError: '',
            passwordDigitError: '',
            passwordLettersError: '',
        },
        confirmPasswordErrors: {
            confirmPasswordCharactersError: '',
        },
        type: 'password',
        loading: true,
    };

    componentDidMount() {
        const {
            t,
            history,
            location: { search },
            loggingIn,
        } = this.props;
        if (loggingIn) {
            history.push('/');
        } else {
            const arrayOfStrings = search.split('=');
            const data = {
                token: arrayOfStrings[1],
            };
            this.postService
                .checkResetToken(data)
                .then(() => {
                    setTimeout(() => {
                        this.setState({
                            loading: false,
                        });
                    }, 500);
                })
                .catch(error => {
                    let errorMessage = '';
                    if (error.response.data.message === INVALID_PASSWORD_RECOVERY_LINK) {
                        errorMessage = t('error.invalid_password_recovery_link');
                    } else {
                        errorMessage = error.response.data.message;
                    }
                    store.addNotification({
                        message: errorMessage,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animated', 'slideInRight'],
                        animationOut: ['animated', 'zoomOut'],
                        dismiss: {
                            duration: 3000,
                            pauseOnHover: true,
                        },
                    });
                    history.push('/');
                });
        }
    }

    componentDidUpdate(prevProps) {
        const { history, changePasswordSuccess, openLogin } = this.props;

        if (changePasswordSuccess && changePasswordSuccess !== prevProps.changePasswordSuccess) {
            history.push('/');
            openLogin();
        }
    }

    inputOnchange = async event => {
        const { name, value } = event.target;
        const { confirmPassword } = this.state;

        if (name === 'password') {
            await this.passwordValidation(name, value);
            await this.confirmPasswordValidation('confirmPassword', confirmPassword);
        }

        if (name === 'confirmPassword') {
            await this.confirmPasswordValidation(name, value);
        }
    };

    passwordValidation = (name, value) => {
        const { t } = this.props;
        this.setState(state => ({
            [name]: value.trim(),
            passwordErrors: {
                ...state.passwordErrors,
                oneLowercaseChar: oneLowercaseChar(value)
                    ? t('error.one_lowercase_char')
                    : '',
                oneUppercaseChar: oneUppercaseChar(value)
                    ? t('error.one_upperrcase_char')
                    : '',
                oneNumber: oneNumber(value) ? t('error.one_number') : '',
                oneSpecialChar: oneSpecialChar(value) ? t('error.one_special_char') : '',
                minLength: value.length < 8 ? t('error.min_length', { digit: 8 }) : '',
                passwordLengthError: '',
            },
        }));
    };

    confirmPasswordValidation = (name, value) => {
        const { t } = this.props;
        const { password } = this.state;
        const errorText = value !== password ? t('error.password_does_not_match') : '';
        this.setState({
            [name]: value.trim(),
            confirmPasswordErrors: {
                passwordDoesntMatch: errorText,
                confirmPasswordCharactersError: '',
            },
        });
    };

    showHidePassword = () => {
        const { type } = this.state;
        this.setState({
            type: type === 'password' ? 'text' : 'password',
        });
    };

    submitNewPassword = event => {
        event.preventDefault();
        const {
            password,
            passwordErrors,
            confirmPassword,
            confirmPasswordErrors,
        } = this.state;
        const { t } = this.props;

        const copyPasswordErrors = Object.assign({}, passwordErrors);
        const copyConfirmPasswordErrors = Object.assign({}, confirmPasswordErrors);

        Object.keys(copyPasswordErrors).forEach(key => {
            if (!copyPasswordErrors[key]) delete copyPasswordErrors[key];
        });
        Object.keys(copyConfirmPasswordErrors).forEach(key => {
            if (!copyConfirmPasswordErrors[key]) delete copyConfirmPasswordErrors[key];
        });

        if (!password.length) {
            this.setState(state => ({
                passwordErrors: {
                    ...state.passwordErrors,
                    passwordLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        } else if (!confirmPassword.length) {
            this.setState(state => ({
                confirmPasswordErrors: {
                    ...state.confirmPasswordErrors,
                    confirmPasswordCharactersError: t('error.field_can_not_be_empty'),
                },
            }));
        } else if (
            Object.keys(copyPasswordErrors).length === 0
            && Object.keys(copyConfirmPasswordErrors).length === 0
        ) {
            if (password && confirmPassword) {
                const { submitNewPassword, location: { search }, history } = this.props;
                const arrayOfStrings = search.split('=');

                const data = {
                    password,
                    password_repeat: confirmPassword,
                    token: arrayOfStrings[1],
                };
                submitNewPassword(data, t, history);
            }
        }
    };

    render() {
        const {
            password,
            confirmPassword,
            passwordErrors,
            confirmPasswordErrors,
            type,
            loading,
        } = this.state;
        const { t } = this.props;

        if (loading) {
            return (
                <div className={style.spinnerWrapper}>
                    <img className={style.spinner} src={spinnerImg} alt="spinner" />
                </div>
            );
        }

        return (
            <div className={style.resetPasswordContainer} id="resetPassword">
                <div>
                    <h3 className={style.resetPasswordContainer__title}>
                        {t('general.newPassword')}
                    </h3>
                    <div className={style.resetPasswordContainer__inputContainer}>
                        <div className={style.resetPasswordContainer__inputWrapper}>
                            <Field
                                id="password"
                                type={type}
                                placeholder={t('general.typeHere')}
                                name="password"
                                value={password}
                                onChange={this.inputOnchange}
                                error={passwordErrors}
                                inputStyle={style.resetPasswordContainer__input}
                                labelText={t('general.enterNewPassword')}
                                labelStyle={style.resetPasswordContainer__label}
                                inputColor="#B0AED3"
                                passwordType
                            />
                            {password.length >= 1 ? (
                                <div
                                    onClick={this.showHidePassword}
                                    className={
                                        style.resetPasswordContainer__inputWrapper_eye
                                    }
                                >
                                    <img src={eye} alt="eye" />
                                </div>
                            ) : null}
                        </div>
                        <div className={style.resetPasswordContainer__inputWrapper}>
                            <Field
                                id="confirmPassword"
                                type={type}
                                placeholder={t('general.typeHere')}
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={this.inputOnchange}
                                error={confirmPasswordErrors}
                                inputStyle={style.resetPasswordContainer__input}
                                labelText={t('general.confirmNewPassword')}
                                labelStyle={style.resetPasswordContainer__label}
                                inputColor="#B0AED3"
                                passwordType
                            />
                            {confirmPassword.length >= 1 ? (
                                <div
                                    onClick={this.showHidePassword}
                                    className={
                                        style.resetPasswordContainer__inputWrapper_eye
                                    }
                                >
                                    <img src={eye} alt="eye" />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div
                        className={style.resetPasswordContainer__saveBtn}
                        onClick={this.submitNewPassword}
                    >
                        <span className={style.buttonText}>{t('general.apply')}</span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        changePassword: { success: changePasswordSuccess },
    } = state;

    return {
        loggingIn,
        changePasswordSuccess,
    };
};

const mapDispatchToProps = (dispatch, { postService }) => bindActionCreators(
    {
        submitNewPassword: fetchResetPasswordAction(postService),
        openLogin: () => authModalActions.openLogin(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(PasswordRecovery);
