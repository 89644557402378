/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import questionMark from './images/questionMark.svg';
import attention from './images/attention.svg';

import style from './field.module.scss';

const Field = props => {
    const {
        id,
        labelText,
        type,
        placeholder,
        value,
        onChange,
        onFocus,
        onKeyDown,
        name,
        maxLength,
        error,
        inputStyle,
        labelStyle,
        min,
        max,
        disabled,
        step,
        inputColor,
        passwordType,
        isButton,
        buttonStyle,
        buttonClick,
        buttonText,
    } = props;

    const errorValues = Object.values(error).filter(item => item.length > 0);

    let questionMarkPosition = '';
    let tooltipPosition = '';
    let tooltipWidth = '';

    if (passwordType && value.length > 0) {
        questionMarkPosition = '2vw';
        tooltipPosition = '1vw';
        tooltipWidth = '24vw';
    } else {
        questionMarkPosition = '0';
        tooltipPosition = '-1vw';
        tooltipWidth = '26vw';
    }

    const questionMarkStyle = {
        marginRight: questionMarkPosition,
    };

    const tooltipPositionStyle = {
        right: tooltipPosition,
        maxWidth: tooltipWidth,
    };

    let inputInlineStyle = {};
    let borderInlineStyle = {};
    let dynamicInputStyle = {};
    let questionInvalidMarkStyle = {};

    if (errorValues.length > 0) {
        inputInlineStyle = {
            color: '#E55541',
        };
        borderInlineStyle = {
            border: '0.07vw solid #E55541',
        };
        dynamicInputStyle = style.hasError;
    } else {
        inputInlineStyle = {
            color: inputColor,
        };

        borderInlineStyle = {
            border: `0.07vw solid ${inputColor}`,
        };

        dynamicInputStyle = {};
    }

    if (labelText) {
        questionInvalidMarkStyle = style.positionQuestion;
    } else {
        questionInvalidMarkStyle = {};
    }

    return (
        <div className={style.inputWrapper}>
            {labelText
                ? (
                    <label
                        style={errorValues.length ? { color: '#E55541' } : {}}
                        className={labelStyle}
                        htmlFor={id}
                    >
                        {labelText}
                    </label>
                )
                : null}
            <div style={{ position: 'relative' }}>
                <div className={style.frame} style={borderInlineStyle}>
                    <input
                        id={id}
                        type={type}
                        className={classNames(inputStyle, dynamicInputStyle)}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        name={name}
                        maxLength={maxLength}
                        min={min}
                        max={max}
                        autoComplete="new-password"
                        disabled={disabled}
                        step={step}
                        style={inputInlineStyle}
                    />
                    {isButton
                        ? (
                            <button
                                type="button"
                                className={buttonStyle}
                                onClick={buttonClick}
                            >
                                <span className={style.buttonText}>{buttonText}</span>
                            </button>
                        )
                        : null}
                </div>
                {errorValues.length > 0 ? (
                    <div
                        className={classNames(
                            style.inputWrapper__invalid,
                            questionInvalidMarkStyle,
                        )}
                    >
                        <div
                            className={style.inputWrapper__tooltip}
                            style={tooltipPositionStyle}
                        >
                            <img
                                className={style.inputWrapper__tooltip_attention}
                                src={attention}
                                alt="attention"
                            />
                            <div>
                                <ol className={style.inputWrapper__tooltip_errorList}>
                                    {errorValues.map(item => (
                                        <li key={item}>{item}</li>
                                    ))}
                                </ol>
                            </div>
                        </div>
                        <img
                            style={questionMarkStyle}
                            className={style.questionMarkStyle}
                            src={questionMark}
                            alt="questionMark"
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

Field.defaultProps = {
    labelText: '',
    placeholder: '',
    value: '',
    name: '',
    maxLength: '',
    min: 0,
    inputStyle: '',
    labelStyle: '',
    buttonText: '',
    error: {},
    buttonStyle: '',
    step: '',
    onChange: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    buttonClick: () => {},
    disabled: false,
    inputColor: '',
    passwordType: false,
    isButton: false,
};

Field.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    placeholder: PropTypes.string,
    buttonText: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    buttonClick: PropTypes.func,
    name: PropTypes.string,
    buttonStyle: PropTypes.string,
    maxLength: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    error: PropTypes.object,
    step: PropTypes.string,
    inputStyle: PropTypes.string,
    labelStyle: PropTypes.string,
    disabled: PropTypes.bool,
    inputColor: PropTypes.string,
    passwordType: PropTypes.bool,
    isButton: PropTypes.bool,
};

export default Field;
