/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { store } from 'react-notifications-component';
import DatePicker from 'react-datepicker';
import PaginationComponent from '../../../../layouts/pagination';
import closeIcon from '../../../../assets/images/orange-close.svg';
import { compose } from '../../../../../utils';
import style from './balance-history.module.scss';

const HistoryView = ({
    date,
    changeDate,
    historyBalance: { allCount, transaction = [] },
}) => {
    const { t } = useTranslation();
    const copied = () => {
        store.addNotification({
            title: t('general.successNotification'),
            message: t('general.сopiedToClipboard'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'slideInRight'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
                duration: 3000,
                pauseOnHover: true,
            },
        });
    };

    const STATUS_NEW = 1;
    const STATUS_PENDING = 2;
    const STATUS_SUCCESS = 700;
    const STATUS_CANCEL = 800;

    return (
        <Fragment>
            <div className={style.history__headerContainer}>
                <p className={style.history__headerContainer__id}>
                    {t('general.balanceHistory')}
                </p>
                {date ? (
                    <div className={style.resetDate} onClick={() => changeDate('')}>
                        <img src={closeIcon} alt="closeIcon" />
                    </div>
                ) : null}
                <DatePicker
                    selected={date}
                    onChange={changeDate}
                    dateFormat="dd/MM/yyyy"
                    className={style.history__headerContainer_datePicker}
                    placeholderText={t('general.datePlaceholder')}
                />
            </div>
            {transaction.length ? (
                <Fragment>
                    <div className={style.table}>
                        <div className={style.table__columnWrapper}>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.date')}
                                </div>
                                {transaction.map((item, index) => {
                                    const { date: dateRow } = item;

                                    return (
                                        <Fragment key={index + 1}>
                                            <div
                                                className={
                                                    style.table__columnWrapper_item
                                                }
                                            >
                                                <Moment format="DD.MM.YYYY" unix>
                                                    {dateRow}
                                                </Moment>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.type')}
                                </div>
                                {transaction.map((item, index) => {
                                    const { method } = item;
                                    let title = '';
                                    let statusStyle = {};
                                    const METHOD_FREEZE = 1;
                                    const METHOD_UP_BALANCE = 2;
                                    const METHOD_USER_UP_BALANCE = 3;
                                    const METHOD_USER_WITHDRAW = 4;
                                    if (method === METHOD_FREEZE) {
                                        title = t('general.freeze');
                                        statusStyle = style.table__columnWrapper_itemPending;
                                    }
                                    if (method === METHOD_UP_BALANCE) {
                                        title = t('general.funding');
                                        statusStyle = style.table__columnWrapper_itemPending;
                                    }
                                    if (method === METHOD_USER_UP_BALANCE) {
                                        title = t('general.deposit');
                                        statusStyle = style.table__columnWrapper_itemBlue;
                                    }
                                    if (method === METHOD_USER_WITHDRAW) {
                                        title = t('general.withdrawal');
                                        statusStyle = style.table__columnWrapper_itemRed;
                                    }

                                    return (
                                        <Fragment key={index + 1}>
                                            <div className={statusStyle}>{title}</div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.status')}
                                </div>
                                {transaction.map((item, index) => {
                                    const { status } = item;

                                    let title = '';
                                    let statusStyle = {};

                                    if (status === STATUS_NEW) {
                                        title = t('general.new');
                                        statusStyle = style.table__columnWrapper_itemPending;
                                    }
                                    if (status === STATUS_PENDING) {
                                        title = t('general.pending');
                                        statusStyle = style.table__columnWrapper_itemPending;
                                    }
                                    if (status === STATUS_SUCCESS) {
                                        title = t('general.success');
                                        statusStyle = style.table__columnWrapper_itemBlue;
                                    }
                                    if (status === STATUS_CANCEL) {
                                        title = t('general.cancel');
                                        statusStyle = style.table__columnWrapper_itemRed;
                                    }

                                    return (
                                        <Fragment key={index + 1}>
                                            <div className={statusStyle}>{title}</div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('general.amount')}
                                </div>
                                {transaction.map((item, index) => {
                                    const { amount, ticker } = item;

                                    const amountStyle = +amount > 0
                                        ? style.table__columnWrapper_itemBlue
                                        : style.table__columnWrapper_itemRed;

                                    return (
                                        <Fragment key={index + 1}>
                                            <div className={amountStyle}>
                                                {amount} {ticker}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    {t('kyc.address')}
                                </div>
                                {transaction.map((item, index) => {
                                    const { transactionAddress } = item;

                                    return (
                                        <Fragment key={index + 1}>
                                            <CopyToClipboard
                                                text={transactionAddress}
                                                onCopy={() => copied()}
                                            >
                                                <Tooltip
                                                    placement="top"
                                                    title={transactionAddress}
                                                >
                                                    <div
                                                        className={
                                                            style.table__columnWrapper_itemCopied
                                                        }
                                                    >
                                                        {transactionAddress}
                                                    </div>
                                                </Tooltip>
                                            </CopyToClipboard>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={style.table__column}>
                                <div className={style.table__columnWrapper_itemHead}>
                                    Tx
                                </div>
                                {transaction.map((item, index) => {
                                    const {
                                        transactionHash,
                                        linkEtherscan,
                                        status,
                                    } = item;

                                    let transHash = '';
                                    let transHashStyle = '';
                                    if (status === STATUS_PENDING) {
                                        transHash = t('general.pending');
                                        transHashStyle = style.table__columnWrapper_itemPending;
                                    } else if (transactionHash) {
                                        transHash = transactionHash;
                                        transHashStyle = style.table__columnWrapper_item;
                                    } else if (!transactionHash) {
                                        transHash = '-';
                                        transHashStyle = style.table__columnWrapper_itemPending;
                                    }

                                    return (
                                        <Fragment key={index + 1}>
                                            <Tooltip
                                                placement="top"
                                                title={transactionHash}
                                            >
                                                <a
                                                    href={linkEtherscan}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={transHashStyle}
                                                >
                                                    {transHash}
                                                </a>
                                            </Tooltip>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {allCount > 10 ? (
                        <PaginationComponent totalCount={+allCount} />
                    ) : null}
                </Fragment>
            ) : (
                <p className={style.noHistory}>{t('general.noHistoryYet')}</p>
            )}
        </Fragment>
    );
};

HistoryView.defaultProps = {
    date: '',
    historyBalance: {},
    changeDate: () => {},
};

HistoryView.propTypes = {
    date: PropTypes.any,
    historyBalance: PropTypes.any,
    changeDate: PropTypes.func,
};

export default compose(withRouter)(HistoryView);
