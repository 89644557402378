import { store } from 'react-notifications-component';
import {
    POST_VERIFICATION_WITHDRAW,
    personalAreaPath,
    historyBalancePath,
} from '../constants';

const postVerificationWithhdrawRequested = () => ({
    type: POST_VERIFICATION_WITHDRAW.POST_VERIFICATION_WITHDRAW_REQUEST,
});

const postVerificationWithhdrawLoaded = data => ({
    type: POST_VERIFICATION_WITHDRAW.POST_VERIFICATION_WITHDRAW_SUCCESS,
    payload: data,
});

const postVerificationWithhdrawError = error => ({
    type: POST_VERIFICATION_WITHDRAW.POST_VERIFICATION_WITHDRAW_FAILURE,
    payload: error,
});

const fetchVerificationWithhdrawAction = postService => (token, t, history) => dispatch => {
    dispatch(postVerificationWithhdrawRequested());
    postService
        .verificationWithdraw(token)
        .then(data => {
            dispatch(postVerificationWithhdrawLoaded(data));
            history.push(`${personalAreaPath}${historyBalancePath}`);
            store.addNotification({
                message: t('general.successfulWithdrawal'),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        })
        .catch(err => {
            dispatch(postVerificationWithhdrawError(err));
            if (err.response.status === 401) {
                postService.logout();
            }
            history.push('/');
            store.addNotification({
                message: t('error.invalid_verification_link'),
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchVerificationWithhdrawAction;
