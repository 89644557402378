/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { Collapse, Icon } from 'antd';
import { connect } from 'react-redux';
import getAllFaqAction from '../../../actions/get-all-faq.actions';
import withGetService from '../../hoc/with-get-service';
import { compose } from '../../../utils';
import {
    CATEGORY_MAIN,
    CATEGORY_MANAGE_ACCOUNT,
    CATEGORY_FINANCIAL_QUESTIONS,
} from '../../../constants';
import style from './faq-page.module.scss';

class FaqPage extends Component {
    static defaultProps = {
        t: () => {},
        getAllFaq: () => {},
        locale: {},
        allFaq: [],
    };

    static propTypes = {
        t: PropTypes.func,
        getAllFaq: PropTypes.func,
        locale: PropTypes.any,
        allFaq: PropTypes.instanceOf(Array),
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { locale } = this.props;

        if (locale !== prevProps.locale) {
            this.loadData();
        }
    }

    loadData = () => {
        const { getAllFaq } = this.props;
        getAllFaq();
    };

    render() {
        const { t, allFaq } = this.props;
        const { Panel } = Collapse;

        return (
            <div className={style.faq}>
                <h1 className={style.faq__title}>FAQ</h1>
                {allFaq.map((item, idx) => {
                    const { category, data: value } = item;

                    let categoryName = '';

                    if (category === CATEGORY_MAIN) {
                        categoryName = t('faq.general');
                    } else if (category === CATEGORY_MANAGE_ACCOUNT) {
                        categoryName = t('faq.manageAccount');
                    } else if (category === CATEGORY_FINANCIAL_QUESTIONS) {
                        categoryName = t('faq.financialQuestions');
                    } else {
                        categoryName = category;
                    }

                    return (
                        <div className={style.faq__category} key={idx + 1}>
                            <p className={style.faq__category_title}>{categoryName}</p>
                            <Collapse
                                accordion
                                forceRender
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIcon={({ isActive }) => (
                                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                                )}
                                expandIconPosition="right"
                            >
                                {value.map((items, index) => {
                                    const { title, description } = items;

                                    return (
                                        <Panel header={title} key={index + 1}>
                                            <div className={style.faq__wrapper}>
                                                <p className={style.faq__answer}>
                                                    {description}
                                                </p>
                                            </div>
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        </div>
                    );
                })}
                <h4 className={style.stillHaveQuestions}>
                    {t('general.stillHaveQuestions')}
                </h4>
                <p className={style.stillHaveQuestions__subtitle}>
                    {t('general.contactUs')}{' '}
                    <a href="mailto:support@cng.casino">support@cng.casino</a>{' '}
                    {t('general.weHelp')}
                </p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        allFaq: { data: allFaq },
        currentLocale: { locale },
    } = state;

    return {
        allFaq,
        locale,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAllFaq: getAllFaqAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
)(FaqPage);
