import { store } from 'react-notifications-component';
import { DISABLE_DOCUMENT_KYC, INCORRECT_KYC } from '../constants';

const disableDocumentKycRequested = () => ({
    type: DISABLE_DOCUMENT_KYC.DISABLE_DOCUMENT_KYC_REQUEST,
});

const disableDocumentKycLoaded = data => ({
    type: DISABLE_DOCUMENT_KYC.DISABLE_DOCUMENT_KYC_SUCCESS,
    payload: data,
});

const disableDocumentKycError = error => ({
    type: DISABLE_DOCUMENT_KYC.DISABLE_DOCUMENT_KYC_FAILURE,
    payload: error,
});

const disableDocumentKycAction = getService => t => dispatch => {
    dispatch(disableDocumentKycRequested());
    getService
        .disabledDocumentKyc()
        .then(data => {
            dispatch(disableDocumentKycLoaded(data));
        })
        .catch(err => {
            dispatch(disableDocumentKycError(err));
            if (err.response.status === 401) {
                getService.logout();
            }
            let errorMessage = '';
            if (err.response.data.message === INCORRECT_KYC) {
                errorMessage = t('error.incorrect_kyc');
            } else {
                errorMessage = err.response.data.message;
            }
            store.addNotification({
                message: errorMessage,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default disableDocumentKycAction;
