/* eslint-disable */

import { GET_CURRENT_TIKER_CONSTANTS } from '../constants';

const currentTicker = ticker => ({
    type: GET_CURRENT_TIKER_CONSTANTS.CURRENT_TICKER,
    ticker,
});

export const getCurrentTikerActions = {
    currentTicker,
};
