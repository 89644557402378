import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import workPrinciplesBg from './images/workPrinciplesBg.svg';
import principleWorkIcon from './images/principleWorkIcon.svg';
import firstAchievements from './images/first-achievements.svg';
import secondAchievements from './images/second-achievements.svg';
import thirdAchievements from './images/third-achievements.svg';
import style from './achievements.module.scss';

const Achievements = () => {
    const { t } = useTranslation();
    const workPrinciplesBgStyle = {
        backgroundImage: `url(${workPrinciplesBg})`,
    };
    return (
        <Fragment>
            <div
                className={style.containerWrapper}
                style={window.innerWidth > 767 ? workPrinciplesBgStyle : null}
                id="principleOfWork"
            >
                <img
                    className={style.containerWrapper__icon}
                    src={principleWorkIcon}
                    alt="principleWorkIcon"
                />
                <div className={style.containerWrapper__wrapper}>
                    <h3 className={style.containerWrapper__title}>
                        {t('weTakingCare')}
                    </h3>
                    <p className={style.containerWrapper__subTitle}>
                        {t('simpleStepsTakePart')}
                    </p>
                    <div className={style.achievements__wrapper}>
                        <div className={style.achievements__wrapper_item}>
                            <img
                                className={style.achievements__wrapper_icon}
                                src={firstAchievements}
                                alt="icon"
                            />
                            <p className={style.achievements__wrapper_title}>
                                {t('header.registration')}
                            </p>
                            <div>
                                <p className={style.achievements__wrapper_content}>
                                    {t('achievementsContent1')}
                                </p>
                                <p className={style.achievements__wrapper_content}>
                                    {t('achievementsContent2')}
                                </p>
                            </div>
                        </div>
                        <div className={style.achievements__wrapper_item}>
                            <img
                                className={style.achievements__wrapper_icon}
                                src={secondAchievements}
                                alt="icon"
                            />
                            <p className={style.achievements__wrapper_title}>
                                {t('compliteProfile')}
                            </p>
                            <p className={style.achievements__wrapper_content}>
                                {t('achievementsContent3')}
                            </p>
                            <p className={style.achievements__wrapper_content}>
                                {t('achievementsContent4')}
                            </p>
                        </div>
                        <div className={style.achievements__wrapper_item}>
                            <img
                                className={style.achievements__wrapper_icon}
                                src={thirdAchievements}
                                alt="icon"
                            />
                            <p className={style.achievements__wrapper_title}>
                                {t('proofIdentity')}
                            </p>
                            <p className={style.achievements__wrapper_content}>
                                {t('achievementsContent6')}
                            </p>
                            <p className={style.achievements__wrapper_content}>
                                {t('achievementsContent7')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Achievements;
