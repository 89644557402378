/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { store } from 'react-notifications-component';
import { withRouter } from 'react-router-dom';
import ErrorIndicator from '../../../error-page/error-indicator';
import spinnerImg from '../../../../assets/images/spinner.svg';
import WithdrawalView from './withdrawal-view';
import { authModalActions } from '../../../../../actions';
import withGetService from '../../../../hoc/with-get-service';
import withPostService from '../../../../hoc/with-post-service';
import { compose } from '../../../../../utils';
import { personalAreaPath, documentsPath } from '../../../../../constants';
import getTransactionInfoAction from '../../../../../actions/get-transacion-info.actions';
import fetchCreateWithdrawAction from '../../../../../actions/post-create-withdraw.actions';
import style from './withdrawal.module.scss';

export class WithdrawalContainer extends Component {
    mounted = true;

    state = {
        address: '',
        amount: '',
        addressErrors: {},
        amountErrors: {},
        loading: true,
    };

    componentDidMount() {
        const { getTransactionInfo } = this.props;
        getTransactionInfo();
    }

    componentDidUpdate(prevProps) {
        const {
            t,
            history,
            loading,
            withdrawalTwoFaSuccess,
            closeModal,
            createWithdraw,
            switchTickerSuccess,
            getTransactionInfo,
        } = this.props;
        const { address, amount } = this.state;
        if (!loading && loading !== prevProps.loading && this.mounted) {
            this.setState({
                loading: false,
            });
        }

        if (
            withdrawalTwoFaSuccess
            && withdrawalTwoFaSuccess !== prevProps.withdrawalTwoFaSuccess
            && this.mounted
        ) {
            closeModal();
            const data = {
                amount,
                address,
            };
            createWithdraw(data, t, history);
        }

        if (
            switchTickerSuccess
            && switchTickerSuccess !== prevProps.switchTickerSuccess
        ) {
            getTransactionInfo();

            this.setState({
                address: '',
                amount: '',
                addressErrors: {},
                amountErrors: {},
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    inputOnchange = event => {
        const { name, value } = event.target;
        this.validateFields(name, value);
    };

    validateFields = (name, value) => {
        const {
            t,
            transactionInfo: { fee, minWithdrawal, maxWithdrawal },
        } = this.props;
        const onlyNumbers = /^[0-9.]+$/;
        const { address, amount } = this.state;

        if (address.length < 1) {
            this.setState(state => ({
                addressErrors: {
                    ...state.addressErrors,
                    addressCharactersError: t('error.field_can_not_be_empty'),
                },
            }));
        }

        if (amount.length < 1) {
            this.setState(state => ({
                amountErrors: {
                    ...state.amountErrors,
                    amountCharactersError: t('error.field_can_not_be_empty'),
                },
            }));
        }

        if (name === 'address') {
            this.setState(state => ({
                [name]: value,
                addressErrors: {
                    ...state.addressErrors,
                    addressCharactersError: '',
                },
            }));
        }

        if (name === 'amount') {
            if (value === '' || onlyNumbers.test(value)) {
                this.setState(
                    state => ({
                        [name]: value,
                        amountErrors: {
                            ...state.amountErrors,
                            amountCharactersError: '',
                        },
                    }),
                    () => {
                        if (+value > fee) {
                            this.setState(state => ({
                                amountErrors: {
                                    ...state.amountErrors,
                                    amountFeeError: '',
                                },
                            }));
                        } else {
                            this.setState(state => ({
                                amountErrors: {
                                    ...state.amountErrors,
                                    amountFeeError: t(
                                        'error.amount_less_than_withdrawal_fee',
                                    ),
                                },
                            }));
                        }

                        if (+value < minWithdrawal || +value > maxWithdrawal) {
                            this.setState(state => ({
                                amountErrors: {
                                    ...state.amountErrors,
                                    minWithdrawalError: t('error.min_max_amount', {
                                        min: minWithdrawal,
                                        max: maxWithdrawal,
                                    }),
                                },
                            }));
                        } else {
                            this.setState(state => ({
                                amountErrors: {
                                    ...state.amountErrors,
                                    minWithdrawalError: '',
                                },
                            }));
                        }
                    },
                );
            }
        }
    };

    setMaxAmount = () => {
        const {
            balance: { balance },
        } = this.props;

        this.setState(
            state => ({
                amount: balance,
                amountErrors: {
                    ...state.amountErrors,
                    amountCharactersError: '',
                },
            }),
            () => {
                const { amount } = this.state;
                this.validateFields('amount', amount);
            },
        );
    };

    submit = async () => {
        const {
            t,
            history,
            userInfo: { is_kyc },
            userInfo: { is_two_fa },
            openWithdrawTwoFa,
            createWithdraw,
        } = this.props;

        if (!is_kyc) {
            history.push(`${personalAreaPath}${documentsPath}`);
            return store.addNotification({
                message: t('error.proof_your_identify'),
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        }
        await this.validateFields();
        const {
            address, addressErrors, amount, amountErrors,
        } = this.state;

        const copyAddressErrors = { ...addressErrors };
        const copyAmountErrors = { ...amountErrors };

        Object.keys(copyAddressErrors).forEach(key => {
            if (!copyAddressErrors[key]) delete copyAddressErrors[key];
        });

        Object.keys(copyAmountErrors).forEach(key => {
            if (!copyAmountErrors[key]) delete copyAmountErrors[key];
        });

        if (
            !Object.keys(copyAddressErrors).length
            && !Object.keys(copyAmountErrors).length
        ) {
            if (is_two_fa) {
                return openWithdrawTwoFa();
            }
            const data = {
                amount,
                address,
            };
            createWithdraw(data, t, history);
        }
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const {
            loading,
            error,
            address,
            amount,
            addressErrors,
            amountErrors,
        } = this.state;
        const { transactionInfo, createWithdrawLoading } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? (
            <img className={style.spinner} src={spinnerImg} alt="spinner" />
        ) : null;
        const content = hasData ? (
            <WithdrawalView
                address={address}
                amount={amount}
                addressErrors={addressErrors}
                amountErrors={amountErrors}
                transactionInfo={transactionInfo}
                createWithdrawLoading={createWithdrawLoading}
                inputOnchange={this.inputOnchange}
                setMaxAmount={this.setMaxAmount}
                submit={this.submit}
            />
        ) : null;

        return (
            <div className={style.withdrawal} id="withdrawal">
                {errorMessage}
                {spinner}
                {content}
            </div>
        );
    }
}

WithdrawalContainer.defaultProps = {
    t: () => {},
    getTransactionInfo: () => {},
    createWithdraw: () => {},
    openWithdrawTwoFa: () => {},
    closeModal: () => {},
    transactionInfo: {},
    history: {},
    userInfo: {},
    balance: {},
    loading: true,
    createWithdrawLoading: false,
    withdrawalTwoFaSuccess: false,
    switchTickerSuccess: false,
};

WithdrawalContainer.propTypes = {
    t: PropTypes.func,
    getTransactionInfo: PropTypes.func,
    createWithdraw: PropTypes.func,
    openWithdrawTwoFa: PropTypes.func,
    closeModal: PropTypes.func,
    transactionInfo: PropTypes.object,
    history: PropTypes.object,
    userInfo: PropTypes.object,
    balance: PropTypes.object,
    loading: PropTypes.bool,
    createWithdrawLoading: PropTypes.bool,
    withdrawalTwoFaSuccess: PropTypes.bool,
    switchTickerSuccess: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        transactionInfo: { data: transactionInfo, loading },
        createWithdraw: { loading: createWithdrawLoading },
        userInfo: { data: userInfo },
        withdrawalTwoFa: { success: withdrawalTwoFaSuccess },
        switchTicker: { success: switchTickerSuccess },
        currentBalance: { balance },
    } = state;

    return {
        loggingIn,
        transactionInfo,
        loading,
        createWithdrawLoading,
        userInfo,
        withdrawalTwoFaSuccess,
        switchTickerSuccess,
        balance,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        getTransactionInfo: getTransactionInfoAction(getService),
        createWithdraw: fetchCreateWithdrawAction(postService),
        openWithdrawTwoFa: () => authModalActions.openWithdrawTwoFa(),
        closeModal: () => authModalActions.closeModal(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(WithdrawalContainer);
