import React, { PureComponent } from 'react';
import { store } from 'react-notifications-component';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../../small-components/button';
import {
    personalAreaPath,
    profileDataPath,
    USER_CONSTANTS,
    INVALID_2FA_CODE,
} from '../../../constants';
import { alertActions } from '../../../actions/alert.actions';
import { authModalActions } from '../../../actions/authModal.actions';
import { compose } from '../../../utils';
import Field from '../../small-components/field';
import ModalWindow from '../../small-components/modal-window/modal-widow';
import style from './two-fa.module.scss';
import googleAuth from './images/googleAuth.svg';

class TwoFa extends PureComponent {
    static defaultProps = {
        t: () => {},
        dispatch: () => {},
        login: false,
        signUp: false,
        passwordRecovery: false,
        twoFa: false,
        user: {},
        history: {},
    };

    static propTypes = {
        t: PropTypes.func,
        dispatch: PropTypes.func,
        login: PropTypes.bool,
        signUp: PropTypes.bool,
        passwordRecovery: PropTypes.bool,
        twoFa: PropTypes.bool,
        user: PropTypes.object,
        history: PropTypes.object,
    };

    state = {
        code: '',
        codeErrors: {
            codeLengthError: '',
        },
    };

    inputOnchange = event => {
        const { name, value } = event.target;
        const { t } = this.props;

        this.setState(state => ({
            [name]: value,
            codeErrors: {
                ...state.codeErrors,
                codeLengthError: value.length < 6 ? t('error.min_length', { digit: 6 }) : '',
            },
        }));
    };

    validateFields = () => {
        const { t } = this.props;
        const { code } = this.state;

        if (code.length < 1) {
            this.setState(state => ({
                codeErrors: {
                    ...state.codeErrors,
                    codeLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        }
    };

    submit = async event => {
        event.preventDefault();
        await this.validateFields();
        const currentLang = localStorage.getItem('i18nextLngCasion');
        const {
            t,
            history,
            dispatch,
            user: { token_two_fa: token },
        } = this.props;
        const { code, codeErrors } = this.state;

        const copyEmailErrors = { ...codeErrors };

        Object.keys(copyEmailErrors).forEach(key => {
            if (!copyEmailErrors[key]) delete copyEmailErrors[key];
        });

        const success = user => ({ type: USER_CONSTANTS.LOGIN_SUCCESS, user });

        if (Object.keys(copyEmailErrors).length === 0) {
            if (code) {
                const formData = new FormData();
                formData.append('code', code);
                formData.append('token_two_fa', token);

                const options = {
                    method: 'POST',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        language: currentLang,
                    },
                    data: formData,
                    url: `${process.env.REACT_APP_API_URL}/api/login-two-fa`,
                };

                return axios(options)
                    .then(user => {
                        this.setState({
                            code: '',
                            codeErrors: {
                                codeLengthError: '',
                            },
                        });
                        dispatch(success(user.data));
                        dispatch(alertActions.success('Authorization successful'));
                        dispatch(authModalActions.closeModal());
                        localStorage.setItem('user', JSON.stringify(user));
                        history.push(`${personalAreaPath}${profileDataPath}`);
                        store.addNotification({
                            message: t('auth.authorizationSuccessful'),
                            type: 'success',
                            insert: 'top',
                            container: 'top-right',
                            animationIn: ['animated', 'slideInRight'],
                            animationOut: ['animated', 'zoomOut'],
                            dismiss: {
                                duration: 3000,
                                pauseOnHover: true,
                            },
                        });
                    })
                    .catch(err => {
                        let errorMessage = '';
                        if (err.response.data.message === INVALID_2FA_CODE) {
                            errorMessage = t('error.invalid_code');
                        } else {
                            errorMessage = err.response.data.message;
                        }
                        store.addNotification({
                            message: errorMessage,
                            type: 'danger',
                            insert: 'top',
                            container: 'top-right',
                            animationIn: ['animated', 'slideInRight'],
                            animationOut: ['animated', 'zoomOut'],
                            dismiss: {
                                duration: 3000,
                                pauseOnHover: true,
                            },
                        });
                    });
            }
        }
    };

    openSignIn = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.openLogin());
    };

    closeModal = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.closeModal());
    };

    render() {
        const {
            t, login, signUp, passwordRecovery, twoFa,
        } = this.props;
        const { code, codeErrors } = this.state;
        const customStyles = {
            content: {
                maxWidth: '50vw',
            },
        };

        if (login || signUp || passwordRecovery || twoFa) {
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'visible';
        }

        return (
            <ModalWindow
                isOpen={twoFa}
                style={customStyles}
                onRequestClose={this.closeModal}
            >
                <h3 className={style.twoFa__title}>{t('auth.twoFactorAuth')}</h3>
                <form onSubmit={this.submit} className={style.twoFa__form} autoComplete="off">
                    <div className={style.twoFa__infoWapper}>
                        <img
                            src={googleAuth}
                            alt="googleAuth"
                            className={style.twoFa__infoWapper_img}
                        />
                        <div>
                            <p className={style.twoFa__infoWapper_title}>
                                Google Authenticator
                            </p>
                            <p className={style.twoFa__infoWapper_subTitle}>
                                {t('auth.EnterGoogleAuthenticatorCode')}
                            </p>
                        </div>
                    </div>
                    <div className={style.twoFa__inputWrapper}>
                        <Field
                            id="code"
                            type="text"
                            placeholder={t('general.typeHere')}
                            name="code"
                            value={code}
                            onChange={this.inputOnchange}
                            error={codeErrors}
                            inputStyle={style.twoFa__input}
                            inputColor="#fff"
                            labelStyle={style.twoFa__label}
                            labelText={t('auth.googleAuthenticationCode')}
                        />
                    </div>
                    <div className={style.twoFa__submitBtnWrapper}>
                        <Button
                            className={style.twoFa__submitBtn}
                            type="submit"
                        >
                            <span className={style.buttonText}>{t('general.send')}</span>
                        </Button>
                    </div>
                </form>
            </ModalWindow>
        );
    }
}

const mapStateToProps = state => {
    const {
        authModal: {
            login, signUp, passwordRecovery, twoFa,
        },
        authentication: { user },
    } = state;

    return {
        login,
        signUp,
        passwordRecovery,
        twoFa,
        user,
    };
};

export default compose(withTranslation(), connect(mapStateToProps), withRouter)(TwoFa);
