/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import { store } from 'react-notifications-component';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { bindActionCreators } from 'redux';
import ErrorIndicator from '../../../../error-page/error-indicator';
import withPostService from '../../../../../hoc/with-post-service';
import withGetService from '../../../../../hoc/with-get-service';
import getDocumentKycAction from '../../../../../../actions/get-document-kyc.actions';
import fetchSetDocumentKycAction from '../../../../../../actions/post-set-document-kyc.actions';
import spinnerImg from '../../../../../assets/images/spinner.svg';
import StepTwo from './step-two-view';
import { compose } from '../../../../../../utils';
import style from './step-two.module.scss';

const documents = {
    PASSPORT: 'PASSPORT',
    ID_CARD: 'ID_CARD',
    DRIVER_LICENSE: 'DRIVER_LICENSE',
};

const imagesName = {
    FIRST_IMAGE: 'main_image_first',
    SECOND_IMAGE: 'main_image_second',
    THIRD_IMAGE: 'main_image_third',
};

export class StepTwoContainer extends Component {
    static defaultProps = {
        t: () => {},
        setDocumentKyc: () => {},
        getDocumentKyc: () => {},
        setDocumentKycSuccess: false,
        setDocumentKycLoading: false,
    };

    static propTypes = {
        t: PropTypes.func,
        setDocumentKyc: PropTypes.func,
        getDocumentKyc: PropTypes.func,
        setDocumentKycSuccess: PropTypes.bool,
        setDocumentKycLoading: PropTypes.bool,
    };

    state = {
        selectedDocument: '',
        main_image_first: {
            base64: '',
        },
        main_image_second: {
            base64: '',
        },
        main_image_third: {
            base64: '',
        },
        loading: false,
    };

    componentDidUpdate(prevProps) {
        const { setDocumentKycSuccess, getDocumentKyc, setDocumentKycLoading } = this.props;

        if (
            setDocumentKycSuccess
            && setDocumentKycSuccess !== prevProps.setDocumentKycSuccess
        ) {
            getDocumentKyc();
        }

        if (setDocumentKycLoading && setDocumentKycLoading !== prevProps.setDocumentKycLoading) {
            setTimeout(() => {
                this.setState({
                    loading: true,
                });
            }, 500);
        }
    }

    selectDocument = selectedDocument => {
        this.setState({
            selectedDocument,
        });
    };

    goBack = () => {
        this.setState({
            selectedDocument: '',
            main_image_first: {
                base64: '',
            },
            main_image_second: {
                base64: '',
            },
            main_image_third: {
                base64: '',
            },
        });
    };

    fileUpload = (e, imageName) => {
        e.preventDefault();
        const { t } = this.props;
        const { FIRST_IMAGE, SECOND_IMAGE, THIRD_IMAGE } = imagesName;
        const file = e.target.files[0];

        if (file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt5M = file.size / 1024 / 1024 < 8;

            if (!isJpgOrPng) {
                message.error(t('general.youCanUploadPNGFile'), 5);
            }
            if (!isLt5M) {
                message.error(t('general.imageMustSmallerThan', { digit: 8 }), 5);
            }

            if (isJpgOrPng && isLt5M) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    let imageObjectName = '';
                    if (imageName === FIRST_IMAGE) {
                        imageObjectName = FIRST_IMAGE;
                    }
                    if (imageName === SECOND_IMAGE) {
                        imageObjectName = SECOND_IMAGE;
                    }
                    if (imageName === THIRD_IMAGE) {
                        imageObjectName = THIRD_IMAGE;
                    }

                    this.setState({
                        [imageObjectName]: {
                            base64: reader.result,
                        },
                    });
                };
            }
        }
    };

    nextStep = () => {
        const {
            main_image_first,
            main_image_second,
            main_image_third,
            selectedDocument,
        } = this.state;
        const { t, setDocumentKyc } = this.props;

        let data = {};

        if (
            selectedDocument === documents.PASSPORT
            && main_image_first.base64
            && main_image_second.base64
        ) {
            data = {
                main_image_first,
                main_image_second,
            };
            return setDocumentKyc(data);
        }

        if (
            selectedDocument !== documents.PASSPORT
            && main_image_first.base64
            && main_image_second.base64
            && main_image_third.base64
        ) {
            data = {
                main_image_first,
                main_image_second,
                main_image_third,
            };
            return setDocumentKyc(data);
        }

        store.addNotification({
            message: t('error.all_files_required_to_download'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'slideInRight'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
                duration: 3000,
                pauseOnHover: true,
            },
        });
    };

    render() {
        const {
            loading,
            error,
            selectedDocument,
            main_image_first,
            main_image_second,
            main_image_third,
        } = this.state;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? (
            <img className={style.spinner} src={spinnerImg} alt="spinner" />
        ) : null;
        const content = hasData ? (
            <StepTwo
                documents={documents}
                imagesName={imagesName}
                selectedDocument={selectedDocument}
                main_image_first={main_image_first}
                main_image_second={main_image_second}
                main_image_third={main_image_third}
                selectDocument={this.selectDocument}
                goBack={this.goBack}
                fileUpload={this.fileUpload}
                nextStep={this.nextStep}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        setDocumentKyc: { success: setDocumentKycSuccess, loading: setDocumentKycLoading },
    } = state;

    return {
        setDocumentKycSuccess,
        setDocumentKycLoading,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        getDocumentKyc: getDocumentKycAction(getService),
        setDocumentKyc: fetchSetDocumentKycAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
)(StepTwoContainer);
