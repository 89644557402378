import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Header from './header';
import getAllTickersAction from '../../../../actions/get-all-tickers.actions';
import getUserInfoAction from '../../../../actions/get-user-info.actions';
import getUserBalanceAction from '../../../../actions/get-user-balance.actions';
import withGetService from '../../../hoc/with-get-service';
import { compose } from '../../../../utils';

export class HeaderContainer extends Component {
    static defaultProps = {
        getUserInfo: () => {},
        getUserBalance: () => {},
        getAllTickers: () => {},
        allTickers: [],
        userInfo: {},
        location: {},
        loggingIn: false,
    };

    static propTypes = {
        getUserInfo: PropTypes.func,
        getUserBalance: PropTypes.func,
        getAllTickers: PropTypes.func,
        allTickers: PropTypes.instanceOf(Array),
        userInfo: PropTypes.object,
        location: PropTypes.object,
        loggingIn: PropTypes.bool,
    };

    componentDidMount() {
        const { getAllTickers, allTickers } = this.props;
        this.loadData();
        if (!allTickers.length) {
            getAllTickers();
        }
    }

    loadData = () => {
        const { getUserInfo, getUserBalance, loggingIn } = this.props;
        if (loggingIn) {
            getUserInfo();
            getUserBalance();
        }
    };

    logoClick = () => {
        const { location: { pathname } } = this.props;
        if (pathname === '/') {
            document.location.reload(true);
        }
    };

    render() {
        const { userInfo } = this.props;
        return (
            <Header
                userInfo={userInfo}
                logoClick={this.logoClick}
            />
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        userInfo: { data: userInfo },
        allTickers: {
            data: { tickers: allTickers },
        },
    } = state;

    return {
        loggingIn,
        userInfo,
        allTickers,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getUserInfo: getUserInfoAction(getService),
        getUserBalance: getUserBalanceAction(getService),
        getAllTickers: getAllTickersAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(HeaderContainer);
