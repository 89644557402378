import axios from 'axios';
import { history } from '../helpers/history';

export default class GetService {
    getResource = async url => {
        const user = JSON.parse(localStorage.getItem('user'));
        const currentLang = localStorage.getItem('i18nextLngCasion');
        const getToken = () => user.data.access_token;

        const response = await axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
            headers: {
                accessToken: user ? `${getToken()}` : null,
                language: currentLang,
            },
        });

        if (response.status !== 200) {
            if (response.status === 401) {
                this.logout();
            }

            throw new Error(
                `Could not fetch ${process.env.REACT_APP_API_URL}${url},
                received ${response.status}`,
            );
        }
        return response.data;
    };

    logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('updateTokenTime');
        history.push('/');
        document.location.reload(true);
    };

    getAllCountries = async () => {
        const res = await this.getResource('/api/get-all-country');
        return res;
    };

    getUserBalance = async () => {
        const res = await this.getResource('/api/get-balance-user');
        return res;
    };

    getAllTickers = async () => {
        const res = await this.getResource('/api/get-all-tickers');
        return res;
    };

    getUserInfo = async () => {
        const res = await this.getResource('/api/get-user-info');
        return res;
    };

    getAllGames = async () => {
        const res = await this.getResource('/api/get-all-game');
        return res;
    };

    getNewGames = async () => {
        const res = await this.getResource('/api/get-new-game');
        return res;
    };

    getAllFaq = async () => {
        const res = await this.getResource('/api/get-all-faq');
        return res;
    };

    switchLanguage = async () => {
        const res = await this.getResource('/api/switch-language');
        return res;
    };

    get2FaQrCode = async () => {
        const res = await this.getResource('/api/get-qr-code');
        return res;
    };

    generateSecretGoogleAuth = async () => {
        const res = await this.getResource('/api/generate-secret-google-auth');
        return res;
    };

    getHistoryGame = async data => {
        const res = await this.getResource(`/api/get-all-history-game?${data}`);
        return res;
    };

    getDocumentKyc = async () => {
        const res = await this.getResource('/api/get-document-kyc');
        return res;
    };

    disabledDocumentKyc = async () => {
        const res = await this.getResource('/api/disabled-document-kyc');
        return res;
    };

    getHistoryBalance = async data => {
        const filterParams = data ? `?${data}` : '';
        const res = await this.getResource(`/api/get-history-balance${filterParams}`);
        return res;
    };

    getTransactionInfo = async () => {
        const res = await this.getResource('/api/get-transaction-info');
        return res;
    };

    startCaptcha = async () => {
        const res = await this.getResource('/api/start-captcha-servlet');
        return res;
    };

    getActualGameLink = async id => {
        const res = await this.getResource(`/api/get-actual-game-link?id_game=${id}`);
        return res;
    };

    getVersion = async () => {
        const res = await this.getResource('/api/get-actual-version-site');
        return res;
    };
}
