import { GET_HISTORY_BALANCE } from '../constants';

const historyBalance = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case GET_HISTORY_BALANCE.GET_HISTORY_BALANCE_REQUEST:
        return {
            ...state,
            loading: true,
            error: false,
        };

    case GET_HISTORY_BALANCE.GET_HISTORY_BALANCE_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
        };

    case GET_HISTORY_BALANCE.GET_HISTORY_BALANCE_FAILURE:
        return {
            data: {},
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default historyBalance;
