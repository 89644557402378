/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { HashLink as Link } from 'react-router-hash-link';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withGetService from '../../hoc/with-get-service';
import logoutAction from '../../../actions/logout.actions';
import { compose } from '../../../utils';
import getAllGamesAction from '../../../actions/get-all-games.actions';
import profileIcon from '../../assets/images/menu-icon/profile-icon.svg';
import profileFilledIcon from '../../assets/images/menu-icon/profile-filled-icon.svg';
import balanceIcon from '../../assets/images/menu-icon/balance-icon.svg';
import balanceFilledIcon from '../../assets/images/menu-icon/balance-filled-icon.svg';
import historyIcon from '../../assets/images/menu-icon/history-icon.svg';
import historyFilledIcon from '../../assets/images/menu-icon/history-filled-icon.svg';
import documentsIcon from '../../assets/images/menu-icon/documents-icon.svg';
import documentsFilledIcon from '../../assets/images/menu-icon/documents-filled-icon.svg';
import gamesIcon from '../../assets/images/menu-icon/games-icon.svg';
import gamesFilledIcon from '../../assets/images/menu-icon/games-filled-icon.svg';
import logoutIcon from '../../assets/images/menu-icon/logout-icon.svg';
import logoutFilledIcon from '../../assets/images/menu-icon/logout-filled-icon.svg';
import {
    personalAreaPath,
    balancePath,
    historyPath,
    profileDataPath,
    documentsPath,
    depositPath,
    withdrawalPath,
    historyBalancePath,
} from '../../../constants';
import style from './navigation-menu.module.scss';
import './navigation-menu.scss';

class NavigationMenu extends Component {
    mounted = true;

    state = {
        isOpenHistorySubMenu: false,
        isOpenBalanceSubMenu: false,
    };

    componentDidMount() {
        const {
            getAllGames, allGames, location: { pathname },
        } = this.props;

        const matchPathName = pathname.split('/');
        const matchPath = `/${matchPathName[1]}/${matchPathName[2]}`;
        const balance = `${personalAreaPath}${balancePath}`;
        const history = `${personalAreaPath}${historyPath}`;

        if (matchPath === balance) {
            this.setState({
                isOpenBalanceSubMenu: true,
            });
        }

        if (matchPath === history) {
            this.setState({
                isOpenHistorySubMenu: true,
            });
        }

        if (!allGames.length) {
            getAllGames();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    openHistorySubMenu = event => {
        event.stopPropagation();
        this.setState(state => ({
            isOpenHistorySubMenu: !state.isOpenHistorySubMenu,
        }));
    };

    openBalanceSubMenu = event => {
        event.stopPropagation();
        const { isPushToBalance } = this.props;
        this.setState(
            state => ({
                isOpenBalanceSubMenu: !state.isOpenBalanceSubMenu,
            }),
            () => {
                const { isOpenBalanceSubMenu } = this.state;
                const { history } = this.props;
                if (isOpenBalanceSubMenu && isPushToBalance) {
                    history.push(`${personalAreaPath}${balancePath}`);
                }
            },
        );
    };

    mobileTab = (event, type) => {
        const { value } = event.target;
        const { history } = this.props;
        if (type === 'BALANCE') {
            return history.push(`${personalAreaPath}${value}`);
        }
        history.push(`${personalAreaPath}${historyPath}/${value}`);
    };

    logout = () => {
        const { history, logout } = this.props;
        logout(history);
    };

    render() {
        const {
            t,
            allGames,
            containerStyle,
            textStyle,
            subLinkStyle,
            openedSubmenu,
            closedSubmenu,
            asideLinkStyle,
            hideGamesTab,
        } = this.props;
        const { isOpenHistorySubMenu, isOpenBalanceSubMenu } = this.state;

        const submenuStyle = isOpenHistorySubMenu ? openedSubmenu : closedSubmenu;
        const submenuBalanceStyle = isOpenBalanceSubMenu ? openedSubmenu : closedSubmenu;

        const activeStyle = { color: '#221876' };
        return (
            <ul
                id="navigationMenu"
                className={classNames(containerStyle, style.container)}
            >
                <li>
                    <NavLink
                        to={`${personalAreaPath}${profileDataPath}`}
                        className={asideLinkStyle}
                        activeStyle={activeStyle}
                    >
                        <img className={style.icon} src={profileIcon} alt="profileIcon" />
                        <img
                            className={style.iconFilled}
                            src={profileFilledIcon}
                            alt="profileIcon"
                        />
                        <span className={textStyle}>{t('aside.profile')}</span>
                    </NavLink>
                </li>
                <li className={style.mobileSelect}>
                    <select defaultValue="defaultValue" onChange={value => this.mobileTab(value, 'BALANCE')}>
                        <option value="defaultValue" disabled>{t('general.selectOption')}</option>
                        <option value={depositPath}>{t('general.deposit')}</option>
                        <option value={withdrawalPath}>{t('general.withdrawal')}</option>
                        <option value={historyBalancePath}>{t('general.history')}</option>
                    </select>
                    <img className={style.icon} src={balanceIcon} alt="balanceIcon" />
                    <img
                        className={style.iconFilled}
                        src={balanceFilledIcon}
                        alt="balanceIcon"
                    />
                </li>
                <li className={style.hideOnMobile}>
                    <div
                        className={classNames(
                            asideLinkStyle,
                            isOpenBalanceSubMenu ? 'active' : '',
                        )}
                        onClick={this.openBalanceSubMenu}
                    >
                        <img className={style.icon} src={balanceIcon} alt="balanceIcon" />
                        <img
                            className={style.iconFilled}
                            src={balanceFilledIcon}
                            alt="balanceIcon"
                        />
                        <span className={textStyle}>{t('aside.balance')}</span>
                    </div>
                    <div id="subMenu" className={submenuBalanceStyle}>
                        <NavLink
                            to={`${personalAreaPath}${depositPath}`}
                            className={subLinkStyle}
                        >
                            {t('general.deposit')}
                        </NavLink>
                        <NavLink
                            to={`${personalAreaPath}${withdrawalPath}`}
                            className={subLinkStyle}
                        >
                            {t('general.withdrawal')}
                        </NavLink>
                        <NavLink
                            to={`${personalAreaPath}${historyBalancePath}`}
                            className={subLinkStyle}
                        >
                            {t('general.history')}
                        </NavLink>
                    </div>
                </li>
                <li className={style.mobileSelect}>
                    <select defaultValue="defaultValue" onChange={this.mobileTab}>
                        <option value="defaultValue" disabled>
                            {t('general.selectOption')}
                        </option>
                        {allGames.map(item => {
                            const { title, id } = item;

                            return (
                                <option value={id} key={id}>
                                    {title}
                                </option>
                            );
                        })}
                    </select>
                    <img className={style.icon} src={historyIcon} alt="historyIcon" />
                    <img
                        className={style.iconFilled}
                        src={historyFilledIcon}
                        alt="historyIcon"
                    />
                </li>
                <li className={style.hideOnMobile}>
                    <div
                        className={classNames(
                            asideLinkStyle,
                            isOpenHistorySubMenu ? 'active' : '',
                        )}
                        onClick={this.openHistorySubMenu}
                    >
                        <img className={style.icon} src={historyIcon} alt="historyIcon" />
                        <img
                            className={style.iconFilled}
                            src={historyFilledIcon}
                            alt="historyIcon"
                        />
                        <span className={textStyle}>{t('aside.history')}</span>
                    </div>
                    <div id="subMenu" className={submenuStyle}>
                        {allGames.map(item => {
                            const { title, id } = item;

                            return (
                                <NavLink
                                    to={`${personalAreaPath}${historyPath}/${id}`}
                                    className={subLinkStyle}
                                    key={id}
                                >
                                    {title}
                                </NavLink>
                            );
                        })}
                    </div>
                </li>
                <li>
                    <NavLink
                        to={`${personalAreaPath}${documentsPath}`}
                        className={asideLinkStyle}
                        activeStyle={activeStyle}
                    >
                        <img
                            className={style.icon}
                            src={documentsIcon}
                            alt="documentsIcon"
                        />
                        <img
                            className={style.iconFilled}
                            src={documentsFilledIcon}
                            alt="documentsIcon"
                        />
                        <span className={textStyle}>{t('aside.documents')}</span>
                    </NavLink>
                </li>
                {!hideGamesTab ? (
                    <li>
                        <Link to="/#games" className={asideLinkStyle}>
                            <img className={style.icon} src={gamesIcon} alt="gamesIcon" />
                            <img
                                className={style.iconFilled}
                                src={gamesFilledIcon}
                                alt="gamesIcon"
                            />
                            <span className={textStyle}>{t('aside.games')}</span>
                        </Link>
                    </li>
                ) : null}
                <li>
                    <div className={asideLinkStyle} onClick={this.logout}>
                        <img className={style.icon} src={logoutIcon} alt="logoutIcon" />
                        <img
                            className={style.iconFilled}
                            src={logoutFilledIcon}
                            alt="logoutIcon"
                        />
                        <span className={textStyle}>{t('aside.logout')}</span>
                    </div>
                </li>
            </ul>
        );
    }
}
NavigationMenu.defaultProps = {
    t: () => {},
    logout: () => {},
    getAllGames: () => {},
    history: {},
    location: {},
    containerStyle: '',
    textStyle: '',
    subLinkStyle: '',
    asideLinkStyle: '',
    openedSubmenu: '',
    closedSubmenu: '',
    allGames: [],
    hideGamesTab: false,
    isPushToBalance: true,
};

NavigationMenu.propTypes = {
    t: PropTypes.func,
    logout: PropTypes.func,
    getAllGames: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    containerStyle: PropTypes.string,
    textStyle: PropTypes.string,
    subLinkStyle: PropTypes.string,
    asideLinkStyle: PropTypes.string,
    openedSubmenu: PropTypes.string,
    closedSubmenu: PropTypes.string,
    allGames: PropTypes.instanceOf(Array),
    hideGamesTab: PropTypes.bool,
    isPushToBalance: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        allGames: { data: allGames },
    } = state;

    return {
        loggingIn,
        allGames,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAllGames: getAllGamesAction(getService),
        logout: history => logoutAction(history),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(NavigationMenu);
