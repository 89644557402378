import { GET_USER_INFO } from '../constants';

const getUserInfoRequested = () => ({
    type: GET_USER_INFO.GET_USER_INFO_REQUEST,
});

const getUserInfoLoaded = data => ({
    type: GET_USER_INFO.GET_USER_INFO_SUCCESS,
    payload: data,
});

const getUserInfoError = error => ({
    type: GET_USER_INFO.GET_USER_INFO_FAILURE,
    payload: error,
});

const getUserInfoAction = getService => () => dispatch => {
    dispatch(getUserInfoRequested());
    getService
        .getUserInfo()
        .then(data => {
            dispatch(getUserInfoLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getUserInfoError(err));
        });
};

export default getUserInfoAction;
