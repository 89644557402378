import { store } from 'react-notifications-component';
import { POST_CHANGE_USER_AVATAR } from '../constants';

const postChangeUserAvatarRequested = () => ({
    type: POST_CHANGE_USER_AVATAR.FETCH_CHANGE_USER_AVATAR_REQUEST,
});

const postChangeUserAvatarLoaded = data => ({
    type: POST_CHANGE_USER_AVATAR.FETCH_CHANGE_USER_AVATAR_SUCCESS,
    payload: data,
});

const postChangeUserAvatarError = error => ({
    type: POST_CHANGE_USER_AVATAR.FETCH_CHANGE_USER_AVATAR_FAILURE,
    payload: error,
});

const fetchChangeUserAvatarAction = postService => (avatar, t) => dispatch => {
    dispatch(postChangeUserAvatarRequested());
    postService
        .uploadAvatar(avatar)
        .then(data => {
            dispatch(postChangeUserAvatarLoaded(data));
            store.addNotification({
                message: t('general.photoUploadedSuccessfully'),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        })
        .catch(err => {
            dispatch(postChangeUserAvatarError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            store.addNotification({
                message: err.response.data.message,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchChangeUserAvatarAction;
