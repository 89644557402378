import { store } from 'react-notifications-component';
import {
    POST_CREATE_WITHDRAW,
    ADDRESS_IS_INVALID,
    INSUFFICIENT_FUNDS,
    personalAreaPath,
    historyBalancePath,
} from '../constants';

const postCreateWithdrawRequested = () => ({
    type: POST_CREATE_WITHDRAW.FETCH_CREATE_WITHDRAW_REQUEST,
});

const postCreateWithdrawLoaded = data => ({
    type: POST_CREATE_WITHDRAW.FETCH_CREATE_WITHDRAW_SUCCESS,
    payload: data,
});

const postCreateWithdrawError = error => ({
    type: POST_CREATE_WITHDRAW.FETCH_CREATE_WITHDRAW_FAILURE,
    payload: error,
});

const fetchCreateWithdrawAction = postService => (amount, t, history) => dispatch => {
    dispatch(postCreateWithdrawRequested());
    postService
        .createWithdraw(amount)
        .then(data => {
            dispatch(postCreateWithdrawLoaded(data));
            store.addNotification({
                message: t('applicationSuccessfullyCreated'),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 5000,
                    pauseOnHover: true,
                },
            });
            history.push(`${personalAreaPath}${historyBalancePath}`);
        })
        .catch(err => {
            dispatch(postCreateWithdrawError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            let errorMessage = '';
            if (err.response.data.message === INSUFFICIENT_FUNDS) {
                errorMessage = t('error.insufficient_funds');
            } else if (err.response.data.message.trim() === ADDRESS_IS_INVALID) {
                errorMessage = t('error.invalid_address');
            } else {
                errorMessage = err.response.data.message;
            }

            store.addNotification({
                message: errorMessage,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchCreateWithdrawAction;
