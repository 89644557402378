/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import coin from './images/coin.svg';
import style from './privacy-policy.module.scss';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className={style.privacyPolicy}>
            <h1 className={style.privacyPolicy__mainTitle}>
                {t('general.privacyPolicy')}
            </h1>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title1')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content1_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title2')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content2_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title3')}
                </h4>
                <ul className={style.privacyPolicy__list}>
                    <li>{t('privacyPolicy.content3_sub_1')}</li>
                    <li>{t('privacyPolicy.content3_sub_2')}</li>
                    <li>{t('privacyPolicy.content3_sub_3')}</li>
                    <li>{t('privacyPolicy.content3_sub_4')}</li>
                    <li>{t('privacyPolicy.content3_sub_5')}</li>
                    <li>{t('privacyPolicy.content3_sub_6')}</li>
                    <li>{t('privacyPolicy.content3_sub_7')}</li>
                    <li>{t('privacyPolicy.content3_sub_8')}</li>
                    <li>{t('privacyPolicy.content3_sub_9')}</li>
                </ul>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content3_sub_10')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title4')}
                </h4>
                <ul className={style.privacyPolicy__list}>
                    <li>{t('privacyPolicy.content4_sub_1')}</li>
                    <li>{t('privacyPolicy.content4_sub_2')}</li>
                    <li>{t('privacyPolicy.content4_sub_3')}</li>
                    <li>{t('privacyPolicy.content4_sub_4')}</li>
                    <li>{t('privacyPolicy.content4_sub_5')}</li>
                    <li>{t('privacyPolicy.content4_sub_6')}</li>
                    <li>{t('privacyPolicy.content4_sub_7')}</li>
                </ul>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title5')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content5_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title6')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content6_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title7')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content7_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title8')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content8_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title9')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content9_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title10')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content10_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title11')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content11_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title12')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content12_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title13')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content13_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title16')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content16_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title17')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content17_sub_1')}
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    <img src={coin} alt="coin" />
                    {t('privacyPolicy.title18')}
                </h4>
                <p className={style.privacyPolicy__content}>
                    {t('privacyPolicy.content18_sub_1')}{' '}
                    <a href="mailto:support@cng.casino">support@cng.casino</a>.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
