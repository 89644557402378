/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import icon2 from './images/icon2.svg';
import icon3 from './images/icon3.svg';
import icon4 from './images/icon4.svg';
import icon5 from './images/icon5.svg';
import icon6 from './images/icon6.svg';
import icon7 from './images/icon7.svg';
import icon8 from './images/icon8.svg';
import icon9 from './images/icon9.svg';
import icon10 from './images/icon10.svg';
import icon11 from './images/icon11.svg';
import icon12 from './images/icon12.svg';
import icon14 from './images/icon14.svg';
import icon15 from './images/icon15.svg';
import icon16 from './images/icon16.svg';
import icon17 from './images/icon17.svg';
import icon18 from './images/icon18.svg';
import icon19 from './images/icon19.svg';
import icon20 from './images/icon20.svg';
import icon21 from './images/icon21.svg';
import style from './term-of-service.module.scss';

const TermOfService = () => {
    const { t } = useTranslation();

    const data = [
        {
            icon: icon2,
            title: t('termOfService.title2'),
            content: t('termOfService.content2'),
        },
        {
            icon: icon3,
            title: t('termOfService.title3'),
            content: t('termOfService.content3'),
        },
        {
            icon: icon4,
            title: t('termOfService.title4'),
            content: t('termOfService.content4'),
        },
        {
            icon: icon5,
            title: t('termOfService.title5'),
            content: t('termOfService.content5'),
        },
        {
            icon: icon6,
            title: t('termOfService.title6'),
            content: t('termOfService.content6'),
        },
        {
            icon: icon7,
            title: t('termOfService.title7'),
            content: t('termOfService.content7'),
        },
        {
            icon: icon8,
            title: t('termOfService.title8'),
            content: t('termOfService.content8'),
        },
        {
            icon: icon9,
            title: t('termOfService.title9'),
            content: t('termOfService.content9'),
        },
        {
            icon: icon10,
            title: t('termOfService.title10'),
            content: t('termOfService.content10'),
        },
        {
            icon: icon11,
            title: t('termOfService.title11'),
            content: t('termOfService.content11'),
        },
        {
            icon: icon12,
            title: t('termOfService.title12'),
            content: t('termOfService.content12'),
        },
        {
            icon: icon14,
            title: t('termOfService.title14'),
            content: t('termOfService.content14'),
        },
        {
            icon: icon15,
            title: t('termOfService.title15'),
            content: t('termOfService.content15'),
        },
        {
            icon: icon16,
            title: t('termOfService.title16'),
            content: t('termOfService.content16'),
        },
        {
            icon: icon17,
            title: t('termOfService.title17'),
            content: t('termOfService.content17'),
        },
        {
            icon: icon18,
            title: t('termOfService.title18'),
            content: t('termOfService.content18'),
        },
        {
            icon: icon19,
            title: t('termOfService.title19'),
            content: t('termOfService.content19'),
        },
        {
            icon: icon20,
            title: t('termOfService.title20'),
            content: t('termOfService.content20'),
        },
        {
            icon: icon21,
            title: t('termOfService.title21'),
            content: t('termOfService.content21'),
        },
    ];

    return (
        <div className={style.termOfService}>
            <h1 className={style.termOfService__mainTitle}>{t('general.termOfService')}</h1>
            <div className={style.termOfService__wrapper}>
                <p className={style.termOfService__content}>{t('termOfService.content1')}</p>
            </div>
            {data.map(item => {
                const { icon, title, content } = item;

                return (
                    <div key={title} className={style.termOfService__wrapper}>
                        <h4 className={style.termOfService__title}>
                            <img src={icon} alt="icon" /> {title}
                        </h4>
                        <p className={style.termOfService__content}>{content}</p>
                    </div>
                );
            })}
            <div className={style.termOfService__wrapper}>
                <h4 className={style.termOfService__title}>{t('termOfUse.contactUs')}</h4>
                <p className={style.termOfService__content}>
                    {t('termOfUse.contactUsTitle')}
                </p>
            </div>
        </div>
    );
};

export default TermOfService;
