import { store } from 'react-notifications-component';
import { POST_ENABLE_TWO_FA, INVALID_2FA_CODE } from '../constants';

const postEnabledTwoFaRequested = () => ({
    type: POST_ENABLE_TWO_FA.FETCH_ENABLE_TWO_FA_REQUEST,
});

const postEnabledTwoFaLoaded = data => ({
    type: POST_ENABLE_TWO_FA.FETCH_ENABLE_TWO_FA_SUCCESS,
    payload: data,
});

const postEnabledTwoFaError = error => ({
    type: POST_ENABLE_TWO_FA.FETCH_ENABLE_TWO_FA_FAILURE,
    payload: error,
});

const fetchEnabledTwoFaAction = postService => (code, t) => dispatch => {
    dispatch(postEnabledTwoFaRequested());
    postService
        .enabledTwoFa(code)
        .then(data => {
            dispatch(postEnabledTwoFaLoaded(data));
            store.addNotification({
                message: t('general.twoFaEnabled'),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        })
        .catch(err => {
            dispatch(postEnabledTwoFaError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            let errorMessage = '';
            if (err.response.data.message === INVALID_2FA_CODE) {
                errorMessage = t('error.invalid_code');
            } else {
                errorMessage = err.response.data.message;
            }
            store.addNotification({
                message: errorMessage,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchEnabledTwoFaAction;
