import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FirstScreen from './first-screen';
import AllGames from './all-games';
import Advantages from './advantages';
import WorkPrinciples from './work-principles';
import SmartContract from './smart-contract';
import Achievements from './achievements';
import Bonus from './bonus';

const HomePage = ({ loading }) => (
    <Fragment>
        <FirstScreen loading={loading} />
        <AllGames />
        <Achievements />
        <Advantages />
        <WorkPrinciples />
        <SmartContract />
        <Bonus />
    </Fragment>
);

HomePage.defaultProps = {
    loading: true,
};

HomePage.propTypes = {
    loading: PropTypes.bool,
};

export default HomePage;
