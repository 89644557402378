import { GET_NEW_GAMES } from '../constants';

const newGames = (state, action) => {
    if (state === undefined) {
        return {
            data: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case GET_NEW_GAMES.GET_NEW_GAMES_REQUEST:
        return {
            data: [],
            loading: true,
            error: false,
        };

    case GET_NEW_GAMES.GET_NEW_GAMES_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
        };

    case GET_NEW_GAMES.GET_NEW_GAMES_FAILURE:
        return {
            data: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default newGames;
