import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import withGetService from '../../../../hoc/with-get-service';
import ErrorIndicator from '../../../error-page/error-indicator';
import HistoryCriptoView from './balance-history-view';
import { compose } from '../../../../../utils';
import { FilterActions } from '../../../../../actions/filter.actions';
import getHistoryBalanceAction from '../../../../../actions/get-history-balance.actions';
import spinnerImg from '../../../../assets/images/spinner.svg';
import 'react-datepicker/dist/react-datepicker.css';
import style from './balance-history.module.scss';

export class HistoryContainer extends Component {
    static defaultProps = {
        filterParams: {},
        getHistoryBalance: () => {},
        resetCurrentPage: () => {},
        loading: true,
        historyBalance: [],
    };

    static propTypes = {
        filterParams: PropTypes.object,
        getHistoryBalance: PropTypes.func,
        resetCurrentPage: PropTypes.func,
        loading: PropTypes.bool,
        historyBalance: PropTypes.any,
    };

    state = {
        date: '',
        timeStamp: '',
        loading: true,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const {
            filterParams: { currentPageNumber },
        } = this.props;

        if (currentPageNumber !== prevProps.filterParams.currentPageNumber) {
            this.loadData();
        }

        const { loading } = this.props;
        if (!loading && loading !== prevProps.loading) {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 500);
        }
    }

    changeDate = date => {
        const { resetCurrentPage, filterParams } = this.props;
        const currentFilterParams = {
            ...filterParams,
            currentPageNumber: 1,
        };
        const timeStamp = date ? Math.round(date.getTime() / 1000) : '';
        this.setState(
            {
                date,
                timeStamp,
            },
            () => {
                this.loadData();
                resetCurrentPage(currentFilterParams);
            },
        );
    };

    loadData = () => {
        const { timeStamp } = this.state;
        const {
            filterParams: { currentPageNumber },
            getHistoryBalance,
        } = this.props;
        this.setState({
            loading: true,
        });

        const page = currentPageNumber ? `currentPage=${currentPageNumber}&` : '';
        const time = timeStamp ? `date=${timeStamp}&` : '';
        const data = `${page}${time}`;

        getHistoryBalance(data);
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const { loading, error, date } = this.state;
        const { historyBalance } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? (
            <img className={style.spinner} src={spinnerImg} alt="spinner" />
        ) : null;
        const content = hasData ? (
            <HistoryCriptoView
                date={date}
                historyBalance={historyBalance}
                changeDate={this.changeDate}
            />
        ) : null;

        return (
            <div className={style.history}>
                {errorMessage}
                {spinner}
                {content}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        filterParams: { data: filterParams },
        historyBalance: { data: historyBalance, loading },
    } = state;

    return {
        filterParams,
        historyBalance,
        loading,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getHistoryBalance: getHistoryBalanceAction(getService),
        resetCurrentPage: currentFilterParams => FilterActions.selectFilter(currentFilterParams),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(HistoryContainer);
