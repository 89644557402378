import { GET_DOCUMENT_KYC } from '../constants';

const documentKyc = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case GET_DOCUMENT_KYC.GET_DOCUMENT_KYC_REQUEST:
        return {
            data: {},
            loading: true,
            error: false,
        };

    case GET_DOCUMENT_KYC.GET_DOCUMENT_KYC_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
        };

    case GET_DOCUMENT_KYC.GET_DOCUMENT_KYC_FAILURE:
        return {
            data: {},
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default documentKyc;
