const getCurrentLocale = () => {
    const currentLang = localStorage.getItem('i18nextLngCasion');
    let locale = currentLang;

    if (currentLang === 'US') {
        locale = 'en';
    } else {
        locale = currentLang.toLowerCase();
    }

    return locale;
};

export default getCurrentLocale;
