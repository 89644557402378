/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import getAllGamesAction from '../../../../actions/get-all-games.actions';
import { authModalActions } from '../../../../actions/authModal.actions';
import withGetService from '../../../hoc/with-get-service';
import { compose } from '../../../../utils';
import {
    personalAreaPath,
    documentsPath,
    gamePath,
    MONEY_GAME,
    DEMO_GAME,
} from '../../../../constants';
import Button from '../../../small-components/button';
import spinnerImg from '../../../assets/images/spinner.svg';
import blueCoin from '../../../assets/images/blueCoin.svg';
import style from './all-games.module.scss';

class AllGames extends Component {
    static defaultProps = {
        t: () => {},
        getAllGames: () => {},
        openLogin: () => {},
        allGames: [],
        loggingIn: false,
        loading: false,
        success: false,
        userInfo: {},
        history: {},
    };

    static propTypes = {
        t: PropTypes.func,
        getAllGames: PropTypes.func,
        openLogin: PropTypes.func,
        allGames: PropTypes.instanceOf(Array),
        loading: PropTypes.bool,
        success: PropTypes.bool,
        loggingIn: PropTypes.bool,
        userInfo: PropTypes.object,
        history: PropTypes.object,
    };

    componentDidMount() {
        const { getAllGames } = this.props;
        getAllGames();
    }

    openMoneyGame = (id, type) => {
        const {
            t,
            history,
            loggingIn,
            openLogin,
            userInfo: { is_kyc },
        } = this.props;

        if (!loggingIn) {
            return openLogin();
        }
        if (!is_kyc) {
            history.push(`${personalAreaPath}${documentsPath}`);
            return store.addNotification({
                message: t('error.proof_your_identify'),
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        }

        history.push(`${gamePath}/${id}/${type}`);
    };

    openDemoGame = (id, type) => {
        const { history } = this.props;
        history.push(`${gamePath}/${id}/${type}`);
    };

    render() {
        const {
            t, allGames, loading, success,
        } = this.props;

        return (
            <div className={style.allGames} id="games">
                <p className={style.allGames__title}>{t('general.games')}</p>
                {loading ? (
                    <div className={style.allGames} id="games">
                        <img className={style.spinner} src={spinnerImg} alt="spinner" />
                    </div>
                ) : null}
                <CSSTransition in={success} timeout={3000} classNames="CSSTransition">
                    <div className={style.allGames__wrapper}>
                        {allGames.map(item => {
                            const {
                                id, title, image, link_demo,
                            } = item;

                            return (
                                <div key={id} className={style.allGames__wrapper_item}>
                                    <img
                                        className={style.allGames__wrapper_img}
                                        src={`${process.env.REACT_APP_ALL_IMAGES_URL}${image}`}
                                        alt="img"
                                    />
                                    <p className={style.allGames__wrapper_title}>
                                        {title}
                                    </p>
                                    <div className={style.allGames__wrapper_mask} />
                                    <Button
                                        className={style.allGames__wrapper_buttonMoney}
                                        type="button"
                                        onClick={() => this.openMoneyGame(id, MONEY_GAME)}
                                    >
                                        <span className={style.buttonText}>
                                            {t('allGames.play')}
                                        </span>
                                    </Button>
                                    {link_demo ? (
                                        <Button
                                            className={style.allGames__wrapper_buttonDemo}
                                            type="button"
                                            onClick={() => this.openDemoGame(id, DEMO_GAME)
                                            }
                                        >
                                            <span className={style.buttonText}>DEMO</span>
                                        </Button>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                </CSSTransition>
                <img className={style.allGames_coin} src={blueCoin} alt="blueCoin" />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        allGames: { data: allGames, loading, success },
        userInfo: { data: userInfo },
    } = state;

    return {
        loggingIn,
        allGames,
        userInfo,
        loading,
        success,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAllGames: getAllGamesAction(getService),
        openLogin: () => authModalActions.openLogin(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(AllGames);
