import React from 'react';
import PropTypes from 'prop-types';
import StepOne from './step-one';
import StepTwo from './step-two';
import StepThree from './step-three';
import { STEP_KYC_1, STEP_KYC_2, STEP_KYC_3 } from '../../../../../constants';
import './documents.scss';

const DocumentsView = ({ documentKyc: { stepKyc } }) => {
    if (stepKyc === STEP_KYC_1) { return <StepOne />; }

    if (stepKyc === STEP_KYC_2) { return <StepTwo />; }

    if (stepKyc === STEP_KYC_3) { return <StepThree />; }

    return (
        <StepOne />
    );
};

DocumentsView.defaultProps = {
    documentKyc: {},
};

DocumentsView.propTypes = {
    documentKyc: PropTypes.object,
};

export default DocumentsView;
