import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import ErrorIndicator from '../../../../error-page/error-indicator';
import withGetService from '../../../../../hoc/with-get-service';
import withPostService from '../../../../../hoc/with-post-service';
import getAllCountriesAction from '../../../../../../actions/get-all-countries.actions';
import getDocumentKycAction from '../../../../../../actions/get-document-kyc.actions';
import fetchSetDocumentKycAction from '../../../../../../actions/post-set-document-kyc.actions';
import Spinner from '../../../../../spinner';
import StepOne from './step-one-view';
import { compose } from '../../../../../../utils';

export class StepOneContainer extends Component {
    static defaultProps = {
        t: () => {},
        getAllCountries: () => {},
        setDocumentKyc: () => {},
        getDocumentKyc: () => {},
        allCountries: [],
        setDocumentKycSuccess: false,
    };

    static propTypes = {
        t: PropTypes.func,
        getAllCountries: PropTypes.func,
        setDocumentKyc: PropTypes.func,
        getDocumentKyc: PropTypes.func,
        allCountries: PropTypes.instanceOf(Array),
        setDocumentKycSuccess: PropTypes.bool,
    };

    state = {
        firstStep: {
            address: '',
            birthday: '',
            city: '',
            firstName: '',
            gender: '',
            lastName: '',
            zipCode: '',
            country: '',
        },
        errors: {
            birthdayError: {},
            genderError: {},
            countryError: {},
        },
    };

    componentDidMount() {
        const { getAllCountries } = this.props;
        getAllCountries();
    }

    componentDidUpdate(prevProps) {
        const { setDocumentKycSuccess, getDocumentKyc } = this.props;

        if (
            setDocumentKycSuccess
            && setDocumentKycSuccess !== prevProps.setDocumentKycSuccess
        ) {
            getDocumentKyc();
        }
    }

    inputOnchange = event => {
        const { name, value } = event.target;
        const { t } = this.props;

        const lengthError = value.length > 1 ? '' : t('error.min_length', { digit: 2 });
        const symbolsAndNumbers = /[0-9!@#,./$%^&*/':+{}=();"`/\-/\]/\\()/[_]+$/;
        const onlyLetters = ['city', 'firstName', 'lastName'];
        if (value.length > 50) {
            return;
        }
        if (onlyLetters.includes(name)) {
            if (value === '' || !symbolsAndNumbers.test(value)) {
                this.setState(state => ({
                    firstStep: {
                        ...state.firstStep,
                        [name]: value,
                    },
                    errors: {
                        ...state.errors,
                        [`${name}Error`]: {
                            ...state.errors[`${name}Error`],
                            lengthError,
                        },
                    },
                }));
            }
            return;
        }

        this.setState(state => ({
            firstStep: {
                ...state.firstStep,
                [name]: value,
            },
            errors: {
                ...state.errors,
                [`${name}Error`]: {
                    ...state.errors[`${name}Error`],
                    lengthError,
                },
            },
        }));
    };

    birthdayInputOnchange = event => {
        const { name, value } = event.target;
        const { t } = this.props;

        // Validate Birth day
        const lengthError = !value.match(
            /^(?:0[1-9]|[12]\d|3[01])([/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/,
        )
            ? t('error.min_length', { digit: 1 })
            : '';

        this.setState(state => ({
            firstStep: {
                ...state.firstStep,
                [name]: value,
            },
            errors: {
                ...state.errors,
                [`${name}Error`]: {
                    ...state.errors[`${name}Error`],
                    lengthError,
                },
            },
        }));
    };

    radioOnChange = e => {
        this.setState(state => ({
            firstStep: {
                ...state.firstStep,
                gender: e.target.value,
            },
            errors: {
                ...state.errors,
                genderError: {},
            },
        }));
    };

    validateFields = () => {
        const { t } = this.props;
        const { firstStep } = this.state;

        const arr = [firstStep];

        arr.map(item => Object.keys(item).forEach(key => {
            if (item[key] < 1) {
                this.setState(state => ({
                    errors: {
                        ...state.errors,
                        [`${key}Error`]: {
                            lengthError: t('error.field_can_not_be_empty'),
                        },
                    },
                }));
            }
        }));
    };

    location = value => {
        this.setState(state => ({
            firstStep: {
                ...state.firstStep,
                country: value,
            },
            errors: {
                ...state.errors,
                countryError: {},
            },
        }));
    };

    nextButton = async () => {
        await this.validateFields();
        const {
            errors,
            firstStep: {
                address,
                birthday,
                city,
                firstName,
                gender,
                lastName,
                zipCode,
                country,
            },
        } = this.state;
        const { setDocumentKyc } = this.props;
        const copyState = Object.assign({}, errors);

        // Combine errors to array
        const combineErrorsToArray = Object.entries(copyState).map(a => (Object.entries(a[1]).filter(b => b[1].length).length
            ? a
            : delete copyState[a[0]]));

        // Checking fields for errors
        const checkFieldsErrors = combineErrorsToArray.every(item => item === true);

        if (checkFieldsErrors) {
            const data = {
                first_name: firstName,
                last_name: lastName,
                country,
                city,
                address,
                zip_code: zipCode,
                gender,
                birth_date_str: birthday,
            };
            setDocumentKyc(data);
        }
    };

    render() {
        const {
            loading, error, firstStep, errors,
        } = this.state;
        const { allCountries } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <StepOne
                allCountries={allCountries}
                firstStep={firstStep}
                errors={errors}
                inputOnchange={this.inputOnchange}
                birthdayInputOnchange={this.birthdayInputOnchange}
                radioOnChange={this.radioOnChange}
                nextButton={this.nextButton}
                location={this.location}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        allCountries: { data: allCountries },
        setDocumentKyc: { success: setDocumentKycSuccess },
    } = state;

    return {
        loggingIn,
        allCountries,
        setDocumentKycSuccess,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        getAllCountries: getAllCountriesAction(getService),
        getDocumentKyc: getDocumentKycAction(getService),
        setDocumentKyc: fetchSetDocumentKycAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
)(StepOneContainer);
