import { store } from 'react-notifications-component';
import { POST_SWITCH_TICKER, gamePath } from '../constants';

const postSwitchTickerRequested = () => ({
    type: POST_SWITCH_TICKER.FETCH_SWITCH_TICKER_REQUEST,
});

const postSwitchTickerLoaded = data => ({
    type: POST_SWITCH_TICKER.FETCH_SWITCH_TICKER_SUCCESS,
    payload: data,
});

const postSwitchTickerError = error => ({
    type: POST_SWITCH_TICKER.FETCH_SWITCH_TICKER_FAILURE,
    payload: error,
});

const fetchSwitchTickerAction = postService => (id, t) => dispatch => {
    dispatch(postSwitchTickerRequested());
    postService
        .switchTicker(id)
        .then(data => {
            dispatch(postSwitchTickerLoaded(data));
            const pathName = window.location.pathname;
            const matchPathName = pathName.split('/')[1];
            if (`/${matchPathName}` !== gamePath) {
                store.addNotification({
                    message: t('coinChangedSuccessfully'),
                    type: 'success',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'slideInRight'],
                    animationOut: ['animated', 'zoomOut'],
                    dismiss: {
                        duration: 3000,
                        pauseOnHover: true,
                    },
                });
            }
        })
        .catch(err => {
            dispatch(postSwitchTickerError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            store.addNotification({
                message: err.response.data.message,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchSwitchTickerAction;
