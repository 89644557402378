/* eslint-disable camelcase */
import axios from 'axios';

const currentLang = localStorage.getItem('i18nextLngCasion');

const logout = () => {
    localStorage.removeItem('user');
};
const userJson = JSON.parse(localStorage.getItem('user'));

if (localStorage.getItem('user') === 'undefined') {
    logout();
} else if (userJson && !userJson.data) {
    logout();
}

const login = ({
    email,
    geetest_challenge,
    geetest_seccode,
    geetest_validate,
    userPasswordLogin,
    gtserver,
    gtuser_id,
}) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('geetest_challenge', geetest_challenge);
    formData.append('geetest_seccode', geetest_seccode);
    formData.append('geetest_validate', geetest_validate);
    formData.append('password', userPasswordLogin);
    formData.append('gtserver', gtserver);
    formData.append('gtuser_id', gtuser_id);
    const options = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            language: currentLang,
        },
        data: formData,
        url: `${process.env.REACT_APP_API_URL}/api/login`,
    };

    return axios(options).then(user => user);
};

const registration = user => {
    const formData = new FormData();
    const {
        country,
        email,
        password,
        geetest_challenge,
        geetest_seccode,
        geetest_validate,
        gtserver,
        gtuser_id,
    } = user;
    formData.append('country', country);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('geetest_challenge', geetest_challenge);
    formData.append('geetest_seccode', geetest_seccode);
    formData.append('geetest_validate', geetest_validate);
    formData.append('gtserver', gtserver);
    formData.append('gtuser_id', gtuser_id);

    const options = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            language: currentLang,
        },
        data: formData,
        url: `${process.env.REACT_APP_API_URL}/api/registration`,
    };

    return axios(options).then(data => data);
};

export { login, registration, logout };
