import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { compose } from '../../../../../utils';
import background from './images/background.svg';
import style from './balance.module.scss';
import './balance.scss';
import {
    personalAreaPath,
    depositPath,
    withdrawalPath,
} from '../../../../../constants/pathLocation';

const BalanceView = ({ allTickersLoading, balance: { name: ticker, balance } }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className={style.balance__container}>
                <CSSTransition
                    in={!allTickersLoading}
                    timeout={1000}
                    classNames="CSSTransition"
                >
                    <div className={style.balance__wrapper}>
                        <p className={style.balance__wrapper_title}>
                            {t('general.yourCurrentBalance')}
                        </p>
                        <p className={style.balance__wrapper_amount}>
                            {balance} {ticker}
                        </p>
                    </div>
                </CSSTransition>
                <div className={style.balance__buttonWrapper}>
                    <Link
                        to={`${personalAreaPath}${depositPath}`}
                        className={style.balance__buttonWrapper_deposit}
                    >
                        <span className={style.buttonText}>
                            {t('general.topUpMyBalance')}
                        </span>
                    </Link>
                    <Link
                        to={`${personalAreaPath}${withdrawalPath}`}
                        className={style.balance__buttonWrapper_withdrawal}
                    >
                        <span className={style.buttonText}>
                            {t('general.iWantToWithdraw')}
                        </span>
                    </Link>
                </div>
            </div>
            <img
                className={style.balance__backgroundImage}
                src={background}
                alt="background"
            />
        </Fragment>
    );
};

BalanceView.defaultProps = {
    balance: {},
    allTickersLoading: false,
};

BalanceView.propTypes = {
    balance: PropTypes.object,
    allTickersLoading: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        currentBalance: { balance },
    } = state;

    return {
        balance,
    };
};

export default compose(connect(mapStateToProps))(BalanceView);
