import { GET_ALL_GAMES } from '../constants';

const getAllGamesRequested = () => ({
    type: GET_ALL_GAMES.GET_ALL_GAMES_REQUEST,
});

const getAllGamesLoaded = data => ({
    type: GET_ALL_GAMES.GET_ALL_GAMES_SUCCESS,
    payload: data,
});

const getAllGamesError = error => ({
    type: GET_ALL_GAMES.GET_ALL_GAMES_FAILURE,
    payload: error,
});

const getAllGamesAction = getService => () => dispatch => {
    dispatch(getAllGamesRequested());
    getService
        .getAllGames()
        .then(data => {
            dispatch(getAllGamesLoaded(data));
        })
        .catch(err => {
            dispatch(getAllGamesError(err));
        });
};

export default getAllGamesAction;
