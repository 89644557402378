import { GET_HISTORY_GAME } from '../constants';

const getHistoryGameRequested = () => ({
    type: GET_HISTORY_GAME.GET_HISTORY_GAME_REQUEST,
});

const getHistoryGameLoaded = data => ({
    type: GET_HISTORY_GAME.GET_HISTORY_GAME_SUCCESS,
    payload: data,
});

const getHistoryGameError = error => ({
    type: GET_HISTORY_GAME.GET_HISTORY_GAME_FAILURE,
    payload: error,
});

const getHistoryGameAction = getService => parametrs => dispatch => {
    dispatch(getHistoryGameRequested());
    getService
        .getHistoryGame(parametrs)
        .then(data => {
            dispatch(getHistoryGameLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getHistoryGameError(err));
        });
};

export default getHistoryGameAction;
