
import { combineReducers } from 'redux';
import { authModal } from './authModal.reducer';
import { alert } from './alert.reducer';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import refreshToken from './jwt.reducer';
import allCountries from './get-all-countries.reducer';
import userBalance from './get-user-balance.reducer';
import allTickers from './get-all-tickers.reducer';
import changePassword from './post-change-password.reducer';
import passwordRecovery from './post-password-recovery.reducer';
import changeUserData from './post-change-user-data.reducer';
import userInfo from './get-user-info.reducer';
import changeUserAvatar from './post-change-user-avatar.reducer';
import resetPassword from './post-reset-password.reducer';
import removeAvatar from './post-remove-avatar.reducer';
import allGames from './get-all-games.reducer';
import newGames from './get-new-games.reducer';
import allFaq from './get-all-faq.reducer';
import { currentLocale } from './current-locale.reducer';
import switchLang from './get-switch-language.reducer';
import generateSecretGoogleAuth from './get-generate-secret-google-auth.reducer';
import getTwoFaQrCode from './get-twoFa-qrcode.reducer';
import enabledTwoFa from './post-enabled-two-fa.reducer';
import disabledTwoFa from './post-disabled-two-fa.reducer';
import { filterParams } from './filter.reducer';
import historyGames from './get-history-games.reducer';
import documentKyc from './get-document-kyc.reducer';
import setDocumentKyc from './post-set-document-kyc.reducer';
import disableDocumentKyc from './get-disable-document-kyc.reducer';
import historyBalance from './get-history-balance.reducer';
import transactionInfo from './get-transaction-info.reducer';
import createInvoice from './post-create-invoice.reducer';
import createWithdraw from './post-create-withdraw.reducer';
import verificationUser from './post-verification-user.reducer';
import withdrawalTwoFa from './post-withdrawal-two-fa.reducer';
import verificationWithdraw from './post-verification-withdraw.reducer';
import actualGameLink from './get-actual-game-link.reducer';
import version from './get-version.reducer';
import switchTicker from './post-switch-ticker.reducer';
import { currentTicker } from './current-ticker.reducer';
import { currentBalance } from './current-balance.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    authModal,
    refreshToken,
    allCountries,
    userBalance,
    allTickers,
    changePassword,
    passwordRecovery,
    changeUserData,
    userInfo,
    changeUserAvatar,
    resetPassword,
    removeAvatar,
    allGames,
    newGames,
    allFaq,
    currentLocale,
    switchLang,
    generateSecretGoogleAuth,
    getTwoFaQrCode,
    enabledTwoFa,
    disabledTwoFa,
    filterParams,
    historyGames,
    documentKyc,
    setDocumentKyc,
    disableDocumentKyc,
    historyBalance,
    transactionInfo,
    createInvoice,
    createWithdraw,
    verificationUser,
    withdrawalTwoFa,
    verificationWithdraw,
    actualGameLink,
    version,
    switchTicker,
    currentTicker,
    currentBalance,
});

export default rootReducer;
