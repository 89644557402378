/* eslint-disable camelcase */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../../utils';
import ScrollToTop from '../../helpers/scroll-to-top';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import PasswordRecovery from '../auth/password-recovery';
import { currentBalanceActions } from '../../actions/current-balance.actions';
import Login from '../auth/login';
import Registration from '../auth/registration';
import TwoFa from '../auth/two-fa';
import GetService from '../../services/get-service';
import {
    HomePage,
    PageNotFound,
    LoadingPage,
    TermOfUse,
    TermOfService,
    PrivacyPolicy,
    PersonalArea,
    PasswordRecoveryPage,
    FaqPage,
    VerificationUser,
    GamePage,
    VerificationWithdraw,
} from '../pages';
import {
    termOfUsePath,
    termOfServicePath,
    privacyPolicyPath,
    personalAreaPath,
    passwordRecoveryPath,
    faqPath,
    verificationUserPath,
    gamePath,
    verificationWithdrawPath,
} from '../../constants';
import '../assets/styles/reset.scss';
import '../assets/styles/search-select.scss';
import './app.scss';
import '../assets/styles/fonts.scss';
import 'react-notifications-component/dist/theme.css';

class App extends Component {
    getService = new GetService();

    interval = null;

    static defaultProps = {
        dispatch: () => {},
        userBalance: [],
        userBalanceSuccess: false,
        allTickersSuccess: false,
        id_ticker_currency: '',
    };

    static propTypes = {
        dispatch: PropTypes.func,
        userBalance: PropTypes.instanceOf(Array),
        userBalanceSuccess: PropTypes.bool,
        allTickersSuccess: PropTypes.bool,
        id_ticker_currency: PropTypes.any,
    };

    state = {
        loading: true,
        focus: null,
    };

    componentDidMount() {
        this.setState({
            focus: true,
        });
        window.addEventListener('focus', this.setFocus);
        window.addEventListener('blur', this.clearFocus);
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 2000);
    }

    componentDidUpdate(prevProps, prevState) {
        const { focus } = this.state;
        const {
            dispatch,
            userBalance,
            id_ticker_currency,
            userBalanceSuccess,
            allTickersSuccess,
        } = this.props;

        const isData = userBalanceSuccess && allTickersSuccess;
        const isDataUpdate = userBalanceSuccess !== prevProps.userBalanceSuccess
            || allTickersSuccess !== prevProps.allTickersSuccess;

        if (focus !== prevState.focus) {
            if (focus) {
                this.checkVersion();
                this.interval = setInterval(this.checkVersion, 60000);
                return;
            }
            this.clearCheckVersion();
        }

        if (isData && isDataUpdate) {
            const balanceAndTicker = userBalance.find(
                item => item.id === id_ticker_currency,
            );
            dispatch(currentBalanceActions.currentBalance(balanceAndTicker));
        }
    }

    componentWillUnmount() {
        this.clearCheckVersion();
    }

    checkVersion = () => {
        this.getService.getVersion().then(version => {
            const currentVersion = localStorage.getItem('cng_casino_version');

            if (localStorage.getItem('cng_casino_version') === null) {
                return localStorage.setItem('cng_casino_version', version);
            }

            if (currentVersion !== version) {
                localStorage.setItem('cng_casino_version', version);
                document.location.reload(true);
            }
        });
    };

    setFocus = () => {
        this.setState({
            focus: true,
        });
    };

    clearFocus = () => {
        this.setState({
            focus: false,
        });
    };

    clearCheckVersion = () => {
        clearInterval(this.interval);
    };

    render() {
        const { loading } = this.state;

        return (
            <Router>
                <ScrollToTop>
                    <LoadingPage loading={loading} />
                    <Header />
                    <Login />
                    <Registration />
                    <PasswordRecovery />
                    <TwoFa />
                    <Switch>
                        <Route path={termOfUsePath} component={TermOfUse} exact />
                        <Route path={termOfServicePath} component={TermOfService} exact />
                        <Route path={privacyPolicyPath} component={PrivacyPolicy} exact />
                        <Route path={faqPath} component={FaqPage} />
                        <Route
                            path={`${gamePath}/:id?/:type?`}
                            component={GamePage}
                            exact
                        />
                        <Route
                            path={passwordRecoveryPath}
                            component={PasswordRecoveryPage}
                            exact
                        />
                        <Route
                            path={verificationUserPath}
                            component={VerificationUser}
                            exact
                        />
                        <Route
                            path={verificationWithdrawPath}
                            component={VerificationWithdraw}
                            exact
                        />
                        <Route path={personalAreaPath} component={PersonalArea} />
                        <Route
                            path="/"
                            render={props => (
                                <HomePage {...props} path="/" loading={loading} exact />
                            )}
                        />
                        <Route component={PageNotFound} />
                    </Switch>
                    <Footer />
                </ScrollToTop>
                <ReactNotification />
            </Router>
        );
    }
}

const mapStateToProps = state => {
    const {
        userBalance: { data: userBalance, success: userBalanceSuccess },
        allTickers: {
            data: { id_ticker_currency },
            success: allTickersSuccess,
        },
    } = state;

    return {
        userBalanceSuccess,
        allTickersSuccess,
        userBalance,
        id_ticker_currency,
    };
};

export default compose(connect(mapStateToProps))(App);
