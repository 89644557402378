import { store } from 'react-notifications-component';
import { registration } from '../services/auth.service';
import { alertActions } from './alert.actions';
import { USER_CONSTANTS, EMAIL_ADDRESS_ALREADY_TAKEN } from '../constants';
import { authModalActions } from './authModal.actions';

const registrationAction = (user, t) => {
    const request = payload => ({
        type: USER_CONSTANTS.REGISTER_REQUEST,
        payload,
    });

    const success = payload => ({
        type: USER_CONSTANTS.REGISTER_SUCCESS,
        payload,
    });

    const failure = error => ({
        type: USER_CONSTANTS.REGISTER_FAILURE,
        error,
    });

    return dispatch => {
        dispatch(request());

        registration(user).then(response => {
            if (response.status === 200) {
                dispatch(success(response));
                dispatch(
                    alertActions.success(
                        'Check your email and verify your email address',
                    ),
                );
                dispatch(authModalActions.closeModal());
                store.addNotification({
                    message: t('auth.checkEmail'),
                    type: 'success',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'slideInRight'],
                    animationOut: ['animated', 'zoomOut'],
                    dismiss: {
                        duration: 4000,
                        pauseOnHover: true,
                    },
                });
                return;
            }

            const error = response.data.message;
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
            let errorMessage = error;
            if (error === EMAIL_ADDRESS_ALREADY_TAKEN) {
                errorMessage = t('error.email_already_taken');
            }

            store.addNotification({
                message: errorMessage,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
    };
};

export default registrationAction;
