import { store } from 'react-notifications-component';
import {
    USER_CONSTANTS,
    INCORRECT_PASSWORD_OR_EMAIL,
    USER_NOT_VERIFICATION,
    personalAreaPath,
    profileDataPath,
} from '../constants';
import { login } from '../services/auth.service';
import { alertActions } from '.';
import { authModalActions } from './authModal.actions';

const loginAction = (data, history, t) => {
    const request = user => ({ type: USER_CONSTANTS.LOGIN_REQUEST, user });
    const success = user => ({ type: USER_CONSTANTS.LOGIN_SUCCESS, user });
    const twoFa = user => ({ type: USER_CONSTANTS.LOGIN_TWO_FA_SUCCESS, user });
    const failure = error => ({ type: USER_CONSTANTS.LOGIN_FAILURE, error });

    return dispatch => {
        dispatch(request({ data }));

        login(data, history).then(
            user => {
                if (user.status === 200) {
                    if (user.data.login_two_fa) {
                        dispatch(authModalActions.openTwoFa());
                        dispatch(twoFa(user.data));
                        return;
                    }

                    if (user.data.is_blocked) {
                        dispatch(authModalActions.closeModal());
                        dispatch(failure('The user is blocked'));
                        store.addNotification({
                            title: t('userIsBlocked'),
                            message: t('contactSupport'),
                            type: 'danger',
                            insert: 'top',
                            container: 'top-right',
                            animationIn: ['animated', 'slideInRight'],
                            animationOut: ['animated', 'zoomOut'],
                            dismiss: {
                                duration: 5000,
                                pauseOnHover: true,
                            },
                        });
                        return;
                    }

                    dispatch(success(user.data));
                    dispatch(alertActions.success('Authorization successful'));
                    dispatch(authModalActions.closeModal());
                    localStorage.setItem('user', JSON.stringify(user));
                    history.push(`${personalAreaPath}${profileDataPath}`);
                    store.addNotification({
                        message: t('auth.authorizationSuccessful'),
                        type: 'success',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animated', 'slideInRight'],
                        animationOut: ['animated', 'zoomOut'],
                        dismiss: {
                            duration: 3000,
                            pauseOnHover: true,
                        },
                    });
                    return;
                }

                const error = user.data.message;
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                let errorMessage = error;
                if (error === INCORRECT_PASSWORD_OR_EMAIL) {
                    errorMessage = t('error.invalid_email_or_password');
                }
                if (error === USER_NOT_VERIFICATION) {
                    errorMessage = t('auth.checkEmail');
                }

                store.addNotification({
                    message: errorMessage,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'slideInRight'],
                    animationOut: ['animated', 'zoomOut'],
                    dismiss: {
                        duration: 3000,
                        pauseOnHover: true,
                    },
                });
            },
        );
    };
};

export default loginAction;
