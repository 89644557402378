/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import thirdStepIcon from './images/third-step-icon.svg';
import thirdStepMobileIcon from './images/third-step-mobile-icon.svg';
import approvedIcon from './images/approvedIcon.svg';
import pendingIcon from './images/pendingIcon.svg';
import declinedIcon from './images/declinedIcon.svg';
import { countries } from '../../../../../../helpers/countries';
import style from './step-three.module.scss';

const StepThree = ({
    data: {
        firstName,
        lastName,
        address,
        zipCode,
        gender,
        birthDate,
        country,
        city,
        mainImageFirst,
        mainImageSecond,
        mainImageThird,
        status,
        adminComment,
    },
    resetKyc,
    loading,
}) => {
    const { t } = useTranslation();

    const STATUS_IS_PENDING = 1;
    const STATUS_APPROVE = 700;
    const STATUS_CANCEL = 800;

    const ApprovedStatus = () => (
        <div className={style.step__statusWrapper_item}>
            <img src={approvedIcon} alt="approved" />
            <p className={style.step__statusWrapper_approved}>{t('kyc.approved')}</p>
        </div>
    );

    const PendingStatus = () => (
        <div className={style.step__statusWrapper_item}>
            <img src={pendingIcon} alt="pending" />
            <p className={style.step__statusWrapper_pending}>
                {t('kyc.verifyingInProgress')}
            </p>
        </div>
    );

    const DeclinedStatus = () => (
        <div className={style.step__statusWrapper_item}>
            <img src={declinedIcon} alt="declined" />
            <p className={style.step__statusWrapper_declined}>{t('kyc.declined')}</p>
        </div>
    );

    let statusContent = '';

    if (status === STATUS_APPROVE) {
        statusContent = <ApprovedStatus />;
    } else if (status === STATUS_IS_PENDING) {
        statusContent = <PendingStatus />;
    } else if (status === STATUS_CANCEL) {
        statusContent = <DeclinedStatus />;
    }

    const findCountryName = countries.find(item => item.value === country);

    return (
        <div className={style.step}>
            <p className={style.step__mainTitle}>3. {t('kyc.veryfying')}</p>
            <img
                className={style.step__stepIcon}
                src={thirdStepIcon}
                alt="thirdStepIcon"
            />
            <img
                className={style.step__stepMobileIcon}
                src={thirdStepMobileIcon}
                alt="thirdStepIcon"
            />
            <div className={style.step__description}>
                <p className={style.step__description_item}>
                    {t('kyc.basicInformation')}
                </p>
                <p className={style.step__description_item}>{t('kyc.uploadDocuments')}</p>
                <p className={style.step__description_item}>{t('kyc.veryfying')}</p>
            </div>
            <p className={style.step__title}>{t('kyc.personalInformation')}</p>
            <div className={style.step__infoWrapper}>
                <p className={style.step__infoWrapper_item}>
                    {t('kyc.firstName')}: {firstName}
                </p>
                <p className={style.step__infoWrapper_item}>
                    {t('kyc.lastName')}: {lastName}
                </p>
                <p className={style.step__infoWrapper_item}>
                    {t('kyc.birthDate')}:{' '}
                    <Moment format="DD/MM/YYYY" unix>
                        {birthDate}
                    </Moment>
                </p>
                <p className={style.step__infoWrapper_item}>
                    {t('kyc.gender')}: {gender === 1 ? t('general.male') : t('general.female')}
                </p>
            </div>
            <p className={style.step__title}>{t('kyc.contactInformation')}</p>
            <div className={style.step__infoWrapper}>
                <p className={style.step__infoWrapper_item}>
                    {t('general.country')}: {findCountryName.name}
                </p>
                <p className={style.step__infoWrapper_item}>
                    {t('kyc.city')}: {city}
                </p>
                <p className={style.step__infoWrapper_item}>
                    {t('kyc.address')}: {address}
                </p>
                <p className={style.step__infoWrapper_item}>
                    {t('kyc.zipCode')}: {zipCode}
                </p>
            </div>
            <p className={style.step__title}>{t('kyc.documentPhoto')}</p>
            <div className={style.step__photoWrapper}>
                {mainImageFirst ? (
                    <img
                        className={style.step__photoWrapper_img}
                        src={`${process.env.REACT_APP_ALL_DOCUMENTS_URL}${mainImageFirst}`}
                        alt="img"
                    />
                ) : null}
                {mainImageSecond ? (
                    <img
                        className={style.step__photoWrapper_img}
                        src={`${process.env.REACT_APP_ALL_DOCUMENTS_URL}${mainImageSecond}`}
                        alt="img"
                    />
                ) : null}
                {mainImageThird ? (
                    <img
                        className={style.step__photoWrapper_img}
                        src={`${process.env.REACT_APP_ALL_DOCUMENTS_URL}${mainImageThird}`}
                        alt="img"
                    />
                ) : null}
            </div>
            <div className={style.step__statusWrapper}>
                <div>
                    <p className={style.step__statusWrapper_title}>
                        {t('general.status')}
                    </p>
                    {statusContent}
                </div>
                {adminComment ? (
                    <div className={style.step__statusWrapper_commentWrapper}>
                        <p className={style.step__statusWrapper_adminComment}>
                            {adminComment}
                        </p>
                    </div>
                ) : null}
            </div>
            {status === STATUS_CANCEL ? (
                <button
                    type="button"
                    disabled={loading}
                    className={style.step__tryAgain}
                    onClick={resetKyc}
                >
                    <span className={style.buttonText}>{t('kyc.tryAgain')}</span>
                </button>
            ) : null}
        </div>
    );
};

StepThree.defaultProps = {
    data: {},
    loading: false,
    resetKyc: () => {},
};

StepThree.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
    resetKyc: PropTypes.func,
};

export default StepThree;
