import React from 'react';
import PropTypes from 'prop-types';

const SettingsIcon = ({ className }) => (
    <svg className={className} width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M23.3899 9.82988L25.3882 10.2745C25.7453 10.354 26 10.6774 26 11.0514V15.5568C26 15.9308 25.7453 16.2542 25.3882 16.3337L23.3899 16.7783C23.2162 17.3228 23.0009 17.8538 22.7468 18.3654L23.8531 20.1265C24.0507 20.4411 24.0072 20.8542 23.749 21.1185L20.6358 24.3045C20.3776 24.569 19.9739 24.6133 19.6664 24.4111L17.9456 23.2789C17.4457 23.539 16.9268 23.7592 16.3948 23.9371L15.9604 25.9821C15.8828 26.3475 15.5666 26.6082 15.2012 26.6082H10.7988C10.4334 26.6082 10.1172 26.3475 10.0396 25.9821L9.60519 23.9371C9.07318 23.7592 8.55426 23.539 8.05438 23.2789L6.33357 24.4111C6.02611 24.6133 5.62244 24.5688 5.36417 24.3045L2.25104 21.1185C1.99277 20.8542 1.94933 20.4411 2.1469 20.1265L3.25317 18.3654C2.99907 17.8538 2.78384 17.3228 2.61008 16.7783L0.611755 16.3337C0.2547 16.2542 0 15.9308 0 15.5568V11.0514C0 10.6774 0.2547 10.354 0.611755 10.2745L2.61008 9.82988C2.78384 9.28542 2.99907 8.75436 3.25317 8.24279L2.1469 6.48173C1.94933 6.16707 1.99277 5.75396 2.25104 5.48964L5.36417 2.30369C5.62244 2.03938 6.02611 1.99492 6.33357 2.19712L8.05438 3.32927C8.55426 3.06922 9.07318 2.84896 9.60519 2.67113L10.0396 0.626065C10.1172 0.260658 10.4334 0 10.7988 0H15.2012C15.5666 0 15.8828 0.260658 15.9604 0.626065L16.3948 2.67113C16.9268 2.84896 17.4457 3.06922 17.9456 3.32927L19.6664 2.19712C19.9739 1.99492 20.3776 2.03938 20.6358 2.30369L23.749 5.48964C24.0072 5.75396 24.0507 6.16707 23.8531 6.48173L22.7468 8.24279C23.0009 8.75436 23.2162 9.28542 23.3899 9.82988ZM13.0005 18.0744C15.5707 18.0744 17.6618 15.9344 17.6618 13.3041C17.6618 10.6737 15.5707 8.53365 13.0005 8.53365C10.4303 8.53365 8.3391 10.6737 8.3391 13.3041C8.3391 15.9344 10.4303 18.0744 13.0005 18.0744Z" fill="#D8D6ED" />
    </svg>

);

SettingsIcon.defaultProps = {
    className: '',
};

SettingsIcon.propTypes = {
    className: PropTypes.string,
};

export default SettingsIcon;
