import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import withGetService from '../../../hoc/with-get-service';
import SelectLanguage from '../../../language';
import { compose } from '../../../../utils';
import Img from '../../../small-components/img/img';
import { personalAreaPath, profileDataPath } from '../../../../constants/pathLocation';
import SelectCoin from '../../select-coin';
import Balance from '../header-site-area/balance';
import getUserInfoAction from '../../../../actions/get-user-info.actions';
import getAllTickersAction from '../../../../actions/get-all-tickers.actions';
import getUserBalanceAction from '../../../../actions/get-user-balance.actions';
import logo from '../../../assets/images/logoBlueText.svg';
import mobileLogo from '../../../assets/images/mobile-logo.svg';
import style from './header-personal-area.module.scss';

class HeaderPersonalArea extends Component {
    static defaultProps = {
        getUserBalance: () => {},
        getAllTickers: () => {},
        getUserInfo: () => {},
        userInfo: {},
        allTickers: [],
    };

    static propTypes = {
        getUserBalance: PropTypes.func,
        getAllTickers: PropTypes.func,
        getUserInfo: PropTypes.func,
        userInfo: PropTypes.object,
        allTickers: PropTypes.instanceOf(Array),
    };

    componentDidMount() {
        const {
            getUserBalance, getAllTickers, getUserInfo, allTickers,
        } = this.props;
        getUserBalance();
        getUserInfo();

        if (!allTickers.length) {
            getAllTickers();
        }
    }

    render() {
        const {
            userInfo: { avatar, full_name: name },
        } = this.props;

        const avatarUrl = avatar
            ? `${process.env.REACT_APP_AVATAR_IMAGE_URL}${avatar}`
            : null;

        return (
            <header className={style.header}>
                <Link to="/" className={style.header__logo}>
                    <img className={style.header__logo_img} src={logo} alt="logo" />
                    <img
                        className={style.header__logo_imgMobile}
                        src={mobileLogo}
                        alt="logo"
                    />
                </Link>
                <Balance color="#221876" cabinet />
                <div className={style.header__rightSide}>
                    <div className={style.header__rightSide_language}>
                        <SelectCoin />
                    </div>
                    <Link
                        to={`${personalAreaPath}${profileDataPath}`}
                        className={style.header__logoWrapper}
                    >
                        <Img
                            className={style.header__rightSide_logo}
                            src={avatarUrl}
                            userName={name || ''}
                        />
                        <p className={style.header__rightSide_name}>{name}</p>
                    </Link>
                    <SelectLanguage />
                </div>
            </header>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { user },
        allTickers: {
            data: { tickers: allTickers },
        },
        userInfo: { data: userInfo },
    } = state;

    return {
        user,
        allTickers,
        userInfo,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getUserBalance: getUserBalanceAction(getService),
        getAllTickers: getAllTickersAction(getService),
        getUserInfo: getUserInfoAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
)(HeaderPersonalArea);
