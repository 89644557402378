import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../../../../../small-components/button';
import { authModalActions } from '../../../../../../actions/authModal.actions';
import fetchWithdrawalTwoFaAction from '../../../../../../actions/post-withdrawal-two-fa.actions';
import { compose } from '../../../../../../utils';
import Field from '../../../../../small-components/field';
import withPostService from '../../../../../hoc/with-post-service';
import ModalWindow from '../../../../../small-components/modal-window/modal-widow';
import style from './two-fa.module.scss';
import googleAuth from './images/googleAuth.svg';

class WithdrawTwoFa extends PureComponent {
    static defaultProps = {
        t: () => {},
        checkTwoFa: () => {},
        closeModal: () => {},
        withdrawTwoFa: false,
        withdrawalTwoFaSuccess: false,
    };

    static propTypes = {
        t: PropTypes.func,
        checkTwoFa: PropTypes.func,
        closeModal: PropTypes.func,
        withdrawTwoFa: PropTypes.bool,
        withdrawalTwoFaSuccess: PropTypes.bool,
    };

    state = {
        code: '',
        codeErrors: {
            codeLengthError: '',
        },
    };

    componentDidUpdate(prevProps) {
        const { withdrawalTwoFaSuccess } = this.props;

        if (
            withdrawalTwoFaSuccess
            && withdrawalTwoFaSuccess !== prevProps.withdrawalTwoFaSuccess
        ) {
            this.setState({
                code: '',
                codeErrors: {
                    codeLengthError: '',
                },
            });
        }
    }

    inputOnchange = event => {
        const { name, value } = event.target;
        const { t } = this.props;

        this.setState(state => ({
            [name]: value,
            codeErrors: {
                ...state.codeErrors,
                codeLengthError: value.length < 6 ? t('error.min_length', { digit: 6 }) : '',
            },
        }));
    };

    validateFields = () => {
        const { t } = this.props;
        const { code } = this.state;

        if (code.length < 1) {
            this.setState(state => ({
                codeErrors: {
                    ...state.codeErrors,
                    codeLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        }
    };

    submit = async event => {
        event.preventDefault();
        await this.validateFields();
        const { t, checkTwoFa } = this.props;
        const { code, codeErrors } = this.state;

        const copyEmailErrors = { ...codeErrors };
        Object.keys(copyEmailErrors).forEach(key => {
            if (!copyEmailErrors[key]) delete copyEmailErrors[key];
        });

        if (!Object.keys(copyEmailErrors).length) {
            const data = {
                code,
            };
            checkTwoFa(data, t);
        }
    };

    render() {
        const { t, withdrawTwoFa, closeModal } = this.props;
        const { code, codeErrors } = this.state;
        const customStyles = {
            content: {
                maxWidth: '50vw',
            },
        };

        if (withdrawTwoFa) {
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'visible';
        }

        return (
            <ModalWindow
                isOpen={withdrawTwoFa}
                style={customStyles}
                onRequestClose={() => closeModal()}
            >
                <h3 className={style.twoFa__title}>{t('auth.twoFactorAuth')}</h3>
                <form
                    onSubmit={this.submit}
                    className={style.twoFa__form}
                    autoComplete="off"
                >
                    <div className={style.twoFa__infoWapper}>
                        <img
                            src={googleAuth}
                            alt="googleAuth"
                            className={style.twoFa__infoWapper_img}
                        />
                        <div>
                            <p className={style.twoFa__infoWapper_title}>
                                Google Authenticator
                            </p>
                            <p className={style.twoFa__infoWapper_subTitle}>
                                {t('auth.EnterGoogleAuthenticatorCode')}
                            </p>
                        </div>
                    </div>
                    <div className={style.twoFa__inputWrapper}>
                        <Field
                            id="code"
                            type="text"
                            placeholder={t('general.typeHere')}
                            name="code"
                            value={code}
                            onChange={this.inputOnchange}
                            error={codeErrors}
                            inputStyle={style.twoFa__input}
                            inputColor="#fff"
                            labelStyle={style.twoFa__label}
                            labelText={t('auth.googleAuthenticationCode')}
                        />
                    </div>
                    <div className={style.twoFa__submitBtnWrapper}>
                        <Button className={style.twoFa__submitBtn} type="submit">
                            <span className={style.buttonText}>{t('general.send')}</span>
                        </Button>
                    </div>
                </form>
            </ModalWindow>
        );
    }
}

const mapStateToProps = state => {
    const {
        authModal: { withdrawTwoFa },
        authentication: { user },
        withdrawalTwoFa: { success: withdrawalTwoFaSuccess },
    } = state;

    return {
        withdrawTwoFa,
        user,
        withdrawalTwoFaSuccess,
    };
};

const mapDispatchToProps = (dispatch, { postService }) => bindActionCreators(
    {
        checkTwoFa: fetchWithdrawalTwoFaAction(postService),
        closeModal: () => authModalActions.closeModal(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(WithdrawTwoFa);
