/* eslint-disable */

import { FILTER_CONSTANTS } from '../constants';

function selectFilter(payload) {
    return {
        type: FILTER_CONSTANTS.SELECT_FILTER,
        payload,
    };
}

export const FilterActions = {
    selectFilter,
};
