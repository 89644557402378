/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GetService from '../../../services/get-service';
import withGetService from '../../hoc/with-get-service';
import { authModalActions } from '../../../actions/authModal.actions';
import { compose } from '../../../utils';
import getCurrentLocale from '../../../helpers/getCurrentLocale';
import Button from '../../small-components/button';
import Field from '../../small-components/field';
import loginAction from '../../../actions/login.actions';
import eye from './images/eye.svg';
import ModalWindow from '../../small-components/modal-window/modal-widow';
import {
    emailValid,
    oneLowercaseChar,
    oneUppercaseChar,
    oneNumber,
    oneSpecialChar,
} from '../../../helpers';
import style from './login.module.scss';

class Login extends PureComponent {
    getService = new GetService();

    static defaultProps = {
        t: () => {},
        dispatch: () => {},
        history: {},
        getService: {},
        login: false,
        signUp: false,
        loading: false,
        loggingIn: false,
    };

    static propTypes = {
        t: PropTypes.func,
        dispatch: PropTypes.func,
        history: PropTypes.object,
        getService: PropTypes.object,
        login: PropTypes.bool,
        signUp: PropTypes.bool,
        loading: PropTypes.bool,
        loggingIn: PropTypes.bool,
    };

    state = {
        email: '',
        userPasswordLogin: '',
        emailErrors: {
            emailLengthError: '',
            emailCharactersError: '',
        },
        passwordErrors: {
            passwordCharactersError: '',
            passwordLengthError: '',
            passwordDigitError: '',
            passwordLettersError: '',
        },
        type: 'password',
    };

    componentDidUpdate(prevProps) {
        const { loggingIn, login } = this.props;
        if (loggingIn && loggingIn !== prevProps.loggingIn) {
            this.setState({
                email: '',
                userPasswordLogin: '',
                emailErrors: {
                    emailLengthError: '',
                    emailCharactersError: '',
                },
                passwordErrors: {
                    passwordCharactersError: '',
                    passwordLengthError: '',
                    passwordDigitError: '',
                    passwordLettersError: '',
                },
            });
        }

        if (login && login !== prevProps.login) {
            this.initializationCaptcha();
        }
    }

    initializationCaptcha = () => {
        const {
            getService, t, history, dispatch,
        } = this.props;
        getService
            .startCaptcha()
            .then(data => {
                const { gtserver, gtuser_id } = data;
                window.initGeetest(
                    {
                        product: 'bind',
                        width: '100%',
                        lang: getCurrentLocale(),
                        gt: data.gt,
                        challenge: data.challenge,
                        new_captcha: data.new_captcha,
                        offline: !data.success,
                        https: true,
                    },
                    captchaObj => {
                        captchaObj.onReady(() => {
                        }).onSuccess(() => {
                            const captchaKeys = captchaObj.getValidate();
                            const loginData = {
                                ...captchaKeys,
                                email: this.state.email,
                                userPasswordLogin: this.state.userPasswordLogin,
                                gtserver,
                                gtuser_id,
                            };
                            dispatch(loginAction(loginData, history, t));
                            captchaObj.reset();
                        }).onError(() => {
                            captchaObj.reset();
                        });
                        this.loadCaptcha = () => {
                            captchaObj.verify();
                        };
                    },
                );
            })
            .catch(err => {
                console.log(err, 'err');
            });
    };

    inputOnchange = async event => {
        const { name, value } = event.target;

        if (name === 'email') {
            await this.emailValidation(name, value);
        }

        if (name === 'userPasswordLogin') {
            await this.passwordValidation(name, value);
        }
    };

    emailValidation = (name, value) => {
        const { t } = this.props;
        this.setState(state => ({
            [name]: value.toLowerCase().trim(),
            emailErrors: {
                ...state.emailErrors,
                wrongEmail: emailValid(value) ? t('error.wrong_email') : '',
                emailLengthError: '',
            },
        }));
    };

    passwordValidation = (name, value) => {
        const { t } = this.props;
        this.setState(state => ({
            [name]: value.trim(),
            passwordErrors: {
                ...state.passwordErrors,
                oneLowercaseChar: oneLowercaseChar(value)
                    ? t('error.one_lowercase_char')
                    : '',
                oneUppercaseChar: oneUppercaseChar(value)
                    ? t('error.one_upperrcase_char')
                    : '',
                oneNumber: oneNumber(value) ? t('error.one_number') : '',
                oneSpecialChar: oneSpecialChar(value) ? t('error.one_special_char') : '',
                minLength: value.length < 8 ? t('error.min_length', { digit: 8 }) : '',
                passwordLengthError: '',
            },
        }));
    };

    validateFields = () => {
        const { t } = this.props;
        const { email, userPasswordLogin } = this.state;

        if (email.length < 1) {
            this.setState(state => ({
                emailErrors: {
                    ...state.emailErrors,
                    emailLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        }

        if (userPasswordLogin.length < 1) {
            this.setState(state => ({
                passwordErrors: {
                    ...state.passwordErrors,
                    passwordLengthError: t('error.field_can_not_be_empty'),
                },
            }));
        }
    };

    loginSubmit = async event => {
        event.preventDefault();
        await this.validateFields();
        const { passwordErrors, emailErrors } = this.state;

        const copyEmailErrors = { ...emailErrors };
        const copyPasswordErrors = { ...passwordErrors };

        Object.keys(copyEmailErrors).forEach(key => {
            if (!copyEmailErrors[key]) delete copyEmailErrors[key];
        });
        Object.keys(copyPasswordErrors).forEach(key => {
            if (!copyPasswordErrors[key]) delete copyPasswordErrors[key];
        });

        if (
            !Object.keys(copyEmailErrors).length
            && !Object.keys(copyPasswordErrors).length
        ) {
            this.loadCaptcha();
        }
    };

    openSignUp = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.openSignUp());
    };

    openPasswordRecovery = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.openPasswordRecovery());
    };

    closeModal = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.closeModal());
    };

    showHidePassword = () => {
        const { type } = this.state;
        this.setState({
            type: type === 'password' ? 'text' : 'password',
        });
    };

    render() {
        const {
            t, login, signUp, loading,
        } = this.props;
        const {
            email,
            emailErrors,
            userPasswordLogin,
            passwordErrors,
            type,
        } = this.state;
        const customStyles = {
            content: {
                maxWidth: '50vw',
            },
        };

        if (login || signUp) {
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'visible';
        }

        return (
            <ModalWindow
                isOpen={login}
                style={customStyles}
                onRequestClose={this.closeModal}
            >
                <h3 className={style.signIn__title}>{t('header.signIn')}</h3>
                <form onSubmit={this.loginSubmit} className={style.signIn__form} autoComplete="off">
                    <div className={style.signIn__inputWrapper}>
                        <Field
                            id="email"
                            type="email"
                            placeholder={t('auth.typeEmail')}
                            name="email"
                            value={email}
                            onChange={this.inputOnchange}
                            error={emailErrors}
                            inputStyle={style.signIn__input}
                            inputColor="#fff"
                        />
                    </div>
                    <div className={style.signIn__inputWrapper}>
                        <Field
                            id="password"
                            type={type}
                            placeholder={t('auth.typePassword')}
                            name="userPasswordLogin"
                            value={userPasswordLogin}
                            onChange={this.inputOnchange}
                            error={passwordErrors}
                            inputStyle={style.signIn__input}
                            inputColor="#fff"
                            passwordType
                        />
                        {userPasswordLogin.length >= 1 ? (
                            <div
                                onClick={this.showHidePassword}
                                className={style.signIn__inputWrapper_eye}
                            >
                                <img src={eye} alt="eye" />
                            </div>
                        ) : null}
                    </div>
                    <div
                        onClick={this.openPasswordRecovery}
                        className={style.signIn__forgotPassword}
                    >
                        {t('auth.forgotPassword')}
                    </div>
                    <div className={style.signIn__submitBtnWrapper}>
                        <Button
                            className={style.signIn__submitBtn}
                            type="submit"
                            loading={loading}
                        >
                            <span className={style.buttonText}>{t('header.signIn')}</span>
                        </Button>
                    </div>
                    <div onClick={this.openSignUp} className={style.signIn__registration}>
                        {t('header.registration')}
                    </div>
                </form>
            </ModalWindow>
        );
    }
}

const mapStateToProps = state => {
    const {
        authModal: { login, signUp },
        authentication: { loggingIn, loading },
    } = state;

    return {
        login,
        signUp,
        loading,
        loggingIn,
    };
};

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps),
    withRouter,
)(Login);
