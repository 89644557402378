import { store } from 'react-notifications-component';
import { POST_CREATE_INVOICE } from '../constants';

const postCreateInvoiceRequested = () => ({
    type: POST_CREATE_INVOICE.FETCH_CREATE_INVOICE_REQUEST,
});

const postCreateInvoiceLoaded = data => ({
    type: POST_CREATE_INVOICE.FETCH_CREATE_INVOICE_SUCCESS,
    payload: data,
});

const postCreateInvoiceError = error => ({
    type: POST_CREATE_INVOICE.FETCH_CREATE_INVOICE_FAILURE,
    payload: error,
});

const fetchCreateInvoiceAction = postService => (amount, t) => dispatch => {
    dispatch(postCreateInvoiceRequested());
    postService
        .createInvoice(amount)
        .then(data => {
            dispatch(postCreateInvoiceLoaded(data));
            store.addNotification({
                message: t('general.applicationSuccessfullyCreated'),
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        })
        .catch(err => {
            dispatch(postCreateInvoiceError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            store.addNotification({
                message: err.response.data.message,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchCreateInvoiceAction;
