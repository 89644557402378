import { GET_ALL_COUNTRIES } from '../constants';

const getAllCountriesRequested = () => ({
    type: GET_ALL_COUNTRIES.GET_ALL_COUNTRIES_REQUEST,
});

const getAllCountriesLoaded = data => ({
    type: GET_ALL_COUNTRIES.GET_ALL_COUNTRIES_SUCCESS,
    payload: data,
});

const getAllCountriesError = error => ({
    type: GET_ALL_COUNTRIES.GET_ALL_COUNTRIES_FAILURE,
    payload: error,
});

const getAllCountriesAction = getService => () => dispatch => {
    dispatch(getAllCountriesRequested());
    getService
        .getAllCountries()
        .then(data => {
            dispatch(getAllCountriesLoaded(data));
        })
        .catch(err => {
            dispatch(getAllCountriesError(err));
        });
};

export default getAllCountriesAction;
