import { store } from 'react-notifications-component';
import { POST_SET_DOCUMENT_KYC } from '../constants';

const postSetDocumentKycRequested = () => ({
    type: POST_SET_DOCUMENT_KYC.FETCH_SET_DOCUMENT_KYC_REQUEST,
});

const postSetDocumentKycLoaded = data => ({
    type: POST_SET_DOCUMENT_KYC.FETCH_SET_DOCUMENT_KYC_SUCCESS,
    payload: data,
});

const postSetDocumentKycError = error => ({
    type: POST_SET_DOCUMENT_KYC.FETCH_SET_DOCUMENT_KYC_FAILURE,
    payload: error,
});

const fetchSetDocumentKycAction = postService => kyc => dispatch => {
    dispatch(postSetDocumentKycRequested());
    postService
        .setDocumentKyc(kyc)
        .then(data => {
            dispatch(postSetDocumentKycLoaded(data));
        })
        .catch(err => {
            dispatch(postSetDocumentKycError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            store.addNotification({
                message: err.response.data.message,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchSetDocumentKycAction;
