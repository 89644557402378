import { store } from 'react-notifications-component';
import { GET_ACTUAL_GAME_LINK, GAME_IS_NOT_AVAILABLE } from '../constants';

const getActualGameLinkRequested = () => ({
    type: GET_ACTUAL_GAME_LINK.GET_ACTUAL_GAME_LINK_REQUEST,
});

const getActualGameLinkLoaded = data => ({
    type: GET_ACTUAL_GAME_LINK.GET_ACTUAL_GAME_LINK_SUCCESS,
    payload: data,
});

const getActualGameLinkError = error => ({
    type: GET_ACTUAL_GAME_LINK.GET_ACTUAL_GAME_LINK_FAILURE,
    payload: error,
});

const getActualGameLinkAction = getService => (id, history, t) => dispatch => {
    dispatch(getActualGameLinkRequested());
    getService
        .getActualGameLink(id)
        .then(data => {
            if (data.goHome === GAME_IS_NOT_AVAILABLE) {
                store.addNotification({
                    message: t('error.game_is_not_available'),
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'slideInRight'],
                    animationOut: ['animated', 'zoomOut'],
                    dismiss: {
                        duration: 3000,
                        pauseOnHover: true,
                    },
                });
                return history.push('/');
            }
            dispatch(getActualGameLinkLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                getService.logout();
            }
            dispatch(getActualGameLinkError(err));
        });
};

export default getActualGameLinkAction;
