/* eslint-disable camelcase */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Rating from '../../small-components/rating-image';
import spinnerImg from '../../assets/images/spinner.svg';
import getAllGamesAction from '../../../actions/get-all-games.actions';
import { authModalActions } from '../../../actions/authModal.actions';
import getActualGameLinkAction from '../../../actions/get-actual-game-link.actions';
import withGetService from '../../hoc/with-get-service';
import { compose } from '../../../utils';
import { personalAreaPath, documentsPath, MONEY_GAME } from '../../../constants';
import icon from './images/icon.svg';
import style from './game.module.scss';

class GamePage extends Component {
    static defaultProps = {
        t: () => {},
        getAllGames: () => {},
        openLogin: () => {},
        getActualGameLink: () => {},
        allGames: [],
        loggingIn: false,
        userInfo: {},
        history: {},
        match: {},
        loading: true,
        switchTickerSuccess: false,
        actualGameLink: '',
    };

    static propTypes = {
        t: PropTypes.func,
        getAllGames: PropTypes.func,
        openLogin: PropTypes.func,
        getActualGameLink: PropTypes.func,
        allGames: PropTypes.instanceOf(Array),
        loggingIn: PropTypes.bool,
        userInfo: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
        loading: PropTypes.bool,
        switchTickerSuccess: PropTypes.bool,
        actualGameLink: PropTypes.string,
    };

    componentDidMount() {
        const {
            t,
            getAllGames,
            getActualGameLink,
            match: {
                params: { id, type },
            },
            history,
        } = this.props;

        getAllGames();

        if (type === MONEY_GAME) {
            getActualGameLink(id, history, t);
        }
    }

    componentDidUpdate(prevProps) {
        const { switchTickerSuccess } = this.props;

        if (switchTickerSuccess && switchTickerSuccess !== prevProps.switchTickerSuccess) {
            document.location.reload(true);
        }
    }

    openMoneyGame = link => {
        const {
            t,
            history,
            loggingIn,
            openLogin,
            userInfo: { is_kyc },
        } = this.props;

        if (!loggingIn) {
            return openLogin();
        }
        if (!is_kyc) {
            history.push(`${personalAreaPath}${documentsPath}`);
            return store.addNotification({
                message: t('error.proof_your_identify'),
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        }

        window.open(link);
    };

    openDemoGame = link => {
        window.open(link);
    };

    render() {
        const {
            t,
            allGames,
            loading,
            actualGameLink,
            match: {
                params: { id, type },
            },
        } = this.props;
        const game = allGames.find(item => +item.id === +id);
        const {
            bet_max,
            bet_min,
            is_bonus_game,
            is_double_game,
            is_jackpot,
            is_wild_sym,
            line_number,
            rating,
            title,
            link_demo,
        } = game || {};

        const link = type === MONEY_GAME ? actualGameLink : link_demo;

        if (type === MONEY_GAME ? loading : false) {
            return (
                <div className={style.spinnerWrapper}>
                    <img className={style.spinner} src={spinnerImg} alt="spinner" />
                </div>
            );
        }

        return (
            <Fragment>
                <div className={style.game} id="games">
                    <div className={style.game__wrapper}>
                        {link ? <iframe src={link} title="game" /> : null}
                    </div>
                    <div className={style.chracteristics}>
                        <h3 className={style.chracteristics__title}>
                            {t('gameChracteristics')}
                        </h3>
                        <div className={style.chracteristics__wrapper}>
                            <div className={style.chracteristics__wrapper_item}>
                                <div className={style.chracteristics__wrapper_row}>
                                    <p className={style.chracteristics__wrapper_text}>
                                        {t('linesNumber')}
                                    </p>
                                    <p
                                        className={
                                            style.chracteristics__wrapper_textOrange
                                        }
                                    >
                                        {line_number}
                                    </p>
                                </div>
                                <div className={style.chracteristics__wrapper_row}>
                                    <p className={style.chracteristics__wrapper_text}>
                                        {t('jackpot')}
                                    </p>
                                    <p
                                        className={
                                            style.chracteristics__wrapper_textOrange
                                        }
                                    >
                                        {is_jackpot ? '+' : '-'}
                                    </p>
                                </div>
                                <div className={style.chracteristics__wrapper_row}>
                                    <p className={style.chracteristics__wrapper_text}>
                                        {t('doubleCountGame')}
                                    </p>
                                    <p
                                        className={
                                            style.chracteristics__wrapper_textOrange
                                        }
                                    >
                                        {is_double_game ? '+' : '-'}
                                    </p>
                                </div>
                                <div className={style.chracteristics__wrapper_rowDesctop}>
                                    <p className={style.chracteristics__wrapper_text}>
                                        {t('gameRating')}{' '}
                                        <span
                                            className={
                                                style.chracteristics__wrapper_textOrange
                                            }
                                        >
                                            {title}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className={style.chracteristics__wrapper_item}>
                                <div className={style.chracteristics__wrapper_row}>
                                    <p className={style.chracteristics__wrapper_text}>
                                        {t('wildSymbol')}
                                    </p>
                                    <p
                                        className={
                                            style.chracteristics__wrapper_textOrange
                                        }
                                    >
                                        {is_wild_sym ? t('general.yes') : t('general.no')}
                                    </p>
                                </div>
                                <div className={style.chracteristics__wrapper_row}>
                                    <p className={style.chracteristics__wrapper_text}>
                                        {t('betLineMinMax')}
                                    </p>
                                    <p
                                        className={
                                            style.chracteristics__wrapper_textOrange
                                        }
                                    >
                                        {bet_min}
                                        {'/'}
                                        {bet_max}
                                    </p>
                                </div>
                                <div className={style.chracteristics__wrapper_row}>
                                    <p className={style.chracteristics__wrapper_text}>
                                        {t('bonusGame')}
                                    </p>
                                    <p
                                        className={
                                            style.chracteristics__wrapper_textOrange
                                        }
                                    >
                                        {is_bonus_game ? '+' : '-'}
                                    </p>
                                </div>
                                <div className={style.chracteristics__wrapper_rowMobile}>
                                    <p className={style.chracteristics__wrapper_text}>
                                        {t('gameRating')}{' '}
                                    </p>
                                </div>
                                <div className={style.chracteristics__wrapper_row}>
                                    <Rating
                                        className={
                                            style.chracteristics__wrapper_ratingImg
                                        }
                                        rating={rating}
                                    />
                                    <p
                                        className={
                                            style.chracteristics__wrapper_textOrange
                                        }
                                    >
                                        {rating}
                                    </p>
                                </div>
                            </div>
                            <div className={style.chracteristics__wrapper_item}>
                                <img
                                    className={style.chracteristics__wrapper_iconImg}
                                    src={icon}
                                    alt="icon"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        allGames: { data: allGames },
        userInfo: { data: userInfo },
        actualGameLink: { data: actualGameLink, loading },
        switchTicker: { success: switchTickerSuccess },
    } = state;

    return {
        loggingIn,
        allGames,
        userInfo,
        loading,
        actualGameLink,
        switchTickerSuccess,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAllGames: getAllGamesAction(getService),
        getActualGameLink: getActualGameLinkAction(getService),
        openLogin: () => authModalActions.openLogin(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(GamePage);
