import { store } from 'react-notifications-component';
import { POST_WITHDRAWAL_TWO_FA, INVALID_2FA_CODE } from '../constants';

const postWithdrawalTwoFaRequested = () => ({
    type: POST_WITHDRAWAL_TWO_FA.FETCH_WITHDRAWAL_TWO_FA_REQUEST,
});

const postWithdrawalTwoFaLoaded = data => ({
    type: POST_WITHDRAWAL_TWO_FA.FETCH_WITHDRAWAL_TWO_FA_SUCCESS,
    payload: data,
});

const postWithdrawalTwoFaError = error => ({
    type: POST_WITHDRAWAL_TWO_FA.FETCH_WITHDRAWAL_TWO_FA_FAILURE,
    payload: error,
});

const fetchWithdrawalTwoFaAction = postService => (code, t) => dispatch => {
    dispatch(postWithdrawalTwoFaRequested());
    postService
        .checkTwoFa(code)
        .then(data => {
            dispatch(postWithdrawalTwoFaLoaded(data));
        })
        .catch(err => {
            dispatch(postWithdrawalTwoFaError(err.response.data.message));
            if (err.response.status === 401) {
                postService.logout();
            }
            let errorMessage = '';
            if (err.response.data.message === INVALID_2FA_CODE) {
                errorMessage = t('error.invalid_code');
            } else {
                errorMessage = err.response.data.message;
            }
            store.addNotification({
                message: errorMessage,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'slideInRight'],
                animationOut: ['animated', 'zoomOut'],
                dismiss: {
                    duration: 3000,
                    pauseOnHover: true,
                },
            });
        });
};

export default fetchWithdrawalTwoFaAction;
